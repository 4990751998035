import request from '@/utils/request'

// 登录方法
export function login (data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 注册
export function register (data) {
  return request({
    url: '/system/anpinguser',
    method: 'post',
    data
  })
}

// 退出方法
export function logout () {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg () {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

// 获取用户详细信息
export function getInfo () {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 修改用户详细信息
export function updateInfo (data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data
  })
}

// 用户头像上传
export function uploadAvatar (data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 用户头像修改
export function updateAvatar (data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}

// 修改用户密码
export function updatePassword (data) {
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 重置用户密码
export function forgetPwd (data) {
  return request({
    url: '/system/user/updateUserPwd',
    method: 'put',
    data: data
  })
}

<template>
  <div class="card">
    <div>
      <div><i class="el-icon-document-copy"></i></div>
      <h6>{{ title }}</h6>
      <p>{{ content }}</p>
    </div>
    <div>
      <button @click="goto">立即进入</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'card',
  props: {
    goto: {
      type: Function
    },
    title: {
      type: String,
      default: '资料下载'
    },
    content: {
      type: String,
      default: '汇集深度剖析文档，专享免费下载，让产品理解更深入。'
    }
  }
}
</script>

<style lang='less' scoped>
  @keyframes rotate {
    0% {
      transform: rotateY(00deg);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  .card {
    // width: 100%;
    width: 300px;
    height: 248px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    background-color: rgb(247, 250, 253);
    box-shadow: 0 0 10px 0px rgba(171, 190, 167, 0.6);
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    i {
      font-size: 40px;
      color: rgb(23, 221, 235);
    }
    h6 {
      font-size: 20px;
      margin: 20px auto 10px;
    }
    > div:last-child {
      position: absolute;
      bottom: -21px;
      left: 0;
      right: 0;
      height: 0px;
      overflow: hidden;
      transition: height 0.25s linear;
    }
    button {
      font-size: 19px;
      height: 43px;
      line-height: 43px;
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      background-color: rgb(23, 221, 235);
    }
  }
  .card:hover {
    i {
      animation: rotate 1s linear infinite;
    }
    > div:last-child {
      height: 43px;
    }
  }
</style>

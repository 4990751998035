/* eslint-disable */
(function() {
  window._bd_share_config = {
    common: {
      bdSnsKey: {},
      bdText: "",
      bdMini: "1",
      bdMiniList: ["tsina", "weixin", "mail", "linkedin", "copy", "print"],
      bdPic: "",
      bdStyle: "0",
      bdSize: "32"
    },
    slide: { type: "slide", bdImg: "3", bdPos: "left", bdTop: "100" }
  };
  // eslint-diable-next-line
  const shareEl = (document.getElementsByTagName("head")[0] || document.body)
  shareEl.appendChild(document.createElement('script')).src = "http://bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js?cdnversion=" +  (+new Date())
  // with (document)
  //   (0)[
  //     ((getElementsByTagName("head")[0] || body).appendChild(
  //       createElement("script")
  //     ).src =
  //       "http://bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js?cdnversion=" +
  //       ~(-new Date() / 36e5))
  //   ];
})();

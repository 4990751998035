<template>
  <div id="learnPlatform">
    <info title="学习平台" refAnimal="learnPlatform" content1="个性化定制培训课程" content2="以便捷方式开始学习，轻轻松松提升" />
    <div class="learnPlatformContainer">
      <ul>
        <li class="animated" v-scroll-class="'fadeInUpBig'">
          <img src="../../assets/images/banner/asidebanner.jpg" alt="">
        </li>
        <li v-if="$store.state.vip.courseList">
          <div v-for="(item, index) in $store.state.vip.courseList" class="animated" :key="index" v-scroll-class="'fadeInUpBig'">
            <course
            :title="item.name"
            :studyPeople="item.ordernum"
            :lecturerName="item.type"
            :id="item.id"
            :cost="item.cost"
            :src="item.introduceimg" />
          </div>
        </li>
        <li class="animated" v-scroll-class="'fadeInUpBig'">
          <ul v-if="$store.state.industry.industryList">
            <li>
              <infoShow
              :unfold="true"
              :title="$store.state.industry.industryList[0].title"
              :id="$store.state.industry.industryList[0].id"
              :summary="$store.state.industry.industryList[0].summary"
              :src="$store.state.industry.industryList[0].img" />
            </li>
            <li class="autoGetHeight">
              <div class="autoScroll"
              ref="autoScroll"
              :style="{ marginTop: marginTop + 'px' }">
                <infoShow
                v-for="(item, index) in $store.state.industry.industryList"
                :key="index"
                :title="item.title"
                :id="item.id"
                :summary="item.summary"
                :uptime="item.uptime" />
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import info from '@/components/index/Info'
import course from './Course'
import infoShow from './InfoShow'
export default {
  name: 'learnPlatform',
  components: { info, course, infoShow },
  data () {
    return {
      marginTop: 0,
      flag: true,
      timer: null
    }
  },
  methods: {
    setInterval () {
      // console.log('鼠标移出')
      // flag = true 表示往上
      this.timer = setInterval(() => {
        if (this.flag) {
          if (this.marginTop > -185) {
            this.marginTop = this.marginTop - 0.5
          } else {
            this.flag = false
          }
        } else {
          if (this.marginTop < 0) {
            this.marginTop = this.marginTop + 0.5
          } else {
            this.flag = true
          }
        }
      }, 10)
    },
    removeInterval () {
      // console.log('鼠标移入')
      clearInterval(this.timer)
    }
  },
  mounted () {
    // // 设置自动滚动
    // this.setInterval()
    // setTimeout(() => {
    //   // 监听鼠标移入
    //   this.$refs.autoScroll.addEventListener('mouseover', this.removeInterval, true)
    //   // 监听鼠标移出
    //   this.$refs.autoScroll.addEventListener('mouseout', this.setInterval, true)
    // }, 5000)
  },
  beforeDestroy () {
    if (this.$store.state.industry.industryList) {
      // 移除监听鼠标移入
      this.$refs.autoScroll.removeEventListener('mouseover', this.removeInterval, true)
      // 移除监听鼠标移出
      this.$refs.autoScroll.removeEventListener('mouseout', this.setInterval, true)
    }
  },
  watch: {
    // 监听行业资讯列表
    '$store.state.industry.industryList': {
      handler (val) {
        console.log(val)
        // 设置自动滚动
        this.setInterval()
        this.$nextTick(function () {
          // 监听鼠标移入
          this.$refs.autoScroll.addEventListener('mouseover', this.removeInterval, true)
          // 监听鼠标移出
          this.$refs.autoScroll.addEventListener('mouseout', this.setInterval, true)
        })
      },
      deep: true
    }
  }
}
</script>

<style lang='less' scoped>
/* 媒体查询，当文档宽度大于1200px时 */
  @media screen and (min-width: 1200px) {
    #learnPlatform .learnPlatformContainer {
      width: 1200px;
      margin: 0 auto;
    }
    .learnPlatformContainer > ul {
      > li:nth-child(1) {
        width: 18%;
      }
      > li:nth-child(2) {
        width: 50%;
      }
      > li:nth-child(3) {
        width: 30%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    #learnPlatform .learnPlatformContainer {
      width: 100%;
    }
    .learnPlatformContainer > ul {
      > li:nth-child(1) {
        width: 0;
        display: none;
      }
      > li:nth-child(2) {
        width: 60%;
      }
      > li:nth-child(3) {
        width: 40%;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .learnPlatformContainer > ul {
      > li:nth-child(1) {
        width: 0;
        display: none;
      }
      > li:nth-child(2) {
        width: 70%;
      }
      > li:nth-child(3) {
        width: 30%;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .learnPlatformContainer > ul {
      > li:nth-child(1) {
        width: 0;
        display: none;
      }
      > li:nth-child(2) {
        width: 95%;
        display: flex;
        margin: 0 auto;
      }
      > li:nth-child(3) {
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
      }
    }
  }
  ul li{
    margin: 0;
    padding: 0;
  }

  .learnPlatformContainer {
    overflow: hidden;
    > ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > li:first-child {
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: transform 0.2s linear;
        }
        &:hover img{
          transform: scale(1.05);
        }
      }
      > li:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          margin: 3px auto;
        }
      }
      > li:last-child {
        overflow: hidden;
        ul {
          position: relative;
          width: 100%;
          height: 577px;
          overflow: hidden;
        }
        ul::before {
          position: absolute;
          content: '';
          display: block;
          left: 5px;
          top: 37.5px;
          bottom: 18.5px;
          width: 1px;
          background-color: rgb(225,225,225);
          z-index: -10;
        }
        li {
          margin: 0 auto;
          overflow: hidden;
        }
      }
    }
  }
</style>

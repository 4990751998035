<template>
  <div class="info" :ref="'indexModelTitle' + refAnimal" :style="{ backgroundColor: backgroundColor, color: fontColor}">
    <div>
      <h3 :class="titleClass">{{ title }}</h3>
      <p :class="descClass">{{ content1 }}</p>
      <p :class="descClass">{{ content2 }}</p>
    </div>
  </div>
</template>
<script>
import { isElementNotInViewport } from "@/utils/index.js";

export default {
  name: 'info',
  props: {
    refAnimal: {
      type: String,
      default: 'company'
    },
    title: {
      type: String,
      default: '安评'
    },
    content1: {
      type: String,
      default: '安坪建站内容1'
    },
    content2: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    fontColor: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      titleClass: '',
      descClass: '',
      clientHeight: null,
    }
  },

  mounted() {
    this.clientHeight = document.documentElement.clientHeight
    console.log('容器高度', this.clientHeight)

    this.animation()
    window.addEventListener('scroll', this.handleScroll, true)
  },

  methods: {
    animation() {
      // console.log(this.refAnimal, this.$refs['indexModelTitle' + this.refAnimal].getBoundingClientRect().bottom - this.clientHeight)
      const bottom = this.$refs['indexModelTitle' + this.refAnimal].getBoundingClientRect().top - this.clientHeight
      // console.log('rrrrr', isElementNotInViewport(this.$refs.indexModelTitle))
      if (bottom < 110) {
        this.titleClass = 'bounceInLeft animated'
        this.descClass = 'bounceInRight animated'
        // setTimeout(() => {
        //   this.titleClass = ''
        //   this.descClass = ''
        // }, 2000)
      }
    },

    // 监听滚动
    handleScroll () {
      // console.log('滚动高度', window.pageYOffset)
      // console.log('元素距离顶部高度', this.$refs.bookListContent.getBoundingClientRect().top)
      // console.log('滚动-----' + this.refAnimal, this.$refs['indexModelTitle' + this.refAnimal].getBoundingClientRect().bottom - this.clientHeight)
      const bottom = this.$refs['indexModelTitle' + this.refAnimal].getBoundingClientRect().bottom - this.clientHeight
      if (bottom < 135) {
        this.titleClass = 'bounceInLeft animated'
        this.descClass = 'bounceInRight animated'

        if (bottom < 67 - this.clientHeight) {
          this.titleClass = ''
          this.descClass = ''
        }
      } else {
        this.titleClass = ''
        this.descClass = ''
      }
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 767px) {
    .info {
      height: 150px;
    }
  }
  @media screen and (min-width: 768px) {
    .info {
      height: 200px;
    }
  }
  .info {
    box-sizing: border-box;
    text-align: center;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h3 {
    margin-top: 0;
    font-size: 22px;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 0;
    margin-top: 5px;
    color: rgb(188, 188, 188);
    font-size: 14px;
  }


</style>

<style lang="less">
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}
@-webkit-keyframes bounceInLeft {
  0%, 100%, 60%, 75%, 90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(0.215, .61, .355, 1)
  }
}
@keyframes bounceInLeft {
  0%, 100%, 60%, 75%, 90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(0.215, .61, .355, 1)
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft
}
@-webkit-keyframes bounceInRight {
  0%, 100%, 60%, 75%, 90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(0.215, .61, .355, 1)
  }
}
@keyframes bounceInRight {
  0%, 100%, 60%, 75%, 90% {
    -webkit-transition-timing-function: cubic-bezier(0.215, .61, .355, 1);
    transition-timing-function: cubic-bezier(0.215, .61, .355, 1)
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight
}
</style>

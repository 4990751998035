<template>
  <div id="dataCenter">
    <el-card>
      <div slot="header" class="clearfix">
      <breadcrumb />
        <div class="top">
          <!-- <nav-list
          :navList="typeList"
          classifyName="类型"
          :getList="getList" /> -->
          <nav-list
          :navList="formatList"
          classifyName="格式"
          @selectList="val => this.selectList = val" />
          <div class="search">
            <el-input placeholder="关键词" v-model="search" class="input-with-select" clearable size="mini" @keydown.enter.native="onSearch">
              <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
            </el-input>
          </div>
        </div>
      </div>
      <div>
        <div v-if="isLoading" style="text-align: center;">正在加载中...</div>
        <datas-list v-else :dataList="filterDataList" />
        <div v-if="filterDataList.length === 0" style="text-align: center;">暂无数据...</div>
      </div>
    </el-card>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { listInformation } from '@/api/dataInfo'
import breadcrumb from '@/common/components/Breadcrumb'
import navList from '@/components/learnWorld/NavList'
import datasList from '@/components/learnWorld/DataList'
export default {
  name: 'dataCenter',
  components: {
    breadcrumb,
    navList,
    datasList
  },
  data () {
    return {
      dataList: [],
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      isLoading: true,
      typeList: [
        '产品宣传册',
        '产品彩页',
        '合作案例',
        '产品培训PPT',
        '展会演讲材料',
        '技术白皮书',
        '解决方案',
        '产品介绍视频',
        '产品荣誉证明',
        '公司宣传片',
        '公司宣传册',
        '公司资质',
        '品牌VI',
        '营销活动视频',
        '活动照片',
        '公司荣誉',
        '其他'
      ],
      formatList: [
        'pdf',
        'doc',
        'docx',
        'ppt',
        'xls',
        'xlsx'
      ],
      keyword: '',
      selectList: []
    }
  },
  methods: {
    async loadDataList () {
      try {
        this.isLoading = true
        const res = await listInformation({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          title: this.search
        })
        if (res.code === 200) {
          this.total = res.total
          this.dataList = res.rows
          this.isLoading = false
        }
      } catch (error) {
        this.$message.error('未知错误，请重新尝试')
      }
    },
    onSearch () {
      this.pageNum = 10
      this.pageNum = 1
      this.loadDataList()
    },
    // 分页每页数量发生改变
    handleSizeChange (val) {
      this.pageSize = val
      this.loadDataList()
    },
    // 分页当前页码发生改变
    handleCurrentChange (val) {
      this.pageNum = val
      this.loadDataList()
    }
  },
  computed: {
    filterDataList () {
      return this.dataList.filter((item, index, arr) => {
        return this.selectList.includes(item.url.split('.')[1])
      })
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    this.loadDataList()
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 1200px) {
  #dataCenter {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  #dataCenter {
    width: 1200px;
    margin: 0 auto;
  }
}
  #dataCenter {
    .search {
      float: right;
    }
    /deep/ .el-card__header {
      padding-top: 0 !important;
    }

    .block {
      margin-top: 20px;
    }
  }
</style>

<template>
  <div id="help">
    <div class="helpContainer">
      <breadcrumb :title="titles[$route.query.id]" />
      <div v-if="$store.state.device === 'PC'" class="contentPC">
        <ul class="menu">
          <li :class="{ isActive: $route.query.id === 'paymentProblems'}"><router-link :to="{ query: { id: 'paymentProblems' } }">支付问题</router-link></li>
          <li :class="{ isActive: $route.query.id === 'onlineCourse'}"><router-link :to="{ query: { id: 'onlineCourse' } }">线上课程购买</router-link></li>
          <li :class="{ isActive: $route.query.id === 'memberRegistration'}"><router-link :to="{ query: { id: 'memberRegistration' } }">会员注册</router-link></li>
          <li :class="{ isActive: $route.query.id === 'invoice'}"><router-link :to="{ query: { id: 'invoice' } }">发票相关</router-link></li>
          <li :class="{ isActive: $route.query.id === 'feedback'}"><router-link :to="{ query: { id: 'feedback' } }">反馈问题</router-link></li>
          <li :class="{ isActive: $route.query.id === 'serviceClause'}"><router-link :to="{ query: { id: 'serviceClause' } }">用户服务条款</router-link></li>
        </ul>
        <div>
          <ul>
            <li id="paymentProblems" :style="{ display: $route.query.id === 'paymentProblems' ? 'block' : 'none' }">
              <section>
                <span>支付问题</span>
                <p>
                  1、支付方式：
                </p>
                <p>
                  课程分在线课程和线下课程，在线课程需要在线支付，目前支持支付宝支付方式。线下课程即在线报名，确认课程席位后，线下完成付款。
                </p>
                <p>
                  2、我购买的课程怎么发给我？
                </p>
                <p>
                  用户购买成功的课程，会直接推送至用户的个人中心“我的课程”版块，用户直接进行观看。
                </p>
                <p>
                  3、我购买了课程，也扣款成功了， 但是我在哪能找到我买的课程？
                </p>
                <p>
                  登录个人账号，进入个人中心，购买成功的课程会在“我的课程”界面显示。
                </p>
              </section>
            </li>
            <li id="onlineCourse" :style="{ display: $route.query.id === 'onlineCourse' ? 'block' : 'none' }">
              <section>
                <span>线上课程购买</span>
                <p>购课流程</p>
                <p>1、注册，登录；</p>
                <p>2、选择要购买的课程；</p>
                <p>3、线上课程点击“立即购买”，线下课程点击“立即报名”；</p>
                <p>4、提交订单；</p>
                <p>5、点击“提交订单”，支付方式支持：支付宝、微信支付。</p>
                <p>6、支付完成后，您可通过“会员中心”，“订单管理”浏览您购买的课程。</p>
              </section>
            </li>
            <li id="memberRegistration" :style="{ display: $route.query.id === 'memberRegistration' ? 'block' : 'none' }">
              <section>
                <span>会员注册</span>
                <p>1、在会员专区页面进行注册</p>
                <p>2、按照提示填写姓名、所属公司、手机号</p>
                <p>3、获取验证码，填写正确</p>
                <p>4、设置密码</p>
                <p>5、支付会员费用</p>
              </section>
            </li>
            <li id="invoice" :style="{ display: $route.query.id === 'invoice' ? 'block' : 'none' }">
              <section>
                <span>发票相关</span>
                <p>如果需要发票，请拨打电话0516-83888208或者17712110809进行咨询，也直接加微信进行咨询。</p>
              </section>
            </li>
            <li id="feedback" :style="{ display: $route.query.id === 'feedback' ? 'block' : 'none' }">
              <section>
                <span>反馈问题</span>
                <p>如遇问题，可以直接拨打客服热线0516-83888208或者17712110809</p>
              </section>
            </li>
            <li id="serviceClause" :style="{ display: $route.query.id === 'serviceClause' ? 'block' : 'none' }">
              <section>
                <span>用户服务条款</span>
                <p>
                  一、总则：
                </p>
                <p>
                  您在使用安评应急账号提供的各项服务之前，应仔细阅读本协议。用户应当同意本协议的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击"同意"按钮即视为您已了解并完全同意本服务条款各项内容，与徐州安评应急培训有限公司达成协议，完全接受本协议项下的全部条款，包括安评应急培训账号对服务条款随时做的任何修改。
                </p>
                <p>
                  二、用户的帐号，密码和安全性
                </p>
                <p>
                  用户注册成功后，安评应急培训给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。您可随时根据指示改变您的密码。用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告。
                </p>
                <p>
                  三、用户注册信息和隐私保护
                </p>
                <p>
                  1、安评应急培训帐号（即安评应急培训用户ID）的所有权归安评应急培训，用户完成注册申请手续后，获得安评应急培训帐号的使用权。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，安评应急培训不负任何责任。
                </p>
                <p>
                  2、安评应急培训不对外公开或向第三方提供单个用户的注册资料，除非：
                </p>
                <p>（1）事先获得用户的明确授权；</p>
                <p>（2）根据有关的法律法规要求；</p>
                <p>（3）按照相关政府主管部门的要求；</p>
                <p>（4）为维护安评应急培训的合法权益。</p>
                <p>
                  3、在你注册安评应急培训帐户，使用其他安评应急培训产品或服务，访问安评应急培训网页, 或参加活动时，安评应急培训会收集你的个人身份识别资料，并会将这些资料用于：改进为你提供的服务及网页内容。
                </p>
                <p>
                  四、使用规则
                </p>
                <p>
                 用户在使用安评应急培训服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶
                </p>
                <p>
                  1、上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：
                </p>
                <p>（1）反对宪法所确定的基本原则的；</p>
                <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                <p>（3）损害国家荣誉和利益的；</p>
                <p>（4）煽动民族仇恨、民族歧视、破坏民族团结的；</p>
                <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                <p>（8）侮辱或者诽谤他人，侵害他人合法权利的；</p>
                <p>（9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
                <p>（10）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
                <p>2、不得为任何非法目的而使用网络服务系统；</p>
                <p>3、不利用安评应急培训服务从事以下活动：</p>
                <p>（1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
                <p>（2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
                <p>（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
                <p>（4）故意制作、传播计算机病毒等破坏性程序的；</p>
                <p>（5）其他危害计算机信息网络安全的行为。</p>
                <p>
                  4、用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿安评应急培训与合作公司、关联公司，并使之免受损害。对此，安评应急培训有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收安评应急培训帐号、追究法律责任等措施。对恶意注册安评应急培训帐号或利用安评应急培训帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，安评应急培训有权回收其帐号。同时，安评应急培训公司会视司法部门的要求，协助调查。
                </p>
                <p>5、用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
                <p>6、用户须对自己在使用安评应急培训服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在安评应急培训公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予安评应急培训等额的赔偿。</p>
                <p>7、安评应急培训资源部分为安评应急培训网只有版权，部分资源来源于互联网资源。任何被授权的浏览、复制、打印和传播属于本网站内的资料必须符合以下条件：</p>
                <p>（1）所有的资料和图象均以获得信息为目的；</p>
                <p>（2）所有的资料和图象均不得用于商业目的；</p>
                <p>（3）所有的资料、图象及其任何部分都必须包括此版权声明；</p>
                <p>（4）安评应急培训为在线教育平台，平台上所有的产品、技术均属安评应急培训或课程版权人所有，在此并未授权。</p>
                <p>（5）“安评应急培训”及相关图形等为安评应急培训的注册商标。</p>
                <p>（6）未经安评应急培训许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，安评应急培训将依法追究法律责任。</p>
                <p>五、结束服务</p>
                <p>用户或可随时根据实际情况中断一项或多项网络服务。不需对任何个人或第三方负责而随时中断服务。用户对后来的条款修改有 异议，或对的服务不满，可以行使如下权利：</p>
                <p>1、停止使用的网络服务</p>
                <p>2、通告停止对该用户的服务</p>
                结束用户服务后，用户使用网络服务的权利马上中止。从那时起，用户没有权利，也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
                <p>六、法律
                网络服务条款要与中华人民共和国的法律解释相一致，用户和一致同意服从高等法院所有管辖。如发生服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户产生法律效力和影响。</p>
                <p>七、其他</p>
                <p>1、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
                <p>2、双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向安评应急培训所在地的人民法院提起诉讼。</p>
                <p>3、安评应急培训未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
                <p>4、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
                <p>请您在发现任何违反本服务协议以及其他任何单项服务的服务条款、安评应急培训各类公告之情形时，通知安评应急培训。您可以通过如下联络方式同安评应急培训联系：</p>
                <p>电子邮箱：2405056313@qq.com</p>
                <p>联系方式：17712110809</p>
              </section>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="contentMobile">
        <el-collapse>
          <el-collapse-item title="支付问题" name="1">
            <div>
              <section>
                <span>支付问题</span>
                <p>
                  1、支付方式：
                </p>
                <p>
                  课程分在线课程和线下课程，在线课程需要在线支付，目前支持支付宝支付方式。线下课程即在线报名，确认课程席位后，线下完成付款。
                </p>
                <p>
                  2、我购买的课程怎么发给我？
                </p>
                <p>
                  用户购买成功的课程，会直接推送至用户的个人中心“我的课程”版块，用户直接进行观看。
                </p>
                <p>
                  3、我购买了课程，也扣款成功了， 但是我在哪能找到我买的课程？
                </p>
                <p>
                  登录个人账号，进入个人中心，购买成功的课程会在“我的课程”界面显示。
                </p>
              </section>
            </div>
          </el-collapse-item>
          <el-collapse-item title="线上课程购买" name="2">
            <div>
              <section>
                <span>线上课程购买</span>
                <p>购课流程</p>
                <p>1、注册，登录；</p>
                <p>2、选择要购买的课程；</p>
                <p>3、线上课程点击“立即购买”，线下课程点击“立即报名”；</p>
                <p>4、提交订单；</p>
                <p>5、点击“提交订单”，支付方式支持：支付宝、微信支付。</p>
                <p>6、支付完成后，您可通过“会员中心”，“订单管理”浏览您购买的课程。</p>
              </section>
            </div>
          </el-collapse-item>
          <el-collapse-item title="会员注册" name="3">
            <div>
              <section>
                <span>会员注册</span>
                <p>1、在会员专区页面进行注册</p>
                <p>2、按照提示填写姓名、所属公司、手机号</p>
                <p>3、获取验证码，填写正确</p>
                <p>4、设置密码</p>
                <p>5、支付会员费用</p>
              </section>
            </div>
          </el-collapse-item>
          <el-collapse-item title="发票相关" name="4">
            <div>
              <section>
                <span>发票相关</span>
                <p>如果需要发票，请拨打电话0516-83888208或者17712110809进行咨询，也直接加微信进行咨询。</p>
              </section>
            </div>
          </el-collapse-item>
          <el-collapse-item title="反馈问题" name="5">
            <div>
              <section>
                <span>反馈问题</span>
                <p>如遇问题，可以直接拨打客服热线0516-83888208或者17712110809</p>
              </section>
            </div>
          </el-collapse-item>
          <el-collapse-item title="用户服务条款" name="6">
            <div>
              <section>
                <span>用户服务条款</span>
                <p>
                  一、总则：
                </p>
                <p>
                  您在使用安评应急账号提供的各项服务之前，应仔细阅读本协议。用户应当同意本协议的条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击"同意"按钮即视为您已了解并完全同意本服务条款各项内容，与徐州安评应急培训有限公司达成协议，完全接受本协议项下的全部条款，包括安评应急培训账号对服务条款随时做的任何修改。
                </p>
                <p>
                  二、用户的帐号，密码和安全性
                </p>
                <p>
                  用户注册成功后，安评应急培训给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。您可随时根据指示改变您的密码。用户若发现任何非法使用用户帐号或存在安全漏洞的情况，请立即通告。
                </p>
                <p>
                  三、用户注册信息和隐私保护
                </p>
                <p>
                  1、安评应急培训帐号（即安评应急培训用户ID）的所有权归安评应急培训，用户完成注册申请手续后，获得安评应急培训帐号的使用权。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，安评应急培训不负任何责任。
                </p>
                <p>
                  2、安评应急培训不对外公开或向第三方提供单个用户的注册资料，除非：
                </p>
                <p>（1）事先获得用户的明确授权；</p>
                <p>（2）根据有关的法律法规要求；</p>
                <p>（3）按照相关政府主管部门的要求；</p>
                <p>（4）为维护安评应急培训的合法权益。</p>
                <p>
                  3、在你注册安评应急培训帐户，使用其他安评应急培训产品或服务，访问安评应急培训网页, 或参加活动时，安评应急培训会收集你的个人身份识别资料，并会将这些资料用于：改进为你提供的服务及网页内容。
                </p>
                <p>
                  四、使用规则
                </p>
                <p>
                 用户在使用安评应急培训服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶
                </p>
                <p>
                  1、上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：
                </p>
                <p>（1）反对宪法所确定的基本原则的；</p>
                <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                <p>（3）损害国家荣誉和利益的；</p>
                <p>（4）煽动民族仇恨、民族歧视、破坏民族团结的；</p>
                <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                <p>（8）侮辱或者诽谤他人，侵害他人合法权利的；</p>
                <p>（9）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
                <p>（10）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
                <p>2、不得为任何非法目的而使用网络服务系统；</p>
                <p>3、不利用安评应急培训服务从事以下活动：</p>
                <p>（1）未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
                <p>（2）未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
                <p>（3）未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
                <p>（4）故意制作、传播计算机病毒等破坏性程序的；</p>
                <p>（5）其他危害计算机信息网络安全的行为。</p>
                <p>
                  4、用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿安评应急培训与合作公司、关联公司，并使之免受损害。对此，安评应急培训有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收安评应急培训帐号、追究法律责任等措施。对恶意注册安评应急培训帐号或利用安评应急培训帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，安评应急培训有权回收其帐号。同时，安评应急培训公司会视司法部门的要求，协助调查。
                </p>
                <p>5、用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
                <p>6、用户须对自己在使用安评应急培训服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在安评应急培训公司首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予安评应急培训等额的赔偿。</p>
                <p>7、安评应急培训资源部分为安评应急培训网只有版权，部分资源来源于互联网资源。任何被授权的浏览、复制、打印和传播属于本网站内的资料必须符合以下条件：</p>
                <p>（1）所有的资料和图象均以获得信息为目的；</p>
                <p>（2）所有的资料和图象均不得用于商业目的；</p>
                <p>（3）所有的资料、图象及其任何部分都必须包括此版权声明；</p>
                <p>（4）安评应急培训为在线教育平台，平台上所有的产品、技术均属安评应急培训或课程版权人所有，在此并未授权。</p>
                <p>（5）“安评应急培训”及相关图形等为安评应急培训的注册商标。</p>
                <p>（6）未经安评应急培训许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用。否则，安评应急培训将依法追究法律责任。</p>
                <p>五、结束服务</p>
                <p>用户或可随时根据实际情况中断一项或多项网络服务。不需对任何个人或第三方负责而随时中断服务。用户对后来的条款修改有 异议，或对的服务不满，可以行使如下权利：</p>
                <p>1、停止使用的网络服务</p>
                <p>2、通告停止对该用户的服务</p>
                结束用户服务后，用户使用网络服务的权利马上中止。从那时起，用户没有权利，也没有义务传送任何未处理的信息或未完成的服务给用户或第三方。
                <p>六、法律
                网络服务条款要与中华人民共和国的法律解释相一致，用户和一致同意服从高等法院所有管辖。如发生服务条款与中华人民共和国法律相抵触时，则这些条款将完全按法律规定重新解释，而其它条款则依旧保持对用户产生法律效力和影响。</p>
                <p>七、其他</p>
                <p>1、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
                <p>2、双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向安评应急培训所在地的人民法院提起诉讼。</p>
                <p>3、安评应急培训未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</p>
                <p>4、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
                <p>请您在发现任何违反本服务协议以及其他任何单项服务的服务条款、安评应急培训各类公告之情形时，通知安评应急培训。您可以通过如下联络方式同安评应急培训联系：</p>
                <p>电子邮箱：2405056313@qq.com</p>
                <p>联系方式：17712110809</p>
              </section>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/common/components/Breadcrumb'
export default {
  name: 'help',
  components: {
    breadcrumb
  },
  data () {
    return {
      titles: {
        paymentProblems: '支付问题',
        onlineCourse: '线上课程购买',
        memberRegistration: '会员注册',
        invoice: '发票相关',
        feedback: '反馈问题',
        serviceClause: '用户服务条款'
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  }
}

</script>

<style lang='less' scoped>
  @media screen and (min-width: 1200px) {
    #help .helpContainer {
      width: 1200px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1200px) {
    #help .helpContainer {
      width: 100%;
    }
  }

  #help {
    .contentPC {
      display: flex;
      justify-content: space-between;
      .menu {
        width: 200px;
        height: 288px;
        overflow: hidden;
        border: 1px solid rgb(230, 230, 230);
        li {
          a {
            display: inline-block;
            width: 200px;
            height: 48px;
            line-height: 48px;
            margin-left: 20px;
            transition: color 0.5s linear;
          }
        }
        .isActive a{
          // background-color: rgb(16, 113, 223);
          color: rgb(233, 70, 9);
        }
        li:hover {
          background-color: rgb(236, 245, 255);
        }
      }
      div {
        width: calc(100% - 280px);
        border: 1px solid rgb(230, 230, 230);
        padding: 20px;
        li {
          display: none;
        }
        span {
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          margin: 16px 0px;
        }
        p {
          font-size: 16px;
          margin: 16px 0;
        }
      }
    }
    .contentMobile {
      /deep/ .el-collapse-item__header {
        font-size: 20px;
        padding-left: 5px;
      }
    }
  }

</style>

<template>
  <ul class="videoInfo">
    <li class="img isLink" @click="goto(id, price)">
      <div class="scale">
        <img :src="img" alt="安评" v-handle-img="$store.state.api + src">
      </div>
    </li> <!-- 可点击图片 -->
    <li class="title isLink" @click="goto(id)">
      <span>{{ title }}</span>
    </li> <!-- 可点击视频标题 -->
    <li class="tips">
      <span>分类：{{ type }}</span>
      <span>{{ uptime }}</span>
    </li>
    <li class="vip">
      <div>
        <div class="vipPrice">{{ price === 0 ? '免费' : '限时特惠' }}</div>
        <span class="price">￥{{ price / 100 }}元</span>
        <!-- <del>{{ delPrice }}</del> -->
      </div>
      <span>{{ shopCount }}人购买</span>
    </li>
    <!-- <li v-else class="free">
      <div>
        <div class="vipPrice">限时免费</div>
        <del>{{ delPrice }}</del>
      </div>
      <span>{{ shopCount }}人已观看</span>
    </li> -->
  </ul>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
export default {
  name: 'videoInfo',
  props: {
    goto: { // url跳转路径
      type: Function
    },
    src: { // 图片路径
      type: String,
      default: 'assets/images/安评-02.jpg'
    },
    title: { // 课程标题
      type: String,
      default: '2020 版生产安全事故案例警示片'
    },
    price: { // 课程现价
      type: Number,
      default: 0
    },
    isVipPrice: { // 是否使VIP视频
      type: Boolean,
      default: true
    },
    delPrice: { // 原价
      type: String,
      default: '￥33.9'
    },
    shopCount: { // 购买人数
      type: Number,
      default: 99
    },
    id: { // course id
      type: String
    },
    uptime: { // course uptime
      type: String
    },
    type: { // course type
      type: String
    }
  },
  data () {
    return {
      img: imgUrl
    }
  }
}
</script>

<style lang='less' scoped>
  .videoInfo {
    width: 250px;
    border: 1px solid rgb(217, 217, 217);
    box-shadow: 0 0 10px rgba(95, 101, 105, 0.15);
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    margin: 0;
    overflow: hidden;

    .isLink {
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    img {
      width: 100%;
      height: 160px;
    }
    .img  {
      width: 100%;
      height: 100%;
      height: 160px;
    }
    .title {
      width: 100%;
      margin: 10px 0px;
      font-size: 14px;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .tips {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #666;
    }
    .vip {
      display: flex;
      width: 100%;
      margin-top: 10px;
      justify-content: space-between;
      font-size: 12px;
      padding-bottom: 10px;
      .vipPrice{
        display: inline;
        color: white;
        background-color: red;
        border-radius: 3px;
        padding: 3px 3px;
      }
      .price {
        color: red;
        margin: 0 3px;
      }
      del {
        color: gray;
      }
      > span {
        color: #666;
      }
    }
    .free {
      display: flex;
      width: 100%;
      margin-top: 10px;
      justify-content: space-between;
      font-size: 12px;
      padding-bottom: 10px;
      .vipPrice{
        display: inline;
        color: white;
        background-color: red;
        border-radius: 3px;
        padding: 3px 3px;
      }
      del {
        color: gray;
        margin: 0 5px;
      }
      > span {
        color: #666;
      }
    }
  }
  .scale {
    overflow: hidden;
    transition: transform 0.2s linear;
    &:hover {
      transform: scale(1.05);
    }
  }
</style>

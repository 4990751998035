<template>
  <div class="news">
    <div class="date" v-if="sort">
      <mis-icon icon="1" v-if="sort === 1" font-size="38px"></mis-icon>
      <mis-icon icon="2" v-if="sort === 2" font-size="38px"></mis-icon>
      <mis-icon icon="3" v-if="sort === 3" font-size="38px"></mis-icon>
      <mis-icon icon="4" v-if="sort === 4" font-size="38px"></mis-icon>
      <mis-icon icon="5" v-if="sort === 5" font-size="38px"></mis-icon>
      <mis-icon icon="6" v-if="sort === 6" font-size="38px"></mis-icon>
    </div>
    <div class="newsContent" v-if="sort">
      <p class="title">{{ title }}</p>
    </div>
<!--    <div class="date" v-if="!sort">
      <p>{{ uptime.split('-')[0] }}</p>
      <p>{{ uptime.split('-')[1] }}-{{ uptime.split('-')[2] }}</p>
    </div>
    <div class="newsContent" v-if="!sort">
      <router-link :to="{
        name: 'newsDetails',
        query: {
          id: id,
          module: 'companyNews'
        }
      }">
        <p class="title">{{ title }}</p>
      </router-link>
      <p>{{ summary }}</p>
    </div>-->
  </div>
</template>

<script>
export default {
  name: 'news',
  props: {
    sort: {
      type: Number,
      default: null
    },
    title: { // 标题
      type: String,
      default: '安评应急培训'
    },
    summary: { // 内容
      type: String,
      default: '对每个企业而言，定制开发的软件合适的就是好的。标准+量身定制=100%满足企业的业务管理，这就是我们追求的！'
    },
    uptime: {
      type: String,
      default: '2000-2-21'
    },
    id: {
      type: String
    }
  }
}
</script>

<style lang='less' scoped>
  .news {
    display: flex;
    // height: 80px;
    box-sizing: border-box;
    overflow: hidden;
    // justify-content: flex-start;
    p {
      margin: 0;
    }
    .date {
      position: relative;
      // width: 100px;
      text-align: right;
      margin-right: 20px;
      color: rgb(137, 137, 137);
      p:nth-child(1) {
        position: absolute;
        font-size: 18px;
        line-height: 40px;
        top: 0;
        right: 6px;
      }
      p:nth-child(2) {
        position: absolute;
        line-height: 36px;
        font-size: 34px;
        bottom: 0;
        right: 0;
      }
    }
    .newsContent {
      transition: background-color 0.2s linear;
      flex: 1;
      color: rgb(110, 110, 110);
      p:nth-child(1) {
        font-size: 18px;
        height: 38px;
        line-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
        word-wrap: break-word;
        transition: color 0.2s linear;
      }
      p:nth-child(2) {
        font-size: 14px;
        line-height: 18px;
        height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp:2;
        word-break: break-all;
        word-wrap: break-word;
      }
    }

    &:hover {
      background-color: rgba(234,234,234, .6);
    }
    &:hover .title {
      color: #f00;
    }
  }
</style>

<template>
  <div id="company">
    <info title="工程业绩" refAnimal="company" content1="丰富行业实战经验" content2="始终践行创新精神、破解行业难题" />
    <div class="intro">
      <div class="left animated" v-scroll-class="'fadeInUpBig'">
        <div class="imgContainer">
          <img class="imgAnimate" src="@/assets/images/安评应急培训/intro.jpg" alt="">
        </div>
      </div>
      <div class="right animated"  v-scroll-class="'fadeInUpBig'">
        <div>
          <p class="title"><span>XXXX大厦消防系统</span></p>
          <p class="content">
            徐州安评应急培训有限公司专注于矿井设备检测技术培训和矿山安全生产管理培训，与中国矿业大学及矿一科技共同结合，拥有丰富的教育资源和行业监管实战经验的特点，针对检测检验机构、科研院所、矿业企业、高校师生、装备制造业的科研和工程技术人员等的培训需求，可定制培训方案，坚持专业化、创新化的系统培训。我们以紧跟政策，创新发展，传播先进科学技术成果，推动行业技术进步为经营方针，深耕矿山安全监管行业，打造专业矿井设备技术培训和应急培训产业。
          </p>
          <button @click="goto" v-add-class="'swing'" class="animated">
            了解更多
            <i class="el-icon-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="intro">
      <div class="right animated" v-scroll-class="'fadeInUpBig'">
        <div>
          <p class="title"><span>XXXX大厦消防系统</span></p>
          <p class="content">
            徐州安评应急培训有限公司专注于矿井设备检测技术培训和矿山安全生产管理培训，与中国矿业大学及矿一科技共同结合，拥有丰富的教育资源和行业监管实战经验的特点，针对检测检验机构、科研院所、矿业企业、高校师生、装备制造业的科研和工程技术人员等的培训需求，可定制培训方案，坚持专业化、创新化的系统培训。我们以紧跟政策，创新发展，传播先进科学技术成果，推动行业技术进步为经营方针，深耕矿山安全监管行业，打造专业矿井设备技术培训和应急培训产业。
          </p>
          <button @click="goto" v-add-class="'swing'" class="animated">
            了解更多
            <i class="el-icon-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="left animated"  v-scroll-class="'fadeInUpBig'">
        <div class="imgContainer">
          <img class="imgAnimate" src="@/assets/images/安评应急培训/intro.jpg" alt="">
        </div>
      </div>
    </div>

<!--    <div class="business">
      <div class="left animated" v-scroll-class="'fadeInUpBig'">
        <p class="title"><span>纵深精耕</span> 覆盖多种行业安全应急培训</p>
        <p class="content">
          安评应急培训积累了丰富的安全应急理论知识和实践经验，可以根据行业特点量身定制培训内容，把控行业技术关键点
          <br />
          全面分析数据，为企业带来快速的、直接的效益和价值的同时，还可以大幅度降低人均培训成本。
        </p>
      </div>
      <ul class="right animated" v-scroll-class="'fadeInUpBig'">
        <li v-add-class class="animated" v-scroll-class>
          <skill title="矿井设备检测培训" icon="el-icon-coordinate" :content="content[0]" src="assets/images/安评应急培训/安全生产.jpg" />
        </li>
        <li v-add-class class="animated">
          <skill title="矿井作业人员培训" icon="el-icon-aim" :content="content[1]" src="assets/images/安评应急培训/自然灾害.jpg" />
        </li>
        <li v-add-class class="animated">
          <skill title="检测机构资质培训" icon="el-icon-magic-stick" :content="content[2]" src="assets/images/安评应急培训/职业技能.jpg" />
        </li>
      </ul>
    </div>-->
  </div>
</template>

<script>
import info from '@/components/index/Info'
import skill from '@/components/index/Skill'
export default {
  components: { info, skill },
  name: 'company',
  data () {
    return {
      activeValue: 'first',
      autoWidth: '1600px',
      isActive: 0,
      content: [
        [
          '煤矿',
          '非煤矿',
          '危险化学品',
          '烟花爆竹',
          '金属冶炼',
          '特种作业'
        ],
        [
          '火灾',
          '水旱灾害',
          '地址灾害'
        ],
        [
          '高压电工',
          '低压电工',
          '焊工'
        ]
      ]
    }
  },

  created() {
  },

  methods: {
    goto (item) {
      this.$router.push({
        path: '/projectDetail',
        query: item
      })
    }
  }
}
</script>

<style lang='less' scoped>
  /* 媒体查询，当文档宽度大于1200px时 */
  @media screen and (min-width: 1200px) {
    .business, .intro {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1200px) {
    .business, .intro {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .business, .intro {
      flex-wrap: wrap;
    }
    .business .left, .intro .right {
      width: 100%;
      height: 350px;
      padding: 10px;
      text-align: center;
    }
    .business .right, .intro .left {
      width: 100%;
      height: 350px;
      overflow: hidden;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 767px) {
    #company > .business{
      padding-bottom: 100px;
      padding-top: 50px;
    }
     #company > .intro {
      padding-top: 100px;
    }
    .business .left {
      padding: 40px;
    }
    .business .left, .intro .right {
      height: 400px;
      width: 46%;
    }
    .business .right, .intro .left  {
      height: 400px;
      width: 46%;
    }
  }
  #company {
    background-color: rgb(238,238,238);
    .business {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      background-color: rgb(238,238,238);
      .left {
        box-sizing: border-box;
        background-image: url('~@/assets/images/安评应急培训/纵深精工.jpg');
        background-size: 100% 100%;
        .title {
          font-size: 20px;
        }
        .title span {
          position: relative;
        }
        .title span:after {
          content: '';
          position: absolute;
          display: block;
          height: 5px;
          bottom: -10px;
          left: 0;
          right: 0;
          border-radius: 3px;
          background-color: rgb(13,111,184);
          z-index: 1;
        }
        .content {
          margin-top: 30px;
          font-size: 13px;
          line-height: 18px;
        }
      }
      .right {
        display: flex;
        // overflow: hidden;
        justify-content: space-between;
        > li {
          flex: 1;
        }
      }
    }

    .intro {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      background-color: rgb(238,238,238);
      .left {
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        padding: 0 20px;
        .title {
          font-size: 20px;
        }
        .title span {
          position: relative;
        }
        .title span:after {
          content: '';
          position: absolute;
          display: block;
          height: 5px;
          bottom: -10px;
          left: 0;
          right: 0;
          border-radius: 3px;
          background-color: rgb(13,111,184);
          z-index: 1;
        }
        .content {
          margin-top: 30px;
          font-size: 13px;
          line-height: 21px;
          letter-spacing: 1px;
        }
        button {
          outline: none;
          border: none;
          padding: 8px 15px;
          background-color: rgb(26, 70, 161);
          color: #fff;
          border-radius: 15px;
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          margin-top: 20px;
        }
      }
    }
  }
</style>

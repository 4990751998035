<template>
  <div id="videoShow">
    <div class="top">
      <dplayer
      :id="course.id"
      :pic="course.introduceimg"
      :title="course.name"
      :studyPeople="course.ordernum"
      :playNumber="course.visit" />
    </div>
    <div class="bottom">
      <bottom-synthesize
      :tabs="tabs">
      <div slot="first">
        <div v-html="course.introducecontent"></div>
      </div>
      <!-- <div slot="second">
        <div class="second">
          <lecturer />
        </div>
      </div>
      <div slot="three">
        <div class="three">
          <p>这是购买指南</p>
        </div>
      </div>
      <div slot="four"> -->
        <!-- <div class="four">
          <div>
            <span>本课程评分：</span>
            <el-rate
              v-model="score"
              :colors="colors">
            </el-rate>
          </div>
          <div>
            <textarea id="" cols="30" rows="10" placeholder="在这里输入您的评价内容哦，亲！"></textarea>
            <el-button type="primary" @click="submitEvaluate">提交评价</el-button>
          </div>
        </div>
      </div> -->
      </bottom-synthesize>
    </div>
  </div>
</template>

<script>
import bottomSynthesize from '@/common/components/BottomSynthesize'
import { getCurriculum } from '@/api/course'
import dplayer from '@/common/components/Dplayer'
export default {
  name: 'videoShow',
  components: {
    bottomSynthesize,
    dplayer
  },
  data () {
    return {
      // tabs: ['课程介绍', '讲师介绍', '购买指南', '评价'],
      tabs: ['课程介绍'],
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      score: null,
      course: {
        introducecontent: null
      },
      videoUrl: '',
      isShow: false
    }
  },
  methods: {
    async loadVideo (id) {
      const res = await getCurriculum(id)
      if (res.code === 200) {
        if (res.data) {
          this.course = res.data
          if (this.course.cost > 0) { // 价格超过0时
            if (this.$store.state.isLoginType === 1 || this.$store.state.isLoginType === 2) { // 已登陆
              if (this.$route.query.out_trade_no && this.$route.query.total_amount) {
                this.$message.success('您当前正在观看完整版')
              }
            } else { // 未登录
              const loading = this.$loading({
                lock: true,
                text: '此课程面向注册付费会员，即将前往登录页面',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.9)'
              })
              setTimeout(() => {
                this.$router.push({
                  name: 'login'
                })
                loading.close()
              }, 2000)
            }
          }
        } else {
          const loading = this.$loading({
            lock: true,
            text: '此课程不存在，即将返回会员专区',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.9)'
          })
          setTimeout(() => {
            this.$router.push({
              name: 'vip'
            })
            loading.close()
          }, 2000)
        }
      } else {
        this.$message.error('请求错误，请稍后再试')
      }
    },
    submitEvaluate () {
      this.$message.warning('评价功能暂未实现')
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    const id = this.$route.query.id
    if (id) {
      this.loadVideo(id)
    } else {
      const loading = this.$loading({
        lock: true,
        text: '此课程不存在即将返回会员专区',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.9)'
      })
      setTimeout(() => {
        this.$router.push({
          name: 'vip'
        })
        loading.close()
      }, 2000)
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #videoShow {
      .bottom {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    #videoShow {
      .bottom {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  @media screen and (min-width: 768px) {
    #videoShow {

    }
  }
  #videoShow {
    .top {
      padding-bottom: 10px;
      background-color: rgb(50,59,64);
    }
    .bottom {
      margin-top: 20px;
    }
    .second {
      width: 300px;
      margin: 0 auto;
    }
    .four {
      width: 100%;
      > div:first-child {
        display: flex;
        margin: 0 auto;
        justify-content: flex-start;
      }
      > div:last-child {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 10px;
      }
      textarea {
        outline: 1px solid rgb(65,168,247);
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
</style>

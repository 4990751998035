<template>
  <div class="partnerAcademy">
    <info
      title="讲师团队"
      refAnimal="partnerAcademy"
      content1="来自于各名校名院"
      content2="拥有专业知识，行业实践经验"
    />
    <ul class="academyList" v-if="this.$store.state.IEversion === 10 || this.$store.state.IEversion === 11">
      <li v-for="(item, index) in tableData" :key="index" @click="goto" class="animated academyClick" v-scroll-class="'fadeInUpBig'"
      :style="{ backgroundImage: 'url(' + require('@/assets/images/academy/academyBg' + (index + 1) + '.jpg') + ')' }">
        <academy
        :src="item.icon"
        :academyName="item.introducetitle"
        :academyIntro="item.introducecontent" />
      </li>
    </ul>
    <main class="page-content" v-else>
      <div class="card" v-for="(item, index) in tableData" :key="index">
        <div class="content">
          <h2 class="title">{{ item.introducetitle }}</h2>
          <p class="copy">{{ item.introducecontent }}</p>
          <button class="btn">查看简介</button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { listMechanism } from '@/api/partnerAcademy'
import academy from './Academy'
import info from '@/components/index/Info'
export default {
  name: 'partnerAcademy',
  components: { info, academy },
  data () {
    return {
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      tableData: []
    }
  },
  methods: {
    async loadList () {
      try {
        const res = await listMechanism({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          name: this.search
        })
        if (res.code === 200) {
          this.total = res.total
          this.tableData = res.rows
        }
      } catch (error) {
        this.$message.error('未知错误，请重新尝试')
      }
    },
    goto () {
      this.$router.push({
        name: 'partnerAcademy'
      })
    }
  },
  created () {
    this.loadList()
  }
}
</script>

<style lang="less" scoped>
/* 媒体查询，当文档宽度大于1200px时 */
@media screen and (min-width: 1200px) {
  .partnerAcademy {
    width: 1200px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  .partnerAcademy {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .partnerAcademy {
    .academyList > li {
      // width: 46%;
    }
  }
}
@media screen and (min-width: 768px) {
  .partnerAcademy {
    .academyList > li {
      // width: 23%;
    }
  }
}
.partnerAcademy {
  overflow: hidden;
  // background-image: url('~@/assets/images/qyyy.jpg');
  // background-size: cover;
  .academyList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > li {
      margin: 10px;
      overflow: hidden;
      flex: 1;
      background-size: cover;
    }
  }
  .academyClick {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}
// 卡片特效
.page-content {
  display: grid;
  grid-gap: 1rem;
  width: 100%;
  font-family: "Rubik", sans-serif;
}
@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(4, 1fr);
  }
}
.card {
  position: relative;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  width: 100%;
  text-align: center;
  color: whitesmoke;
  background-size: cover;
  background-color: whitesmoke;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 2px 2px rgba(0, 0, 0, 0.1), 0 4px 4px rgba(0, 0, 0, 0.1), 0 8px 8px rgba(0, 0, 0, 0.1), 0 16px 16px rgba(0, 0, 0, 0.1);
}
@media (min-width: 600px) {
  .card {
    height: 400px;
  }
}
.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background-size: cover;
  background-position: 0 0;
  transition: transform calc(700ms * 1.5) cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: none;
}
.card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.009) 11.7%, rgba(0, 0, 0, 0.034) 22.1%, rgba(0, 0, 0, 0.072) 31.2%, rgba(0, 0, 0, 0.123) 39.4%, rgba(0, 0, 0, 0.182) 46.6%, rgba(0, 0, 0, 0.249) 53.1%, rgba(0, 0, 0, 0.32) 58.9%, rgba(0, 0, 0, 0.394) 64.3%, rgba(0, 0, 0, 0.468) 69.3%, rgba(0, 0, 0, 0.54) 74.1%, rgba(0, 0, 0, 0.607) 78.8%, rgba(0, 0, 0, 0.668) 83.6%, rgba(0, 0, 0, 0.721) 88.7%, rgba(0, 0, 0, 0.762) 94.1%, rgba(0, 0, 0, 0.79) 100%);
  transform: translateY(-50%);
  transition: transform calc(700ms * 2) cubic-bezier(0.19, 1, 0.22, 1);
}
.card:nth-child(1):before {
  background-image: url('~@/assets/images/academy/academyBg1.jpg');
}
.card:nth-child(2):before {
  background-image: url('~@/assets/images/academy/academyBg2.jpg');
}
.card:nth-child(3):before {
  background-image: url('~@/assets/images/academy/academyBg3.jpg');
}
.card:nth-child(4):before {
  background-image: url('~@/assets/images/academy/academyBg4.jpg');
}
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1;
}
.content > * + * {
  margin-top: 1rem;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  font-family: "Cardo", serif;
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  color: white;
  background-color: black;
  border: none;
}
.btn:hover {
  background-color: #0d0d0d;
}
.btn:focus {
  outline: 1px dashed yellow;
  outline-offset: 3px;
}

@media (hover: hover) and (min-width: 600px) {
  .card:after {
    transform: translateY(0);
  }

  .content {
    transform: translateY(calc(100% - 4.5rem));
  }
  .content > *:not(.title) {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 700ms cubic-bezier(0.19, 1, 0.22, 1), opacity 700ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  .card:hover,
.card:focus-within {
    align-items: center;
  }
  .card:hover:before,
.card:focus-within:before {
    transform: translateY(-4%);
  }
  .card:hover:before {
    transform: translateY(10px);
  }
  .card:hover:after,
.card:focus-within:after {
    transform: translateY(-50%);
  }
  .card:hover .content,
.card:focus-within .content {
    transform: translateY(0);
  }
  .card:hover .content > *:not(.title),
.card:focus-within .content > *:not(.title) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: calc(700ms / 8);
  }

  .card:focus-within:before, .card:focus-within:after,
.card:focus-within .content,
.card:focus-within .content > *:not(.title) {
    transition-duration: 0s;
  }
}
</style>

<template>
  <div class="serviceProcess">
    <info
    title="服务流程"
    fontColor="#fff"
    refAnimal="serviceProcess"
    content1="全程1V1服务，实时追踪反馈，确保项目准时保质保量交付"
    />
    <div class="newsContainer">
      <div class="service-item animated1" v-scroll-class="'slideInUp'">
        <mis-icon icon="tijiao" font-size="88px"></mis-icon>
        <p>提交您的需求</p>
      </div>

      <div class="line animated11" v-scroll-class="'slideInUp'"></div>

      <div class="service-item animated2" v-scroll-class="'slideInUp'">
        <mis-icon icon="zhuanren" font-size="88px"></mis-icon>
        <p>专人接洽</p>
      </div>

      <div class="line animated21" v-scroll-class="'slideInUp'"></div>

      <div class="service-item animated3" v-scroll-class="'slideInUp'">
        <mis-icon icon="dingdan" font-size="88px"></mis-icon>
        <p>订单处理与企业的生产</p>
      </div>

      <div class="line animated31" v-scroll-class="'slideInUp'"></div>

      <div class="service-item animated4" v-scroll-class="'slideInUp'">
        <mis-icon icon="wuliu" font-size="88px"></mis-icon>
        <p>物流运输</p>
      </div>

      <div class="line animated41" v-scroll-class="'slideInUp'"></div>

      <div class="service-item animated5" v-scroll-class="'slideInUp'">
        <mis-icon icon="jishu" font-size="88px"></mis-icon>
        <p>技术支持和服务</p>
      </div>

      <div class="line animated51" v-scroll-class="'slideInUp'"></div>

      <div class="service-item animated6" v-scroll-class="'slideInUp'">
        <mis-icon icon="kehu" font-size="88px"></mis-icon>
        <p>客户验收</p>
      </div>
    </div>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
import info from '@/components/index/Info'
import news from '@/components/index/News'
export default {
  name: 'newsList',
  components: { info, news },
  data () {
    return {
      img: imgUrl
    }
  }
}
</script>

<style lang='less' scoped>
/* 媒体查询，当文档宽度大于1200px时 */
  @media screen and (min-width: 768px) {
    .newsContainer {
      width: 1200px;
      margin: 0 auto;

      .line {
        display: block;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .newsContainer {
      width: 100%;

      .svg-icon {
        font-size: 68px;
      }

      .line {
        display: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .serviceProcess {
    }
  }
  @media screen and (min-width: 768px) {
    .serviceProcess {
    }
  }

.serviceProcess {
  margin-top: 20px;
  background: url("../../assets/images/bg-flow.png") 100% 100% no-repeat;
}

  .newsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    // min-height: 300px;
    padding: 10px 0;

    .service-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      width: 140px;
      box-sizing: border-box;
      transition: .5s;

      p {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        // padding-bottom: 50px;
        margin-top: -50px;
      }
    }

    .line {
      background: #909399;
      width: 30px;
      height: 1px;
      margin-bottom: 55px;
    }
  }
</style>

<style lang="less">
.animated1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated11 {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated2 {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated21 {
  -webkit-animation-duration: 1.75s;
  animation-duration: 1.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated3 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated31 {
  -webkit-animation-duration: 2.25s;
  animation-duration: 2.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated4 {
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated41 {
  -webkit-animation-duration: 2.75s;
  animation-duration: 2.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated5 {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated51 {
  -webkit-animation-duration: 3.25s;
  animation-duration: 3.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.animated6 {
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
  }
}

  .fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp
  }
</style>

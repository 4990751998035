import request from '@/utils/request'

// 查询院校、讲师、团队、机构列表
export function listMechanism (query) {
  return request({
    url: '/back/mechanism/list',
    method: 'get',
    params: query
  })
}

// 查询院校、讲师、团队、机构详细
export function getMechanism (id) {
  return request({
    url: '/back/mechanism/' + id,
    method: 'get'
  })
}

// 新增院校、讲师、团队、机构
export function addMechanism (data) {
  return request({
    url: '/back/mechanism',
    method: 'post',
    data: data
  })
}

// 修改院校、讲师、团队、机构
export function updateMechanism (data) {
  return request({
    url: '/back/mechanism',
    method: 'put',
    data: data
  })
}

// 删除院校、讲师、团队、机构
export function delMechanism (id) {
  return request({
    url: '/back/mechanism/' + id,
    method: 'delete'
  })
}

// 导出院校、讲师、团队、机构
export function exportMechanism (query) {
  return request({
    url: '/back/mechanism/export',
    method: 'get',
    params: query
  })
}

import request from '@/utils/request'

// 查询首页优势列表
export function listAdvantage (query) {
  return request({
    url: '/back/advantage/list',
    method: 'get',
    params: query
  })
}

// 查询首页优势详细
export function getAdvantage (id) {
  return request({
    url: '/back/advantage/' + id,
    method: 'get'
  })
}

// 新增首页优势
export function addAdvantage (data) {
  return request({
    url: '/back/advantage',
    method: 'post',
    data: data
  })
}

// 修改首页优势
export function updateAdvantage (data) {
  return request({
    url: '/back/advantage',
    method: 'put',
    data: data
  })
}

// 删除首页优势
export function delAdvantage (id) {
  return request({
    url: '/back/advantage/' + id,
    method: 'delete'
  })
}

// 导出首页优势
export function exportAdvantage (query) {
  return request({
    url: '/back/advantage/export',
    method: 'get',
    params: query
  })
}

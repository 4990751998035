<template>
  <div id="footTips">
    <div class="footDesc">
      <p class="animated" v-scroll-class="'swing'">优质服务、悉心维护、专业经营、诚信服务、客户至上</p>
    </div>
    <div class="footContainer">
      <h1 class="logo hidden-xs-only">
        <img src="@/assets/images/logo/pufeite.png" alt="普飞特电子科技">
        <span>徐州普飞特电子科技有限公司</span>
      </h1>
<!--      <ul>
        <li>新手指南</li>
        <li><router-link :to="{ name: 'help', query: { id: 'paymentProblems' } }">支付问题</router-link></li>
        <li><router-link :to="{ name: 'help', query: { id: 'onlineCourse' } }">线上课程购买</router-link></li>
        <li><router-link :to="{ name: 'help', query: { id: 'memberRegistration' } }">会员注册</router-link></li>
      </ul>-->
<!--      <ul>
        <li>售后问题</li>
        <li><router-link :to="{ name: 'help', query: { id: 'invoice' } }">发票相关</router-link></li>
        <li><router-link :to="{ name: 'help', query: { id: 'feedback' } }">反馈问题</router-link></li>
      </ul>-->
      <ul>
        <li>联系方式</li>
        <li>地址：江苏省徐州市泉山区工程学院大学科技园K座163室</li>
        <li>电话：0516-83891162</li>
        <li>传真：0516-83891162</li>
        <li>邮箱：pfttech@163.com</li>
      </ul>
      <ul>
        <li>关于我们</li>
        <li><img src="@/assets/images/qrCode/pftwx.png" alt="普菲特"></li>
        <li style="text-align: center;">微信公众号</li>
      </ul>
    </div>
    <div class="aq">
      <h2>徐州普飞特电子科技有限公司</h2>
<!--      <span>苏ICP备2021016579号-1</span>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'footTips'
}
</script>

<style lang="less" scoped>
  @media screen and (min-width: 1200px) {
    #footTips  .footContainer {
      width: 1200px;
      margin: 0 auto;
    }
    #footTips .aq {
      padding-bottom: 10px;
    }
  }
  @media screen and (max-width: 1200px) {
    #footTips .footContainer {
      width: 100%;
    }

    #footTips .aq {
      padding-bottom: 68px;
    }
  }
  #footTips {
    margin-top: 60px;
    background-color: #292929;
    color: #fff;

    .footDesc {
      background: url("../../assets/images/bg-ad.png") 100% 100%;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100% 100%;
      padding: 15px 0;
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: #fff;

      p {
        text-align: center;
        padding: 0 20px;
        line-height: 30px;
      }
    }

    .footContainer {
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      h1 {
        // width: 160px;
        // height: 160px;
        margin: 10px;
        overflow: hidden;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        img {
          // width: 100%;
          height: 50px;
          transform-origin: center center;
          // margin-top: -15px;
        }

        span {
          font-size: 14px;
          display: block;
        }
      }
      ul {
        margin: 10px;
        li:first-child {
          font-weight: bold;
          margin-bottom: 20px;
        }
        li {
          margin: 10px 0;
        }
      }
      img {
        width: 160px;
        height: 160px;
      }
      a {
        color: #fff;
      }
    }

    .aq {
      display: flex;
      justify-content: center;
      background: #212121;
      padding-top: 10px;

      h2 {
        font-size: 12px;
        margin: 0;
        padding: 0;
        color: #808080;
      }
      span {
        font-size: 12px;
        margin: 0;
        padding: 0;
        margin-left: 10px;
      }
    }
  }
</style>

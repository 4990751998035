import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './common/styles/global.css'

// 动画animate
import './utils/jquery-1.8.3.min'
import './common/styles/animate.min.css'

import '@/assets/svg.js'
import MisIcon from '@/components/MisIcon.vue' // svg组件

// CND加速
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
Vue.use(ElementUi)

// 注册全局插件
Vue.component('mis-icon', MisIcon)

// elementui 按需引入
// import { Button, Message, Card, Dialog, Tabs, Dropdown, DropdownMenu, DropdownItem, Backtop, Pagination, Select, Input, Option, Breadcrumb, BreadcrumbItem, Menu, MenuItem, Form, FormItem, Collapse, CollapseItem, MessageBox, Checkbox, TabPane, Radio, RadioGroup, Loading, Image as elImage } from 'element-ui'
// Vue.use(Button)
// Vue.use(Card)
// Vue.use(Dialog)
// Vue.use(Tabs)
// Vue.use(Dropdown)
// Vue.use(DropdownMenu)
// Vue.use(DropdownItem)
// Vue.use(Backtop)
// Vue.use(Pagination)
// Vue.use(Select)
// Vue.use(Input)
// Vue.use(Option)
// Vue.use(Breadcrumb)
// Vue.use(BreadcrumbItem)
// Vue.use(Menu)
// Vue.use(MenuItem)
// Vue.use(Form)
// Vue.use(elImage)
// Vue.use(FormItem)
// Vue.use(Collapse)
// Vue.use(CollapseItem)
// Vue.use(Checkbox)
// Vue.use(TabPane)
// Vue.use(Radio)
// Vue.use(RadioGroup)
// Vue.use(Loading)
// Vue.component(MessageBox.name, MessageBox)
// Vue.component(Message.name, Message)

// Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$loading = Loading.service
// Vue.prototype.$message = Message

/**
 * 检测图片是否存在
 */
const imageIsExist = function (url) {
  return new Promise(resolve => {
    var img = new Image()
    img.onload = function () {
      if (this.complete === true) {
        resolve(true)
        img = null
      }
    }
    img.onerror = function () {
      resolve(false)
      img = null
    }
    img.src = url
  })
}

Vue.directive('handle-img', async function (el, binding, vnode) {
  const imgUrl = binding.value // 获取图片地址
  if (imgUrl) {
    const exist = await imageIsExist(imgUrl)
    if (exist) {
      el.setAttribute('src', imgUrl)
    }
  }
})

// 自定义指令，鼠标移入移出添加类名
Vue.directive('add-class', {
  bind (el, binding) {
    const className = binding.value
    el.addEventListener('mouseover', () => {
      el.classList.add(className)
    })
    el.addEventListener('mouseout', () => {
      el.classList.remove(className)
      el.removeEventListener('mouseover', () => {
        el.classList.add(className)
      })
      el.removeEventListener('mouseout', () => {
        el.classList.remove(className)
      })
    })
  }
})
function addClass (el, binding) {
  const className = binding.value
  // 获取视口高度
  const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  // 获取元素距离视口最上角的距离
  const top = el.getBoundingClientRect() && el.getBoundingClientRect().top
  if (top < viewPortHeight) {
    el.classList.add(className)
    window.removeEventListener('scroll', function () {
      addClass(el, binding)
    }, true)
  }
}
// 自定义指令，元素出现在视口则添加入场效果
Vue.directive('scroll-class', {
  bind (el, binding) {
    window.addEventListener('scroll', function () {
      addClass(el, binding)
    }, true)
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("render-event"))
}).$mount('#app')

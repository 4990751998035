<template>
  <div id="contact">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>联系我们</span>
      </div>
      <ul>
        <li class="locationBack">
          <img src="@/assets/images/logo/location.png" alt="">
        </li>
        <li>
          徐州普飞特电子科技有限公司
        </li>
        <li>
          座机：0516-83891162
        </li>
        <li>
          传真：0516-83891162
        </li>
        <li>
          邮箱：pfttech@163.com
        </li>
        <li>
          公司地址：江苏省徐州市泉山区工程学院大学科技园K座163室
        </li>
        <li class="wxcode">
          <div>
            <img class="wx" src="../../assets/images/qrCode/pftwx.png" alt="微信二维码" title="普飞特电子科技">
            <br>
            <span>微信二维码</span>
          </div>
<!--          <div>-->
<!--            <img class="wx" :src="$store.state.api + tableData.weixinofficial" alt="公众号二维码" title="安评应急培训">-->
<!--            <br>-->
<!--            <span>微信公众号</span>-->
<!--          </div>-->
        </li>
        <li>
        </li>
      </ul>
    </el-card>
  </div>
</template>

<script>
import { listInfo } from '@/api/company'
export default {
  name: 'contact',
  data () {
    return {
      tableData: [],
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined // 搜索内容
    }
  },
  methods: {
    async loadList () {
      try {
        const res = await listInfo({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          name: this.search
        })
        if (res.code === 200) {
          this.total = res.total
          this.tableData = res.rows[0]
        }
      } catch (error) {
        this.$message.error('未知错误，请重新尝试')
      }
    },
    onSearch () {
      this.pageNum = 10
      this.pageNum = 1
      this.loadList()
    }
  },
  created () {
    // this.loadList()
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 767px) {
    .map {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    .map {
      width: 670px;
      height: 505px;
    }
  }
  #contact {
    .box-card {
      margin: 0 auto;
      margin-top: 20px;
      width: 90%;
      text-align: left;
    }
    .clearfix {
      span {
        font-size: 20px;
      }
    }
    ul {
      padding: 0;
    }
    li {
      font-family: Arial;
      color: #6b6b6b;
      line-height: 27px;
      margin: 0;
    }
    li:first-child {
      width: 100%;
      // height: 300px;
      // background: url("../../assets/images/logo/location.png") no-repeat;
      // background-position: center center;
      // background-size: 100%;
      margin-bottom: 20px;

      img{
        width: 100%;
      }
    }
    .wx {
      width: 120px;
    }
    .wxcode {
      display: flex;
      text-align: center;
    }
  }
</style>

<template>
  <div id="industryInfo">
    <breadcrumb />
    <div v-if="!$store.state.industry.industryList" style="text-align: center;">正在加载中...</div>
    <ul v-else>
      <li v-for="(item, index) in $store.state.industry.industryList" :key="index" ref="lis">
        <div v-if="item.state === '上架'">
          <info
          :month="item.uptime.split('-')[1]"
          :day="item.uptime.split('-')[2]"
          :year="item.uptime.split('-')[0]"
          :src="item.img"
          :id="item.id"
          :title="item.title"
          :summary="item.summary"
          :goto="goto"/>
        </div>
      </li>
    </ul>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="$store.state.news.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import info from '@/common/components/Info'
import breadcrumb from '@/common/components/Breadcrumb'
import { mapActions } from 'vuex'
// import { listConsulting } from '@/api/industryInfo'
export default {
  name: 'industryInfo',
  components: {
    info,
    breadcrumb
  },
  data () {
    return {
      // tableData: [],
      submitStatus: 0, // 1 增加 2 修改
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      isLoading: true
    }
  },
  methods: {
    ...mapActions(['getIndustryList']),
    // async loadList () {
    //   try {
    //     const res = await listConsulting({
    //       pageSize: this.pageSize,
    //       pageNum: this.pageNum,
    //       title: this.search
    //     })
    //     if (res.code === 200) {
    //       this.total = res.total
    //       this.tableData = res.rows
    //       this.isLoading = false
    //     }
    //   } catch (error) {
    //     this.$message.error('未知错误，请重新尝试')
    //   }
    // },
    loadList () {
      // 请求公共新闻数据
      this.getIndustryList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        title: this.search
      })
    },
    onSearch () {
      this.pageSize = 10
      this.pageNum = 1
      this.loadList()
    },
    goto (id) {
      this.$router.push({
        name: 'newsDetails',
        query: {
          id: id,
          module: 'industryInfo'
        }
      })
    },
    // 分页每页数量发生改变
    handleSizeChange (val) {
      this.pageSize = val
      this.loadList()
    },
    // 分页当前页码发生改变
    handleCurrentChange (val) {
      this.pageNum = val
      this.loadList()
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    this.loadList()
  }
}
</script>

<style lang='less' scoped>
    @media screen and (max-width: 1200px) {
    #industryInfo {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #industryInfo {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 768px) {
    #industryInfo {

    }
  }
  #industryInfo {
    overflow: hidden;
    ul, li {
      margin: 0;
      padding: 0;
    }
    li {
      border-bottom: 1px solid #f0f0f0;
    }
  }
</style>

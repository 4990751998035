<template>
  <div class="academy" :style="{ height: autoHeight, backgroundImage: 'url(' + this.$store.state.api + bgSrc + ')'}">
    <div class="academyContainer clearfix" :style="{ flexDirection: flexDirection === 'left' ? 'row-reverse' : 'row' }">
      <div class="left">
        <!-- <img :src="require('@/' + imgSrc)" alt=""> -->
      </div>
      <div class="right" :style="{ color: color }">
        <div>
          <h3>{{ academyName }}</h3>
          <!-- <p>{{ academyIntro }}</p> -->
          <p>{{ academyContent }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'academy',
  props: {
    imgSrc: { // 图片地址
      type: String,
      default: 'assets/images/academyImages/academySmall1.png'
    },
    bgSrc: { // 背景图片地址
      type: String,
      default: 'assets/images/academyImages/academyBg1.jpg'
    },
    academyName: { // 合作院校名字
      type: String,
      default: '中国矿业大学'
    },
    academyIntro: { // 院校标语
      type: String,
      default: '开拓创新,严谨治学'
    },
    academyContent: { // 院校简介内容
      type: String,
      default: '中国矿业大学作为当今全国唯一以矿业命名的特色鲜明高水平大学，在煤炭能源的勘探、开发、利用，资源、环境和生产相关的矿建、安全、测绘、机械、信息技术、生态恢复、管理工程等领域形成了优势品牌和鲜明特色。'
    },
    color: { // 文字颜色
      type: String,
      default: '#fff'
    },
    flexDirection: { // 是否反转
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      autoHeight: this.$store.state.autoHeight ? this.$store.state.autoHeight + 'px' : '378.5px'
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    .academy .academyContainer {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    .academy .academyContainer {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 768px) {
    .academy .academyContainer {
      .left {
        display: none;
      }
      .right {
        width: 100%;
        h3 {
          font-size: 26px;
        }
        p:first-of-type {
          font-size: 16px;
        }
        p:last-of-type {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    .academy .academyContainer {
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
        h3 {
          font-size: 32px;
        }
        p:first-of-type {
          font-size: 26px;
        }
        p:last-of-type {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
  }
  .academy {
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .academyContainer {
      box-sizing: border-box;
      padding: 40px;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }
    .left {
      height: 100%;
      margin: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      position: relative;
    }
  }
</style>

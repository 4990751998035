import request from '@/utils/request'

// 查询课程订购列表
export function listOrdering (query) {
  return request({
    url: '/back/ordering/list',
    method: 'get',
    params: query
  })
}

// 查询课程订购详细
export function getOrdering (id) {
  return request({
    url: '/back/ordering/' + id,
    method: 'get'
  })
}

// 新增课程订购
export function addOrdering (data) {
  return request({
    url: '/back/ordering',
    method: 'post',
    data: data
  })
}

// 修改课程订购
export function updateOrdering (data) {
  return request({
    url: '/back/ordering',
    method: 'put',
    data: data
  })
}

// 删除课程订购
export function delOrdering (id) {
  return request({
    url: '/back/ordering/' + id,
    method: 'delete'
  })
}

// 导出课程订购
export function exportOrdering (query) {
  return request({
    url: '/back/ordering/export',
    method: 'get',
    params: query
  })
}

<template>
  <div id="advantage">
    <info title="我们的优势" fontColor="#fff" refAnimal="advantage" content1="我们提供的就是您想要的服务" />
    <div class="tabContainer">
      <ul class="tabs">
        <li class="animated" v-scroll-class="'fadeInUpBig'" v-for="(item, index) in advantageList">
          <div class="adv-item">
            <mis-icon :icon="iconList[index]" font-size="48px"></mis-icon>
            <p class="title">{{ item.title }}</p>
            <p class="content">{{ item.content }}</p>
          </div>
        </li>

<!--        <li class="animated" v-scroll-class="'fadeInUpBig'">
          <div class="scale">
            <advantageImg title="领域" content="楼宇控制行业，让产品走在楼控行业的最前沿" icon="el-icon-place" src="assets/images/我们的优势/雄厚师资力量.jpg" />
          </div>
        </li>
        <li class="animated" v-scroll-class="'fadeInUpBig'">
          <div class="scale">
            <advantageImg title="高质" content="高质量按时交付，采用尖端技术，提供全方面解决方案" icon="el-icon-medal-1" src="assets/images/我们的优势/专业课程标准.jpg" />
          </div>
        </li>
        <li class="animated" v-scroll-class="'fadeInUpBig'">
          <div class="scale">
            <advantageImg title="运营" content="按需提供运营支持，修复漏洞服务" icon="el-icon-data-line" src="assets/images/我们的优势/丰富行业经验.jpg" />
          </div>
        </li>
        <li class="animated" v-scroll-class="'fadeInUpBig'">
          <div class="scale">
            <advantageImg title="售后" content="秉承顾客至上理念，全方位跟进需求，专业解答您的所有疑惑" icon="el-icon-service" src="assets/images/我们的优势/丰富行业经验.jpg" />
          </div>
        </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
import advantageImg from '@/components/index/AdvantageImg'
import info from '@/components/index/Info'

import { list } from "@/api/index";

export default {
  components: { advantageImg, info },
  name: 'advantage',
  data () {
    return {
      activeName: 'first',
      advantageList: [],
      iconList: [
        'tuandui',
        'lingyu',
        'gaozhi',
        'yunying',
        'shouhou',
      ],
      imgList: [
        'assets/images/advantage/shouhou.jpg',
        'assets/images/advantage/biaozhun.jpg',
        'assets/images/advantage/jingyan.jpg',
        'assets/images/advantage/peixun.jpg',
        'assets/images/advantage/shizi.jpg',
      ]
    }
  },

  created() {
    this.getList()
  },

  methods: {
    getList() {
      list({modular: 2}).then(res => {
        console.log('我们的优势列表', res)
        this.advantageList = res.rows
      })
    }
  }
}
</script>

<style lang='less' scoped>
/* 媒体查询，当文档宽度大于1200px时 */
  @media screen and (min-width: 1200px) {
    .tabContainer {
      width: 1200px;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1200px) {
    .tabContainer {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    #advantage {
      background-repeat: repeat !important;
    }
    
    .tabs {
      justify-content: center !important;
      flex-wrap: wrap;
    }
    li {
      width: 40vw;
      // height: 40vh;

      .adv-item {
        width: 100% !important;
        padding: 26px 14px !important;
        
        .svg-icon {
          font-size: 38px;
        }
      }
    }
  }

  #advantage {
    margin-top: 20px;
    background: url("../../assets/images/bg-adv.png") 100% 100% no-repeat;
  }
  
  .tabContainer {
    
    margin: 0 auto;
    .tabs {
      min-height: 400px;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    li {
      margin: 5px;

      .scale {
        overflow: hidden;
        width: 100%;
        height: 100%;
        transition: transform .5s, box-shadow .5s;
        transform-origin: center center;
        &:hover {
          box-shadow: 0 0 10px rgb(255,255,255);
          transform: scale(1.05);
          z-index: 1;
        }
      }

      .adv-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 212px;
        padding: 56px 24px;
        box-sizing: border-box;
        transition: .5s;
        border: 1px solid #6d6b68;
        border-radius: 4px;
        background: rgba(255,255,255,.05);

        .title {
          font-size: 30px;
        }

        .content {
          width: 100%;
          text-align: center;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        &:hover {
          margin-top: -25px;
          background: rgba(255,255,255,.1);
        }
      }
    }
  }
</style>

<template>
  <div class="info" @click="goto(id)" ref="animate">
    <div class="left">
      <div>
        <div class="day">{{ month }}/{{ day }}</div>
        <div class="year">{{ year }}</div>
      </div>
    </div>
    <div class="right">
      <div class="imgBox">
        <img :src="img" alt="安评" v-handle-img="$store.state.api + src">
      </div>
      <div>
        <h5>{{ title }}</h5>
        <!-- <p>{{ content }}</p> -->
        <p>{{ summary }}</p>
        <span class="hidden">2021-03-26</span>
      </div>
    </div>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
export default {
  name: 'info',
  props: {
    id: {
      type: String
    },
    day: {
      type: String,
      default: '26'
    },
    month: {
      type: String,
      default: '03'
    },
    year: {
      type: String,
      default: '2021'
    },
    src: {
      type: String,
      default: 'assets/images/loading.gif'
    },
    title: {
      type: String,
      default: '为何企业都喜欢开发原生态APP软件？'
    },
    summary: {
      type: String,
      default: '手机APP软件开发一共有四种不同的模式Web网页加壳生成APP、Web网页+原生态混编、HTML5开发、原生态开发。但如果企业选择的话90%的都喜欢原生态APP开发模式。其实就开发开发难度而言，原生态开发相对比较困难，那么，为何还有这么多企业喜欢开发原生态APP软件呢?'
    },
    goto: {
      type: Function
    }
  },
  data () {
    return {
      img: imgUrl
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    .info {
      .left {
        display: none;
      }
      .right {
        width: 100%;
        > div:first-child {
          width: 15%;
        }
        > div:last-child {
          width: 82%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      .left {
        display: none;
      }
      .right {
        width: 100%;
        > div:first-child {
          width: 33%;
        }
        > div:last-child {
          width: 65%;
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .info {
      .left {
        display: flex;
        width: 10%;
      }
      .right {
        width: 90%;
        > div:first-child {
          width: 15%;
        }
        > div:last-child {
          width: 82%;
        }
        .hidden {
          display: none;
        }
      }
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 24px 0;
    height: 104px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    .left {
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-right: 10px;
      .day {
        font-size: 36px;
        line-height: 42px;
        color: #c3c3c3;
      }
      .year {
        margin-top: 5px;
        text-align: center;
        color: #cacaca;
        font-size: 18px;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      > div:first-child {
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          transition: transform .25s linear;
        }
      }
      h5 {
        margin: 5px 0 15px;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        color: #5b5b5b;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      p {
        height: 52px;
        line-height: 26px;
        font-size: 14px;
        margin-bottom: 6px;
        overflow: hidden;
        color: #909090;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      span {
        padding: 0;
        border: 0 none;
        font-size: 13px;
        line-height: 24px;
        color: #cacaca;
      }
    }
  }
  .info:hover img{
    transform: scale(1.2);
  }
  .info:hover h5{
    color: red;
  }
</style>

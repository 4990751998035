<template>
  <div class="navList" ref="navList" :style="{ height: dynamicHeight }">
    <p>{{ classifyName }}：</p>
    <ul> <!-- 内容列表 -->
      <li><label :class="{ isSelected: isAll }" @click="onClick()">全部</label></li>
      <!-- label上的@click.prevent="getList(item)" 阻止事件默认行为带来的两次触发（input + label） -->
      <li v-for="(item, index) in navList" :key="index"><label :class="{ isSelected: selectList.indexOf(item) >= 0 }"><input type="checkbox" :value="item" v-model="selectList">{{ item }}</label></li>
    </ul>
    <div v-if="isShowControl"> <!-- 是否显示展开收起功能按钮 -->
      <span v-show="isUnfold" @click="changeUnfold">收起
        <i class="el-icon-arrow-up"></i>
      </span> <!-- 展开 -->
      <span v-show="!isUnfold"  @click="changeUnfold">展开
        <i class="el-icon-arrow-down"></i>
      </span> <!-- 收起 -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'navList',
  props: {
    navList: { /* 传入列表 */
      type: Array,
      default: function () {
        return []
      }
    },
    classifyName: { /* 第一个类型名成 */
      type: String,
      default: '类型'
    }
  },
  data () {
    return {
      selectList: [...this.navList], /* 已选择列表 */
      isAll: false, /* 是否全部选择 */
      isUnfold: false, /* 是否展开 */
      dynamicHeight: '40px', /* 动态高度 */
      recordHeight: '', /* 组件挂载成功后记录获取的总高度 */
      isShowControl: false /* 是否显示控制功能按钮 */
    }
  },
  methods: {
    onClick () { /* 点击 */
      if (this.selectList.length === 0) {
        this.selectList = this.navList /* 全选 */
        this.isAll = true
      } else {
        this.selectList = [] /* 全不选 */
        this.isAll = false
      }
    },
    changeUnfold () {
      this.isUnfold = !this.isUnfold /* 是否展开取反 */
      if (this.isUnfold) {
        this.dynamicHeight = this.recordHeight /* 展开时将总高度赋予动态高度 */
      } else {
        this.dynamicHeight = '40px' /* 收起时初始化动态高度 */
      }
    }
  },
  watch: {
    selectList: {
      handler (newVal, oldVal) {
        if (this.selectList.length === this.navList.length) {
          this.isAll = true
        } else {
          this.isAll = false
        }
        this.$emit('selectList', newVal)
      },
      deep: true
    }
  },
  mounted () {
    if (this.$refs.navList.scrollHeight > 79) { /* 获取组件总高度 */
      this.isShowControl = true /* 显示功能按钮 */
    }
    this.recordHeight = this.$refs.navList.scrollHeight + 'px' /* 纪录总高度 */
    // 一开始默认全选
    this.$emit('selectList', this.selectList)
    this.isAll = true
  }
}
</script>

<style lang='less' scoped>
  .navList {
    display: flex;
    justify-content: flex-start;
    height: 40px;
    overflow: hidden;
    position: relative;
    transition: height 0.2s linear;
    ul, li {
      margin: 0;
      padding: 0;
    }
    p {
      width: 50px;
      line-height: 40px;
      height: 40px;
      margin: 0;
      overflow: hidden;
      flex: 0 0 60px;
    }
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex: 1;
      margin-right: 60px;
      li {
        margin-right: 10px;
        line-height: 40px;
      }
      label {
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 7px;
        font-size: 13px;
      }
      .isSelected {
        background-color: rgb(89, 110, 251);
        color: white;
      }
      input {
        // visibility: hidden;
        display: none;
      }
    }
    > div {
      position: absolute;
      right: 0;
      cursor: pointer;
      line-height: 40px;
    }
  }
</style>

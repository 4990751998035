import request from '@/utils/request'

// 查询首页培训风采列表
export function listTrain (query) {
  return request({
    url: '/back/train/list',
    method: 'get',
    params: query
  })
}

// 查询首页培训风采详细
export function getTrain (id) {
  return request({
    url: '/back/train/' + id,
    method: 'get'
  })
}

// 新增首页培训风采
export function addTrain (data) {
  return request({
    url: '/back/train',
    method: 'post',
    data: data
  })
}

// 修改首页培训风采
export function updateTrain (data) {
  return request({
    url: '/back/train',
    method: 'put',
    data: data
  })
}

// 删除首页培训风采
export function delTrain (id) {
  return request({
    url: '/back/train/' + id,
    method: 'delete'
  })
}

// 导出首页培训风采
export function exportTrain (query) {
  return request({
    url: '/back/train/export',
    method: 'get',
    params: query
  })
}

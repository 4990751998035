<template>
  <div class="dataList">
    <datas v-for="(item, index) in dataList" :key="index"
    :data="item" />
  </div>
</template>

<script>
import datas from './Data'
export default {
  name: 'dataList',
  components: {
    datas
  },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang='less' scoped>
  .dataList {}
</style>

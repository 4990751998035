<template>
  <div id="college">
    <div class="container">
      <div class="controlSearch">
        <div>
          <el-input placeholder="搜索培训会名称" v-model="search" class="input-with-select"
          clearable @keydown.enter.native="onSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
          </el-input>
        </div>
      </div>
      <div v-if="isLoading" style="text-align: center;">正在加载中...</div>
      <train-meet-list v-else :courses="tableData" />
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { listIntroduction } from '@/api/college'
import trainMeetList from '@/common/components/TrainMeetList'
export default {
  name: 'college',
  components: {
    trainMeetList
  },
  data () {
    return {
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      tableData: [],
      activeName: 'first',
      isLoading: true
    }
  },
  methods: {
    async loadList () {
      try {
        const res = await listIntroduction({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          title: this.search
        })
        if (res.code === 200) {
          this.total = res.total
          this.tableData = res.rows
          this.isLoading = false
        }
      } catch (error) {
        this.$message.error('未知错误，请重新尝试')
      }
    },
    onSearch () {
      this.pageNum = 10
      this.pageNum = 1
      this.loadList()
    },
    // 分页每页数量发生改变
    handleSizeChange (val) {
      this.pageSize = val
      this.loadList()
    },
    // 分页当前页码发生改变
    handleCurrentChange (val) {
      this.pageNum = val
      this.loadList()
    }
  },
  watch: {
    activeName () { // 判断是否一键展开或者收起
      this.unfold = !this.unfold
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    this.loadList()
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #college .container {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #college .container {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 768px) {
    #college {

    }
  }
  #college {
    .controlSearch {
      display: flex;
      justify-content: flex-end;
      margin: 5px;
    }
  }
</style>

import request from '@/utils/request'

// 查询行业资讯列表
export function listConsulting (query) {
  return request({
    url: '/back/consulting/list',
    method: 'get',
    params: query
  })
}

// 查询行业资讯详细
export function getConsulting (id) {
  return request({
    url: '/back/consulting/' + id,
    method: 'get'
  })
}

// 新增行业资讯
export function addConsulting (data) {
  return request({
    url: '/back/consulting',
    method: 'post',
    data: data
  })
}

// 修改行业资讯
export function updateConsulting (data) {
  return request({
    url: '/back/consulting',
    method: 'put',
    data: data
  })
}

// 修改行业资讯浏览次数
export function updateConsultingVisit (id) {
  return request({
    url: '/back/consulting/visitUp/' + id,
    method: 'get'
  })
}

// 删除行业资讯
export function delConsulting (id) {
  return request({
    url: '/back/consulting/' + id,
    method: 'delete'
  })
}

// 导出行业资讯
export function exportConsulting (query) {
  return request({
    url: '/back/consulting/export',
    method: 'get',
    params: query
  })
}

<template>
  <div id="tabbarContainer">
    <div class="topbar">
      <div class="logo">
        <h1>
          <a href="">
            <img v-show="$route.path.split('/')[1] === ''" src="@/assets/images/logo/pufeite.png" alt="" title="">
            <img v-show="$route.path.split('/')[1] !== ''" src="@/assets/images/logo/pufeite1.png" alt="" title="">
            <span class="companyName1" v-show="$route.path.split('/')[1] === ''">徐州普飞特电子科技有限公司</span>
            <span class="companyName2" v-show="$route.path.split('/')[1] !== ''">徐州普飞特电子科技有限公司</span>
          </a>
        </h1>
      </div>

      <div style="display: flex;">
        <div class="menu hidden-xs-only">
          <div class="pcMenu">
<!--            <a href="http://www.apyjpx.com" :style="{ color: aColor }">首页</a>-->
            <router-link :to="{ name: 'index' }"><span :style="{ color: aColor }">首页</span></router-link>
            <router-link :to="{ name: 'companyIntroduct' }"><span :style="{ color: aColor }">关于我们</span></router-link>
            <router-link :to="{ name: 'kzqCmp' }"><span :style="{ color: aColor }">产品展示</span></router-link>
            <router-link :to="{ name: 'hrzxt' }"><span :style="{ color: aColor }">解决方案</span></router-link>
            <router-link :to="{ name: 'ktj' }"><span :style="{ color: aColor }">工程业绩</span></router-link>
            <router-link :to="{ name: 'downloadCenter' }"><span :style="{ color: aColor }">下载中心</span></router-link>
          </div>
        </div>

        <div class="control">
          <div class="tel">
            <span>0516-83891162</span>
          </div>
          <div class="right hidden-sm-and-up">
            <el-dropdown class="menuDrop" trigger="click">
              <i class="el-icon-menu"></i>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-s-home">
                  <router-link :to="{ name: 'index' }">首页</router-link>
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-s-custom">
                  <router-link :to="{ name: 'companyIntroduct' }">关于我们</router-link>
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-s-shop">
                  <router-link :to="{ name: 'kzqCmp' }">产品展示</router-link>
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-s-claim">
                  <router-link :to="{ name: 'hrzxt' }">解决方案</router-link>
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-s-marketing">
                  <router-link :to="{ name: 'ktj' }">工程业绩</router-link>
                </el-dropdown-item>
                <el-dropdown-item icon="el-icon-s-grid">
                  <router-link :to="{ name: 'downloadCenter' }">下载中心</router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from '@/api/user'
import bus from '@/utils/eventBus'
import imgUrl from '@/assets/images/touxiang.jpg'
export default {
  name: 'topbar',
  components: {
    // loginCompo,
    // registerCompo
  },
  data () {
    return {
      loginDialogVisible: false,
      registerDialogVisible: false,
      token: '',
      user: null,
      avatar: '',
      loginType: 0, // 未登录0 wx 1 mobile 2
      img: imgUrl
    }
  },
  computed: {
    aColor () { // 判断在不同路由和xs及其他大小下的颜色
      if (this.$route.path === '/') {
        return this.$store.state.isXs ? '#000' : '#000000'
      } else {
        return '#000'
      }
    }
  },

  created () {
    bus.$on('avatar', imgUrl => {
      this.avatar = imgUrl
    })
  },

  methods: {
    closeDialog () {
      this.loginDialogVisible = false // // 隐藏dialog对话框
      this.registerDialogVisible = false // // 隐藏dialog对话框
    },
  }
}
</script>

<style lang='less' scoped>
  @media screen and (min-width: 1600px) {
    #tabbarContainer {
      width: 1600px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 1200px) {
    #tabbarContainer {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1200px) {
    #tabbarContainer {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .logo {
      // transform: scale(0.8) translateX(-20px);
      img {
        height: 25px !important;
        margin-left: 10px;
      }

      .companyName1 {
          line-height: 24px;
          color: #f03235;
          font-size: 14px !important;
        }

        .companyName2 {
          line-height: 24px;
          color: #148897;
          font-size: 14px !important;
        }
    }
    .topbar {
      padding: 0 10px;
    }
    .tel {
      display: none !important;
    }
  }
  @media screen and (min-width: 1500px) {
    .pcMenu {
      a {
        margin: 0 15px !important;
      }
    }
  }
  @media screen and (min-width: 1000px) {
    .pcMenu {
      a {
        margin: 0 15px !important;
      }
    }
  }
  .topbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 75px;
    margin: 0;
    line-height: 75px;
    .logo {
      text-align: center;
      h1 {
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
      a {
        display: flex; /*主要是这个属性*/
        align-items: center;

        .companyName1 {
          line-height: 18px;
          color: #f03235;
          font-size: 24px;
        }

        .companyName2 {
          line-height: 18px;
          color: #148897;
          font-size: 24px;
        }
      }
      img {
        height: 50px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .menu {
      .pcMenu {
        display: flex;
        justify-content: flex-end;
        a {
          margin: 0 15px;
          color: white;
        }
        a:hover {
          color: red;
        }
      }
    }
    .control {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      margin-left: 10px;
      div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .tel {
        span {
          line-height: 40px;
          padding: 0 10px;
          border-radius: 20px;
          background-color: rgb(13,111,184);
          color: #fff;
          font-size: 12px;
        }
      }
      .left {
        width: 85px;
        margin-right: 5px;
      }
      .line {
        width: 1px;
        height: 20px;
        margin: 0 10px;
      }
    }
    .menuDrop {
      font-size: 35px;
      z-index: 10000;
    }
    .pcMenu a.router-link-exact-active {
      position: relative;
    }
    .pcMenu a.router-link-exact-active::after {
      position: absolute;
      content: '';
      display: block;
      height: 5px;
      bottom: 20px;
      left: 0;
      right: 0;
      border-radius: 3px;
      background-color: rgb(13,111,184);
      z-index: 10;
    }
    .user-avatar {
      width: 45px;
      height: 45px;
      margin-right: 5px;
    }
  }
</style>

<template>
  <div class="mobile-foot">
    <div class="btnItem" @click="$router.replace({name: 'index'})">
      <mis-icon icon="home" font-size="24px"></mis-icon>
      <p>返回首页</p>
    </div>

    <div class="btnItem" @click="$router.replace({name: 'contact'})">
      <mis-icon icon="link" font-size="24px"></mis-icon>
      <p>联系我们</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footTips'
}
</script>

<style lang="less" scoped>
  .mobile-foot {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f5f7fa;
    z-index: 10000;

    .btnItem {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      padding: 5px 0;

      p{
        margin: 5px 0;
      }

      &:nth-child(2) {
        background: #66b1ff;
        color: #fff;
      }
    }
  }
</style>

<template>
  <div id="trainShow">
    <info
      v-if="carouselList.length > 0"
      refAnimal="trainShow"
      :title="carouselList[0].title"
      :content1="carouselList[0].introduce.split('/')[0]"
      :content2="carouselList[0].introduce.split('/')[1]"
    />
      <!-- content1="我们的足迹遍布各行业"
      content2="希望与您共同实践，探讨行业技术，开启精彩的工业新时代" -->
    <ul>
      <li v-for="(item, index) in carouselList" :key="index" class="animated" v-scroll-class="'fadeInUpBig'" >
        <!-- <img :src="img" :alt="item.title" v-handle-img="$store.state.api + item.img"> -->
        <el-image
        class="animated"
        v-add-class="'tada'"
        style="width: 100px; height: 100px"
        :src="$store.state.api + item.img"
        :preview-src-list="srcList">
        </el-image>
      </li>
    </ul>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
import info from '@/components/index/Info'
import { listTrain } from '@/api/trainshow'
export default {
  name: 'trainShow',
  components: { info },
  data () {
    return {
      img: imgUrl,
      url: '',
      srcList: [],
      carouselList: [],
      pageNum: 1, // 分页当前页码数
      pageSize: 9, // 分页数量
      search: '' // 搜索内容
    }
  },
  methods: {
    // 获取培训相关图片
    async loadCarouselList () {
      try {
        const res = await listTrain({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          name: this.search
        })
        if (res.code === 200) {
          this.carouselList = res.rows
          this.url = res.rows[0].img
          res.rows.forEach((item) => {
            this.srcList.push(process.env.VUE_APP_BASE_API + item.img)
          })
        }
      } catch (error) {
        this.$message.error('未知错误，请重新尝试')
      }
    }
  },
  created () {
    this.loadCarouselList()
  }
}
</script>

<style lang="less" scoped>
/* 媒体查询，当文档宽度大于1200px时 */
@media screen and (min-width: 1200px) {
  #trainShow {
    width: 1200px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1200px) {
  #trainShow {
    width: 100%;
  }
}
#trainShow {
  overflow: hidden;
  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li {
      overflow: hidden;
      width: 33.15%;
      div {
        width: 100% !important;
        height: 100% !important;
      }
      &:hover /deep/ .el-image__preview {
        transform: scale(1.05);
      }
      .el-image /deep/ .el-image__preview {
        cursor: -moz-zoom-in;
        cursor: -webkit-zoom-in;
        cursor: zoom-in;
        transition: transform 0.2s linear;
      }
    }
  }
}
</style>

<template>
  <!-- 顶部面包屑菜单 -->
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item, index) in breadcrumb" :key="index" :to="{ path: item.path }">{{ item.name }}</el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$route.query.module === 'companyNews'" :to="{ name: 'newsList' }">公司动态</el-breadcrumb-item>
      <el-breadcrumb-item v-else-if="this.$route.query.module === 'industryInfo'" :to="{ name: 'industryInfo' }">行业资讯</el-breadcrumb-item>
      <el-breadcrumb-item v-if="title">{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: 'breadcrumb',
  props: {
    title: { // 面包屑最后一个标题
      type: String
    }
  },
  data () {
    return {
      breadcrumb: this.$route.meta.breadcrumb
    }
  }
}
</script>

<style lang='less' scoped>
  .breadcrumb {
    overflow: hidden;
  }
  .el-breadcrumb {
    color: rgb(140,113,81);
    margin: 10px 0;
    overflow: hidden;
    line-height: 19px;
  }
</style>

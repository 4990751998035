<template>
  <div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'wxRedirect',
  created () {
    const loading = this.$loading({
      lock: true,
      text: '正在登陆中，即将跳转到会员专区',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    const code = this.$route.query.code
    const state = this.$route.query.state
    // eslint-disable-next-line
    axios.get(`http://www.apyjpx.com:89/wx/openWxCallBack?code=` + code + '&state=' + state).then(res => {
      localStorage.setItem('wxUserInfo', JSON.stringify(res.data.data))
      this.$router.push({
        name: 'vip'
      })
      loading.close()
    })
  }
}
</script>

<style lang='less' scoped>

</style>

<template>
  <div id="courseDeals">
    <!-- 顶部面包屑菜单 -->
    <div class="fullScreen">
      <top-shop
      :src="course.introduceimg"
      :title="course.name"
      :id="course.id"
      :cost="course.cost"
      :buyPeople="course.ordernum"
      :visitedNumber="course.visit"
      :isCourse="true"
      :isAllowd="isPay"
      :onSubmit="onSubmit" />
    </div>
    <!-- 底部综合功能区 -->
    <div class="center">
      <bottom-synthesize
      :tabs="tabs">
      <div slot="first">
        <div class="first" v-html="course.introducecontent"></div>
      </div>
      <!-- <div slot="second">
        <div class="second">
          <lecturer />
        </div>
      </div>
      <div slot="three">
        <div class="three">
          <p>这是购买指南</p>
        </div>
      </div>
      <div slot="four">
        <div class="four">
          <div>
            <span>本课程评分：</span>
            <el-rate
              v-model="score"
              :colors="colors">
            </el-rate>
          </div>
          <div>
            <textarea id="" cols="30" rows="10" placeholder="在这里输入您的评价内容哦，亲！"></textarea>
            <el-button type="primary" @click="submitEvaluate">提交评价</el-button>
          </div>
        </div> -->
      <!-- </div> -->
      </bottom-synthesize>
        <el-dialog
        title="微信支付"
        destroy-on-close
        :visible.sync="dialogVisible"
        :width="$store.state.isXs ? '95%' : '500px'"
        :show-close="true"
        :modal-append-to-body="true"
        :append-to-body="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false">
        <div style="text-align: center;">
          <img :src="imgUrl" alt="安评课程微信支付">
        </div>
        </el-dialog>
    </div>
    <button style="display: none;" ref="btn"></button>
  </div>
</template>

<script>
import { addOrdering } from '@/api/courseOrder'
import { listDetail } from '@/api/orderDetail'
import TopShop from '@/common/components/TopShop'
import bottomSynthesize from '@/common/components/BottomSynthesize'
import { getCurriculum } from '@/api/course'
import { getWxCode, getZfbCode } from '@/api/order'
export default {
  name: 'courseDeals',
  components: { TopShop, bottomSynthesize },
  data () {
    return {
      // tabs: ['课程介绍', '讲师介绍', '购买指南', '评价'],
      tabs: ['课程相关'],
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      score: null,
      course: {
        introducecontent: null
      },
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      imgUrl: undefined,
      dialogVisible: false,
      isPay: false
    }
  },
  methods: {
    // 获取微信二维码
    async loadWxCode () {
      console.log(typeof this.$store.state.user.userInfo.userId)
      const res = await getWxCode({
        spId: this.$store.state.user.userInfo.userId,
        orderNo: this.$store.state.user.userInfo.userId.substring(10, 18) + this.course.id.substring(10, 18) + (+new Date()),
        courseId: this.course.id,
        spName: this.$store.state.user.userInfo.userName,
        orderTime: new Date(),
        payTime: new Date(),
        orderMoney: this.course.cost + '',
        totalMoney: this.course.cost + '',
        subject: this.course.name,
        note: this.course.name + '=>' + (this.course.cost / 100) + '元'
      })
      this.imgUrl = 'data:image/gif;base64,' + res.msg
      this.dialogVisible = true
    },
    // 获取支付宝二维码
    async loadZfbCode () {
      console.log(this.$store.state.user.userInfo)
      const res = await getZfbCode({
        spId: this.$store.state.user.userInfo.userId,
        orderNo: this.$store.state.user.userInfo.userId.substring(10, 18) + this.course.id.substring(10, 18) + (+new Date()),
        courseId: this.course.id,
        spName: this.$store.state.user.userInfo.userName,
        orderTime: new Date(),
        payTime: new Date(),
        orderMoney: this.course.cost / 100 + '',
        totalMoney: this.course.cost / 100 + '',
        subject: this.course.name,
        note: this.course.name + '=>' + (this.course.cost / 100) + '元'
      })
      const alipayHtml = window.open('', '_blank')
      alipayHtml.document.write(res)
      // this.$refs.btn.click(() => {
      //   const alipayHtml = window.open('', '_blank')
      //   alipayHtml.document.write(res)
      // })
    },
    // 获取视频详情
    async loadVideo (id) {
      try {
        const res = await getCurriculum(id)
        if (res.code === 200) {
          if (res.data) {
            this.course = res.data
            this.loadOrderDetail()
          } else {
            const loading = this.$loading({
              lock: true,
              text: '此课程不存在，即将返回会员专区',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.9)'
            })
            setTimeout(() => {
              this.$router.push({
                name: 'vip'
              })
              loading.close()
            }, 2000)
          }
        } else {
          this.$message.error('请求错误，请稍后再试')
        }
      } catch (err) {
        // const loading = this.$loading({
        //   lock: true,
        //   text: '异常错误，即将返回会员专区',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.9)'
        // })
        // setTimeout(() => {
        //   this.$router.push({
        //     name: 'vip'
        //   })
        //   loading.close()
        // }, 2000)
      }
    },
    // 购买功能
    onSubmit () {
      if (this.$store.state.isLoginType === 1 || this.$store.state.isLoginType === 2) {
        if (this.isPay) {
          this.$router.push({
            name: 'videoShow',
            query: {
              id: this.$route.query.id
            }
          })
        } else {
          this.$confirm('请选择支付方式', '支付方式', {
            distinguishCancelAndClose: true,
            confirmButtonText: '微信支付',
            cancelButtonText: '支付宝支付',
            lockScroll: true,
            type: 'success'
          })
            .then(() => {
              this.loadWxCode()
            })
            .catch(action => { // action: cancel close
              if (action === 'cancel') {
                this.loadZfbCode()
              } else {
                this.$message({
                  type: 'info',
                  message: '已取消支付'
                })
              }
            })
        }
      } else {
        const loading = this.$loading({
          lock: true,
          text: '您还未登录，正在前往登录',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'login'
          })
          loading.close()
        }, 2000)
      }
    },
    // 获取订单
    async loadOrderDetail () {
      try {
        const res = await listDetail({
          courseId: this.$route.query.id,
          spId: this.$store.state.user.userInfo.userId
        })
        console.log(res)
        if (res.code === 200 && res.total >= 1 && res.rows[0].orderStatus === 'SUCCESS') {
          this.isPay = true
        } else {
          this.isPay = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 上传订单
    async loadUploadOrder () {
      try {
        const res = await addOrdering({
          culumId: this.$route.query.id,
          userId: this.$store.state.user.userInfo.userId,
          ordertime: new Date(),
          state: '已完成'
        })
        console.log(res)
      } catch (err) {
        console.log(err)
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    if (this.$store.state.isLoginType) {
      this.loadOrderDetail()
    }
    const id = this.$route.query.id
    if (id) {
      this.loadVideo(id)
    } else {
      const loading = this.$loading({
        lock: true,
        text: '此课程不存在即将返回会员专区',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.9)'
      })
      setTimeout(() => {
        this.$router.push({
          name: 'vip'
        })
        loading.close()
      }, 2000)
    }

    if (this.$store.state.isLoginType && this.$route.query.out_trade_no && this.$route.query.total_amount) {
      this.$message.success('购买成功')
      this.isPay = true
      this.loadUploadOrder()
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #courseDeals .center {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #courseDeals .center {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 768px) {
    #courseDeals {

    }
  }
  #courseDeals {
    .fullScreen{
      background-color: rgb(243,243,243);
      padding-bottom: 10px;
    }
    .second {
      width: 300px;
      margin: 0 auto;
    }
    .four {
      width: 100%;
      > div:first-child {
        display: flex;
        margin: 0 auto;
        justify-content: flex-start;
      }
      > div:last-child {
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 10px;
      }
      textarea {
        outline: 1px solid rgb(65,168,247);
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
</style>

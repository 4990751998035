<template>
  <div class="dataContainer">
    <div class="data-content">
      <div></div>
      <router-link :to="{ name: 'dataView', query: {
        id: data.url
      } }">
        <h4>{{ data.title }}</h4>
      </router-link>
    </div>
    <ul class="data-control">
      <li>
        <i class="el-icon-download"></i>
        {{ data.size }}(MB)
      </li>
      <li>
        <i class="el-icon-time"></i>
        {{ data.uptime }}
      </li>
      <li>
        <router-link :to="{ name: 'dataView', query: {
          id: data.url
        } }">
          <i class="el-icon-view"></i>
          查看
        </router-link>
      </li>
      <!-- <li>
        <a :href="$store.state.api + data.url">
          <i class="el-icon-download"></i>
          下载
        </a>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'dataContainer',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang='less' scoped>
  .dataContainer {
    border-bottom: 1px solid #eee7e7;
    padding: 10px 0;
    .data-content {
      display: flex;
      justify-content: flex-start;
      div {
        width: 23px;
        height: 29px;
        background-image: url("~@/assets/images/tbA.png");
        // background-position: 177px 83px;
        background-position: -31px -43px;
      }
      h4 {
        height: 40px;
        line-height: 40px;
        margin: 0;
        padding: 0;
        margin-left: 4px;
        font-size: 16px;
        color: #6e6e6ec9;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .data-control {
      display: flex;
      justify-content: flex-start;
      line-height: 20px;
      li {
        font-size: 13px;
        margin-right: 10px;
        color: #7c7c7cd8;
      }
      a {
        color: #7c7c7cd8;
      }
    }
  }
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
// 布局
import layout from '@/views/layout/Layout'
// 首页
import index from '@/views/index/Index'
// 关于我们 相关
import aboutIndex from '@/views/about'
import companyIntroduct from '@/views/about/CompanyIntroduct'
import contact from '@/views/about/Contact'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

// 关于我们
const aboutRoute = {
  path: '/about', // 关于我们页面
  component: aboutIndex,
  children: [
    {
      path: '',
      name: 'companyIntroduct',
      component: companyIntroduct,
      meta: {
        isKeepAlive: true,
        isBanner: false
      }
    },
    {
      path: 'contact',
      name: 'contact',
      component: contact,
      meta: {
        isKeepAlive: true,
        isBanner: false
      }
    }
  ]
}

// 产品展示
const productDisplay = {
  path: '/productDisplay',
  name: 'productDisplay',
  component: () => import('@/views/productDisplay'),
  children: [
    {
      path: '',
      name: 'kzqCmp',
      component: () => import('@/views/productDisplay/kzqCmp'),
      meta: {
        isKeepAlive: true,
        isBanner: false
      }
    },
    {
      path: 'bsqCgq',
      name: 'bsqCgq',
      component: () => import('@/views/productDisplay/bsqCgq'),
      meta: {
        isKeepAlive: true,
        isBanner: true
      }
    },
    {
      path: 'kg',
      name: 'kg',
      component: () => import('@/views/productDisplay/kg'),
      meta: {
        isKeepAlive: true,
        isBanner: true
      }
    },
    {
      path: 'fmQdq',
      name: 'fmQdq',
      component: () => import('@/views/productDisplay/fmQdq'),
      meta: {
        isKeepAlive: true,
        isBanner: true
      }
    },
    {
      path: 'ztwg',
      name: 'ztwg',
      component: () => import('@/views/productDisplay/ztww'),
      meta: {
        isKeepAlive: true,
        isBanner: true
      }
    }
  ]
}

const productDetail = {
  path: '/productDetail',
  name: 'productDetail',
  component: () => import('@/views/productDisplay/productDetail'),
  meta: {
    isKeepAlive: false,
    isBanner: false
  }
}

// 解决方案
const solution = {
  path: '/solution',
  name: 'solution',
  component: () => import('@/views/solution'),
  children: [
    {
      path: '',
      name: 'hrzxt',
      component: () => import('@/views/solution/hrzxt'),
      meta: {
        isKeepAlive: true,
        isBanner: false
      }
    }
  ]
}

const solutionDetail = {
  path: '/solutionDetail',
  name: 'solutionDetail',
  component: () => import('@/views/solution/solutionDetail'),
  meta: {
    isKeepAlive: false,
    isBanner: false
  }
}

// 工程业绩
const projectScore = {
  path: '/projectScore',
  component: () => import('@/views/projectScore'),
  children: [
    {
      path: '',
      name: 'ktj',
      component: () => import('@/views/projectScore/ktj'),
      meta: {
        isKeepAlive: true,
        isBanner: false
      }
    },
    {
      path: 'xfj',
      name: 'xfj',
      component: () => import('@/views/projectScore/xfj'),
      meta: {
        isKeepAlive: true,
        isBanner: true
      }
    },
    {
      path: 'gpsxt',
      name: 'gpsxt',
      component: () => import('@/views/projectScore/gpsxt'),
      meta: {
        isKeepAlive: true,
        isBanner: true
      }
    },
    {
      path: 'lryJhz',
      name: 'lryJhz',
      component: () => import('@/views/projectScore/lryJhz'),
      meta: {
        isKeepAlive: true,
        isBanner: true
      }
    }
  ]
}

const projectDetail = {
  path: '/projectDetail',
  name: 'projectDetail',
  component: () => import('@/views/projectScore/projectDetail'),
  meta: {
    isKeepAlive: false,
    isBanner: false
  }
}

// 下载中心
const downloadCenter = {
  path: '/downloadCenter',
  name: 'downloadCenter',
  component: () => import('@/views/download'),
  meta: {
    isKeepAlive: true,
    isBanner: false
  }
}
// 下载中心
const downloadDetail = {
  path: '/downloadDetail',
  name: 'downloadDetail',
  component: () => import('@/views/download/downloadDetail'),
  meta: {
    isKeepAlive: false,
    isBanner: false
  }
}

const routes = [
  {
    path: '',
    meta: {
      isKeepAlive: false
    },
    component: layout,
    children: [
      {
        path: '', // 首页
        name: 'index',
        component: index,
        meta: {
          isKeepAlive: true,
          isBanner: true,
          isFullScreen: true
        }
      },
      aboutRoute,
      productDisplay,
      productDetail,
      solution,
      solutionDetail,
      projectScore,
      projectDetail,
      downloadCenter,
      downloadDetail
    ]
  },
  {
    path: '*', // 将不存在的url重定向到首页
    redirect: '/'
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 全局前置导航卫士
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

// 全局后置导航卫士
router.afterEach(() => {
  NProgress.done()
})

const VueRouterPush = VueRouter.prototype.push // 解决重复点击路由跳转报错问题
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router

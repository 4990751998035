import request from '@/utils/request'

// 发送验证码
export function loadSms (data) {
  return request({
    url: '/sms/sendSms',
    method: 'post',
    data
  })
}

// 短信登录
export function loginSms (data) {
  return request({
    url: '/sms/code',
    method: 'post',
    data
  })
}

// 验证短信验证码是否正确
export function checkCode (query) {
  return request({
    url: '/sms/checkCode',
    method: 'get',
    params: query
  })
}

<template>
  <div class="trainMeetList">
    <el-card>
      <el-collapse v-model="activeNames">
        <el-collapse-item v-for="(item, index) in courses" :key="index" :name="index">
          <!-- <template slot="title">
            <p>{{ item.theme }}</p>
          </template> -->
          <train-meet
          :id="item.id"
          :src="item.img"
          :title="item.title"
          :starttime="item.starttime"
          :endtime="item.endtime"
          :address="item.adress"
          :content="item.introduce" />
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
import trainMeet from '@/components/college/TrainMeet'
export default {
  name: 'trainMeetList',
  components: {
    trainMeet
  },
  props: {
    unfold: {
      type: Boolean,
      default: true
    },
    // src: {
    //   type: String,
    //   default: 'assets/images/intro.jpg'
    // },
    // title: {
    //   type: String,
    //   default: '2020矿井设备检测技术研讨会'
    // },
    // date: {
    //   type: String,
    //   default: '5月27日-5月29日'
    // },
    // address: {
    //   type: String,
    //   default: '江苏徐州'
    // },
    courses: {
      type: Array,
      default: () => {
        return []
      }
    },
    content: {
      type: String,
      default: '：本次研讨会主讲课程为“煤矿检测四大件“，包括通风机、提升机、空压机、主排水泵检测的基本知识和测试方法，通风阻力原理和测试方法等专业知识。'
    }
  },
  data () {
    return {
      activeNames: [0, 1, 2, 3]
      // titles: [
      //   '2021年第一期矿井三维通风仿真系统软件与通风优化管理技术专题培训班的通知（报名送软件）',
      //   '2020年第五期金属非金属矿井三维通风仿真系统软件与建模技术专题培训班的通知（报名送软件）',
      //   '2020年第三期煤矿三维通风仿真系统软件与建模技术专题培训班的通知（报名送软件）',
      //   '2020年第三期金属非金属矿井三维通风仿真系统软件与建模技术专题培训班的通知（报名送软件）'
      // ]
    }
  },
  computed: {
    recordActiveNames () { // 根据会议数据的数量动态生成recordActiveNames
      const num = this.titles.length
      const arr = []
      for (let i = 0; i < num; i++) {
        arr.push(i)
      }
      return arr
    }
  },
  watch: {
    unfold (val, oldVal) {
      if (val) {
        this.activeNames = JSON.parse(JSON.stringify(this.recordActiveNames))
      } else this.activeNames = []
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 767px) {
    .el-card {
      margin: 10px;
    }
    /deep/ .el-collapse-item__header {
      font-size: 16px;
    }
    // /deep/ .el-collapse-item__content {
    //   > div:first-child {
    //     width: 100%;
    //   }
    //   > div:last-child {
    //     width: 100%;
    //   }
    //   img {
    //     height: 200px;
    //   }
    // }
  }
  @media screen and (min-width: 768px) {
    /deep/ .el-collapse-item__header {
      font-size: 18px;
    }
    // /deep/ .el-collapse-item__content {
    //   display: flex;
    //   justify-content: flex-start;
    //   > div:first-child {
    //     width: 30%;
    //   }
    //   > div:last-child {
    //     width: 65%;
    //   }
    //   img {
    //     height: 100%;
    //   }
    // }
  }
  .trainMeetList {
    /deep/ .el-collapse-item {
      .el-collapse-item__header {
        color: red;
        transition: color 0.5s linear;
        // display: flex;
        display: none;
        justify-content: space-between;
        p {
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .el-collapse-item__content {
        margin-top: 25px;
      //   img {
      //     width: 100%;
      //   }
      //   > div:last-child {
      //     margin-left: auto;
      //     font-size: 14px;
      //     p {
      //       font-size: 18px;
      //       font-weight: 600;
      //       margin: 8px 0;
      //     }
      //     span {
      //       color: #999;
      //     }
      //   }
      }
      .is-active {
        color: green;
      }
    }
  }
</style>

<template>
  <div id="learnWorld">
    <div class="main">
      <h5>以便捷的方式开始学习</h5>
      <ul>
        <li>
          <card
          :goto="gotoDataCenter" />
        </li>
        <li>
          <card
          :goto="gotoIndustryInfo"
          title="行业资讯"
          content="行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯行业资讯" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import card from '@/components/learnWorld/Card'
export default {
  name: 'learnWorld',
  components: {
    card
  },
  methods: {
    gotoDataCenter () {
      this.$router.push({ name: 'dataCenter' })
    },
    gotoIndustryInfo () {
      this.$router.push({ name: 'industryInfo' })
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0 // 每次路由跳转跳到顶部（非缓存情况下）
    })
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #learnWorld .main {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #learnWorld .main {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 768px) {
    #learnWorld {

    }
  }
  #learnWorld {
    // padding-bottom: 100px;
    h5 {
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      margin: 25px auto 80px auto;
      position: relative;
    }
    h5::after {
      position: absolute;
      content: '';
      display: block;
      bottom: -10px;
      width: 100px;
      height: 5px;
      background-color: rgb(23, 221, 235);
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 10px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        margin: 20px auto;
      }
    }
  }
</style>

import request from '@/utils/request'

// 查询首页轮播图列表
export function listBan (query) {
  return request({
    url: '/back/ban/list',
    method: 'get',
    params: query
  })
}

// 查询首页轮播图详细
export function getBan (id) {
  return request({
    url: '/back/ban/' + id,
    method: 'get'
  })
}

// 新增首页轮播图
export function addBan (data) {
  return request({
    url: '/back/ban',
    method: 'post',
    data: data
  })
}

// 修改首页轮播图
export function updateBan (data) {
  return request({
    url: '/back/ban',
    method: 'put',
    data: data
  })
}

// 删除首页轮播图
export function delBan (id) {
  return request({
    url: '/back/ban/' + id,
    method: 'delete'
  })
}

// 导出首页轮播图
export function exportBan (query) {
  return request({
    url: '/back/ban/export',
    method: 'get',
    params: query
  })
}

<template>
  <div class="trainMeet">
    <div>
      <img :src="img" alt="" v-handle-img="$store.state.api + src">
    </div>
    <div>
      <p>{{ title }}</p>
      <div><span>课程时间：</span>{{ starttime }} 至 {{ endtime }}</div>
      <div><span>开课地址：</span>{{ address }}</div>
      <div><span>课程简介：</span>{{ content }}</div>
      <div class="btn">
        <el-button v-if="tranmeetStatus === 1" type="primary" @click="goto">了解详情</el-button>
        <el-button v-else-if="tranmeetStatus === 2" type="success" @click="goto">进行中，了解详情</el-button>
        <el-button v-else type="info" disabled>已结束</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
export default {
  name: 'trainMeet',
  props: {
    id: {
      type: String
    },
    src: {
      type: String
    },
    title: {
      type: String
    },
    starttime: {
      type: String
    },
    endtime: {
      type: String
    },
    address: {
      type: String
    },
    content: {
      type: String
    }
  },
  data () {
    return {
      img: imgUrl
    }
  },
  computed: {
    // 获取时间节点
    tranmeetStatus () {
      // 通过时间戳比较是否开始
      const startTime = +new Date(this.starttime)
      const endTime = +new Date(this.endtime)
      const nowTime = +new Date()
      if (nowTime < startTime) {
        return 1 // 还未开始
      } else if (nowTime >= startTime && nowTime <= endTime) {
        return 2 // 已经开始，但是还未结束
      } else {
        return 0 // 已经结束
      }
    }
  },
  methods: {
    // 点击了解详情跳转到详情页
    goto () {
      this.$router.push({
        name: 'meetDetails',
        query: {
          id: this.id
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
    @media screen and (max-width: 767px) {
    .trainMeet {
      > div:first-child {
        width: 100%;
      }
      > div:last-child {
        width: 100%;
      }
      img {
        height: 200px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .trainMeet {
      display: flex;
      justify-content: flex-start;
      > div:first-child {
        width: 30%;
      }
      > div:last-child {
        width: 65%;
      }
      img {
        height: 100%;
      }
    }
  }
  .trainMeet {
    width: 100%;
    margin: 0;
    img {
      width: 100%;
    }
    > div:first-child {
      position: relative;
      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    > div:last-child {
      margin-left: auto;
      font-size: 14px;
      p {
        font-size: 18px;
        height: 31px;
        font-weight: 600;
        margin: 8px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
        word-wrap: break-word;
      }
      span {
        color: #999;
      }
    }
    .btn {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>

<template>
  <div id="download">
    <div class="poster">
      <img src="../../assets/images/poster/jiejfa.png" alt="">
    </div>
    
    <div class="container">
      <div class="btnContent">
        <div class="btnBox1" v-for="(item, index) in menuBtnList">
          <el-button
            type="text"
            :class="{ isShow: selectedBtn === index }"
            @click="queryProList(item, index)"
          >
            {{ item.name }}
          </el-button>
          <div v-if="index + 1 !== menuBtnList.length" style="width: 1px;margin: 0 10px;height: 15px; background: #000;"></div>
        </div>
      </div>
    </div>

    <div class="classTitle">
      <p class="title">{{ (menuBtnList.length > 0 && menuBtnList[selectedBtn].name) || ' ' }}</p>
    </div>

    <div class="display-list">
      <div class="display-item" v-for="item in dataList" @click="goDetail(item)">
        <div class="imgBox">
          <img :src="baseUrl + item.img" alt="">
        </div>
        <p class="fileItem">
          <span>{{ item.name }}</span>
        </p>
      </div>
    </div>

    <el-pagination
      v-show="total > 0"
      @current-change="handleCurrentChange"
      :current-page.sync="queryParams.pageNum"
      :page-size="9"
      layout="prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <p class="empty-class" v-if="dataList && dataList.length <= 0" :image-size="200">暂无数据 ～～～</p>
  </div>
</template>

<script>
import { proList } from "@/api/product";

export default {
  name: 'download',

  components: {
  },

  data () {
    const baseUrl = process.env.VUE_APP_BASE_API

    return {
      baseUrl: baseUrl,
      queryParams: {
        pageNum: 1,
        pageSize: 9,
        proOrSol: '1',
        type: ''
      },
      dataList: [],
      menuBtnList: [
        {
          name: '空调机组'
        },
        {
          name: '新风机组'
        },
        {
          name: '给排水系统'
        },
        {
          name: '冷热源交换站'
        },
        {
          name: '送排风系统'
        },
        {
          name: '电梯系统'
        }
      ],
      selectedBtn: 0,
      total: 0
    }
  },

  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },

  created() {
    this.queryProList(this.menuBtnList[0])
  },

  methods: {
    queryProList(item, index) {
      this.queryParams.type = item.name || this.queryParams.type
      this.selectedBtn = index || index === 0 ? index : this.selectedBtn
      this.queryParams.pageNum = 1
      proList(this.queryParams).then(res => {
        console.log('产品列表', res)
        this.dataList = res.rows
        this.total = res.total
      })
    },

    goDetail(item) {
      this.$router.push({
        path: '/solutionDetail',
        query: {
          obj: JSON.stringify(item)
        }
      })
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.pageNum = val
      this.queryProList()
    }
  }
}
</script>

<style lang='less' scoped>
#download {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .poster {
    width: 100%;

    img {
      width: 100%;
    }
  }

  ul {
    padding: 0;
  }
  .isShow {
    // background-color: red;
    color: red;
  }

  .empty-class {
    color: #e4e4e4;
  }
}

@media screen and (min-width: 768px) {
  .display-list {
    width: 1170px;
  }

  .classTitle {
    width: 1170px;
  }

  .display-item {
    div {
      width: 300px;
      height: 242px;
      overflow: hidden;
    }

    p {
      width: 300px;
    }
  }
}
@media screen and (max-width: 768px) {
  .display-list {
    width: 100%;
  }

  .classTitle {
    width: 100%;

    .title {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }

  .display-item {
    div {
      width: 170px;
      height: 140px;
      overflow: hidden;
    }
    p {
      width: 170px;
    }
  }
}

.container {
  width: 100%;

  .btnContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    box-sizing: border-box;

    .btnBox1 {
      display: flex;
      align-items: center;

      .el-button {
        font-size: 18px;
      }
    }

    .btnBox2 {
      display: flex;
      align-items: center;

      .el-button {
        font-size: 15px;
      }
    }

    .el-button {
      padding: 10px 0;
      color: black;

      &:hover {
        color: red;
      }
    }
  }
}

.classTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ececec;

  .title {
    font-size: 30px;
    font-weight: bold;
    line-height: 56px;
    color: #3b3b3b;
    letter-spacing: 2px;
    margin: 6px 0;
  }

  .content {
    font-size: 12px;
    color: #a0a0a0;
  }
}

.display-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 36px 5px;
  box-sizing: border-box;

  .display-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6px 12px 6px;

    .imgBox {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #e4e7ed;
      box-sizing: border-box;

      img {
        transition: all .5s;
        width: 95%;
        // height: 100%;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    p {
      margin: 0;
      padding: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      font-weight: bold;
      background-color: #fff;
      text-align: center;
    }

    &:hover {
      cursor: pointer;

      .imgBox {
        border-bottom: none;
        border-color: #3a8ee6;
      }

      p {
        background: #3a8ee6;
        color: #fff;
      }
    }
  }
}
</style>

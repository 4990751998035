<template>
  <div class="videoList">
    <ul>
      <li v-for="(item, index) in courseList" :key="index">
        <div v-if="item.state === '上架'">
          <video-info
          :price="item.cost"
          :title="item.name"
          :id="item.id"
          :uptime="item.uptime"
          :type="item.type"
          :goto="goto"
          :src="item.introduceimg"
          :shopCount="item.ordernum"/>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import videoInfo from '@/common/components/VideoInfo'
export default {
  name: 'videoList',
  components: {
    videoInfo
  },
  props: {
    courseList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    goto (id, price) {
      if (price > 0) {
        this.$router.push({
          name: 'courseDeals',
          query: {
            id
          }
        })
      } else {
        this.$router.push({
          name: 'videoShow',
          query: {
            id
          }
        })
      }
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 767px) {
    li {
      margin: 5px 10px;
    }
  }
  @media screen and (min-width: 767px) {
    li {
      margin: 10px 20px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 auto;
    li {
      // flex: auto;
      margin: 5px auto;
    }
  }
</style>

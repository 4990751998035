<template>
  <div class="password">
    <el-form :model="passwordForm" ref="passwordForm" :rules="ruleForm" label-width="100px">
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input type="password" v-model="passwordForm.oldPassword"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input type="password" v-model="passwordForm.password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPassword">
        <el-input type="password" v-model="passwordForm.checkPassword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updatePassword } from '@/api/user'
export default {
  name: 'password',
  data () {
    const checkPassword = (rule, value, callback) => {
      if (this.passwordForm.password === value) {
        callback()
      } else {
        callback(new Error('两次输入密码不一致'))
      }
    }
    return {
      passwordForm: {
        oldPassword: '',
        password: '',
        checkPassword: ''
      },
      ruleForm: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          { max: 18, min: 6, message: '请输入6-18位有效密码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { max: 18, min: 6, message: '请输入6-18位有效密码', trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async loadUpdatePassword () {
      try {
        // delete this.userInfo.phonenumber
        const res = await updatePassword({
          oldPassword: this.passwordForm.oldPassword,
          newPassword: this.passwordForm.password
        })
        if (res.code === 200) {
          this.$message.success('密码修改成功')
          this.passwordForm = {}
        }
      } catch (err) {
        console.log(err)
      }
    },
    onSubmit () {
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.loadUpdatePassword()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
  .password {

  }
</style>

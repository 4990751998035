<template>
  <div class="topShop">
    <!-- 面包屑导航 -->
    <breadcrumb :title="title" />
    <div class="mainContainer">
      <!-- 左侧图片功能区 -->
      <div class="leftImg">
        <!-- 图片 -->
        <img :src="img"
        :alt="title" v-handle-img="this.$store.state.api + src" >
        <!-- 点击试播放 -->
        <router-link v-if="isCourse" :to="{ name: 'videoShow', query: {
          id: id
        } }">
          <i class="el-icon-caret-right"></i>
          <p>试看</p>
        </router-link>
      </div>
      <!-- 右侧详细内容功能区 -->
      <ul class="rightInfo">
        <li>
          <!-- 标题 -->
          <p>{{ title }}</p>
        </li>
        <li v-if="isCourse">
          <!-- UL1 功能1 -->
          <ul class="ul1">
            <li>{{ buyPeople }}人购买</li>
            <!-- <li v-else>{{ buyPeople }}人报名</li> -->
            <li class="line"></li>
            <li>{{ visitedNumber }}人访问</li>
            <!-- <li v-if="isCourse" class="line"></li> -->
            <!-- <li @click="onShare" class="isLink">
              <i class="el-icon-share"></i>
              分享
            </li> -->
            <!-- <li class="line"></li> -->
            <!-- <li @click="onCollect" class="isLink">
              <i class="el-icon-collection-tag"></i>收藏
            </li> -->
            <!-- <li v-if="isCourse" class="line"></li> -->
            <!-- <li v-if="isCourse">
              <span>评分：</span>
              <el-rate
                v-model="score"
                disabled
                show-score
                text-color="#ff9900"
                disabled-void-color="#C6D5E9"
                score-template="{value}">
              </el-rate>
            </li> -->
          </ul>
        </li>
        <li>
          <!-- UL2 功能2 -->
          <ul class="ul2">
            <li>原价：<del>￥{{ oldPrice }}</del></li>
            <li>限时特惠：<span>￥{{ cost / 100 }}</span></li>
            <!-- <li class="isLink"><i class="el-icon-sell"></i>成为会员</li> -->
          </ul>
        </li>
        <li>
          <el-button type="success" @click="onSubmit" :disabled="isAllowd ? (isCourse ? false : true) : false">
            {{ isCourse ? (isAllowd ? '点击播放' : '立即购买') : (isAllowd ? '报名成功' : '立即报名') }}
          </el-button>
          <el-button type="info">0516-83888208</el-button>
        </li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
import '@/utils/share.js'
import breadcrumb from '@/common/components/Breadcrumb'
import imgUrl from '@/assets/images/loading.gif'
export default {
  name: 'topShop',
  components: {
    breadcrumb
  },
  props: {
    src: { // src图片路径
      type: String
    },
    title: { // 课程标题
      type: String,
      default: '安评应急培训'
    },
    buyPeople: { // 购买/报名人数
      type: Number,
      default: 4146
    },
    visitedNumber: { // 访问数量人数
      type: String,
      default: '465'
    },
    isCourse: { // 是否是课程
      type: Boolean,
      default: true
    },
    isAllowd: { // 是否已经达成条件
      type: Boolean,
      default: false
    },
    score: { // 课程评分
      type: Number,
      default: 2.6
    },
    oldPrice: { // 原价
      type: String,
      default: '39.99'
    },
    cost: { // 会员优惠价
      type: Number,
      default: 0
    },
    onSubmit: { // 报名/购买
      type: Function
    },
    onCollect: { // 收藏
      type: Function
    },
    onShare: { // 分享
      type: Function
    },
    id: { // id
      type: String,
      default: ''
    }
  },
  data () {
    return {
      img: imgUrl
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
  .topShop {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    .topShop {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 767px) {
    .topShop {
      .leftImg {
        width: 100%;
      }
      .rightInfo {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .topShop {
      .leftImg {
        width: 45%;
      }
      .rightInfo {
        width: 45%;
      }
    }
  }
  .topShop {
    .mainContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .leftImg {
      position: relative;
      max-height: 392.78px;
      img {
        width: 100%;
        height: 100%;
      }
      a {
        /* a标签完全居中 */
        position: absolute;
        width: 70px;
        height: 70px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* 文字完全居中 */
        text-align: center;
        line-height: 70px;
        border-radius: 50%;
        box-shadow: 0 0 30px rgb(255, 255, 255);
        background-color: rgb(35,184,255);
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      i {
        font-size: 50px;
        line-height: 70px;
        color: #fff;
      }
      p {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        color: #fff;
      }
    }
    .rightInfo {
      p {
        font-size: 20px;
        height: 25px;
        word-break: keep-all;       /* 不换行 */
        white-space: nowrap;        /* 不换行 */
        overflow: hidden;           /* 内容超出宽度时隐藏超出部分的内容 */
        text-overflow: ellipsis;    /*溢出时显示省略标记...；需与overflow:hidden;一起使用*/
      }
      > li {
        margin: 20px 0;
      }
      .isLink {
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
      .ul1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        color: rgb(153,153,166);
        font-size: 14px;
        li:nth-child(2n + 1) {
          display: flex;
          margin: 0 10px;
          align-items: center;
        }
        li:nth-child(5) {
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        li:nth-child(7) {
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        li:first-child {
          margin-left: 0;
        }
        li:last-child {
          margin-right: 0;
        }
        .line {
          width: 1px;
          height: 20px;
          background-color: rgba(0, 0, 0, .6);
        }
      }
      .ul2 {
        display: flex;
        justify-content: flex-start;
        li {
          margin-right: 15px;
          color: rgb(230,162,60);
          line-height: 33px;
          font-size: 14px;
        }
        li:nth-child(2) {
          font-size: 16px;
        }
        li:last-child {
          font-size: 20px;
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        del {
          color: rgb(245,108,108);
        }
        span {
          color: rgb(245,108,108);
        }
      }
    }
  }
</style>

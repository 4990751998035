import { render, staticRenderFns } from "./DataView.vue?vue&type=template&id=c5a9ac68&scoped=true&"
import script from "./DataView.vue?vue&type=script&lang=js&"
export * from "./DataView.vue?vue&type=script&lang=js&"
import style0 from "./DataView.vue?vue&type=style&index=0&id=c5a9ac68&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a9ac68",
  null
  
)

export default component.exports
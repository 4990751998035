<template>
  <div class="bannerContainer" :style="{ width: autoWidth, height: autoHeight, paddingTop: isIndex ? 0 : '75px' }">
    <div v-if="isLoading" class="loadingBox">
      <img :src="img" alt="普飞特电子科技">
    </div>
    <div class="swiperContent" style="height: 100%; width: 100%;">
      <div class="indexBanner" v-if="true"> <!-- vue-awesome-swiper组件 -->
        <swiper :options="swiperOption" class="czp" :style="{ width: autoWidth, height: autoHeight }">
          <swiper-slide v-for="(item, index) in showBanner" :key="index">
            <!-- 备注：被注释项使用自定义指令会使得swiper自动复制的图片仍然是一开始loading图 -->
            <!-- <img :src="img" :alt="item.title" v-handle-img="$store.state.api + item.img"> -->
            <img class="bannerImg" :src="baseUrl + item.img" :alt="item.title" />
            <div class="info">
              <h3>{{ item.title }}</h3>
              <span>{{ item.content }}</span>
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <div class="swiper-scrollbar"></div> <!-- //滚动条 -->
        <div class="swiper-button-next" :style="{
          marginRight: marginRight
        }"></div> <!-- //下一项 -->
        <div class="swiper-button-prev"></div> <!-- //上一项 -->
      </div>

      <div class="imgContainer" v-if="false">
<!--        <img :src="img" :alt="showBanner[0].title" v-handle-img="$store.state.api + showBanner[0].img">-->

<!--        <video class="video"  id="bgvid" playsinline="" autoplay muted loop="">-->
<!--          <source src="../../assets/video/bannerBack.mp4" type="video/mp4">-->
<!--        </video>-->

        <div class="info">
          <h2>{{ showBanner[0].title }}</h2>
          <div v-html="showBanner[0].content">
          </div>
        </div>
      </div>
    </div>

    <video class="video hidden-xs-only" id="bgvid" playsinline="" autoplay muted loop="">
      <source src="../../assets/video/bannerBack.mp4" type="video/mp4">
    </video>
  </div>
</template>

<script>
import { listBan } from '@/api/banner'
import { list } from '@/api/index'
import imgUrl from '@/assets/images/loading.gif'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  name: 'banner',
  components: {
    swiper,
    swiperSlide
  },
  data () {
    const baseUrl = process.env.VUE_APP_BASE_API

    return {
      baseUrl: baseUrl,
      img: imgUrl,
      autoWidth: '1600px',
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 2000, // 轮播时间
          stopOnLastSlide: false, // 是否在最后一张轮播图上停止
          disableOnInteraction: false,
          reverseDirection: false // 开启反向自动轮播。
        }, // 可选选项，自动滑动
        navigation: {
          nextEl: '.swiper-button-next', // 前进按钮的css选择器或HTML元素。
          prevEl: '.swiper-button-prev', // 后退按钮的css选择器或HTML元素。
          hideOnClick: true, // 点击slide时显示/隐藏按钮
          disabledClass: 'my-button-disabled', // 前进后退按钮不可用时的类名。
          hiddenClass: 'my-button-hidden' // 按钮隐藏时的Class
        },
        loop: false // 形成闭环
      },
      // tableData: [],
      // total: 0, // 分页总数
      // pageNum: 1, // 分页当前页码数
      // pageSize: 10, // 分页数量
      imgSrcs: [],
      isLoading: false,
      showBanner: []
    }
  },

  watch: {
    $route: {
      handler (newVal, oldVal) {
        // this.showBanner = this.imgSrcs.filter((item, index, arr) => {
        //   // 将对应模块的banner过滤出来并赋值给imgSrcs
        //   if (item.modular !== 'index') {
        //     return item.modular === newVal.path.split('/')[1]
        //   } else {
        //     return item.modular === newVal.name
        //   }
        // })
      },
      deep: true
    }
  },

  computed: {
    // 在PC端肯定有滚动条，获取滚动条并且设置margin值
    marginRight () {
      return this.$store.state.device === 'PC' ? this.getScrollbarWidth() + 'px' : 0
    },
    // showBanner () {
    //   return this.imgSrcs.filter((item, index, arr) => {
    //     // 将对应模块的banner过滤出来并赋值给imgSrcs
    //     if (item.modular !== 'index') {
    //       return item.modular === this.$route.path.split('/')[1]
    //     } else {
    //       return item.modular === this.$route.name
    //     }
    //   })
    // },
    isIndex () {
      return this.$route.name === 'index' || this.$store.state.isXs
    },
    autoHeight () {
      // 获取可视区域高度H  XS小屏 0.3H 大屏首页banner0.5H 大屏其他页面0.4H
      return (document.documentElement.clientHeight || document.body.clientHeight) * (this.$store.state.isXs ? 0.3 : (this.$route.name === 'index' ? 0.5 : 0.4)) + 'px'
    }
  },

  created () {
    // 获取banner列表
    this.loadBannerList()

    /* 根据屏幕动态设置轮播图高度和宽度 */
    this.autoWidth = document.body.clientWidth + 'px'
  },

  methods: {
    goto () { // 点击banner的icon跳转到下一屏
      this.$store.state.el_.scrollTop = parseInt(this.autoHeight) + 1
    },
    // 获取滚动条宽度
    getScrollbarWidth () {
      const scrollDiv = document.createElement('div')
      scrollDiv.style.cssText = 'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;'
      document.body.appendChild(scrollDiv)
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
      document.body.removeChild(scrollDiv)
      return scrollbarWidth
    },
    // 等待banner列表数据
    loadBannerList () {
      list({modular: 1}).then(res => {
        this.showBanner = res.rows.map((val, index) => {
          return {
            ...val,
            // img: require(`../../assets/images/banner/banner${index + 1}.jpg`)
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .bannerContainer {
    .info h3 {
      font-size: 20px;
    }
    .info span {
      font-size: 13px;
      line-height: 16px;
    }

    .indexBanner {
      .bannerImg {
        display: inline-block;
      }

      .swiper-button-prev {
        display: none;
      }

      .swiper-button-next {
        display: none;
      }
    }

    .imgContainer {
      // display: none;
    }
  }
}
@media screen and (min-width: 768px) {
  .bannerContainer {
    .indexBanner {
      .bannerImg {
        display: none;
      }
      .swiper-button-prev {
        display: inline-block;
      }

      .swiper-button-next {
        display: inline-block;
      }
    }

    .info h3 {
      font-size: 40px;
    }
    .info span {
      font-size: 15px;
      line-height: 18px;
    }
  }
}
.bannerContainer {
  overflow: hidden;
  margin-bottom: 15px;
  position: relative;

  .swiperContent {
    // position: absolute;
  }

  .imgContainer {
    position: relative;
    height: 100%;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
    }

    .video {
      // height: 100%;
      width: 100%;
    }
  }

  .video {
    top: 0;
    left: 0;
    position: absolute;
    // height: 100%;
    width: 100%;
  }
}
.indexBanner {
  overflow: hidden;
  position: relative;
  font-size: 0; /* 解决多出3px问题 */
  background-color: rgba(0,0,0, 0.3);

  .swiper-button-next {
    position: absolute;
    outline: none;
    height: 30px;
    z-index: 100;
    top: 52%;
    right: 50px;
  }
  .swiper-button-prev {
    position: absolute;
    outline: none;
    height: 30px;
    z-index: 100;
    top: 52%;
    left: 50px;
  }
}
img {
  width: 100%;
  height: 100%;
}
.info {
  position: absolute;
  width: 90%;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // color: black;
  color: #fff;
  text-align: center;
  // p {
  //   font-size: 20px;
  // }
  // span {
  //   font-size: 12px;
  //   line-height: 18px;
  // }
}
#next {
  position: absolute;
  font-size: 50px;
  z-index: 10;
  color: #fff;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  animation-iteration-count: infinite;
  cursor: pointer;
}
.otherBanner {
  div {
    text-align: center;
    margin-bottom: 20px;
    // height: 300px;
    // background-position: center center;
    // background-size: contain;
    // background-repeat: no-repeat;
  }
  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
}
.loadingBox {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

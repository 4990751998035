<template>
  <div id="container">
    <div class="header">
      <topbar /> <!-- 头部topbar -->
    </div>
    <div class="main">
      <!-- <banner v-if="$route.meta.isBanner" /> -->
      <div class="poster" style="margin-top: 75px;" v-if="$route.meta.isBanner">
        <img src="../../assets/images/poster/shouye.png" alt="">
      </div>

      <div v-if="$route.meta.isFullScreen">
        <keep-alive :max="10">  <!-- 最大缓存数10，条件判断要落实到router-view上，在keep-alive上会失效 -->
          <router-view v-if="$route.meta.isKeepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.isKeepAlive"></router-view>
      </div>
      <div v-else :class="{ marginTop: !$route.meta.isBanner }">
        <keep-alive :max="10">  <!-- 最大缓存数10，条件判断要落实到router-view上，在keep-alive上会失效 -->
          <router-view v-if="$route.meta.isKeepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.isKeepAlive"></router-view>
      </div>
    </div>
    <div class="footer">
      <foot-tips /> <!-- 底部信息提示补充 -->
      <mobile-foot-tips v-if="$store.state.isXs" />
    </div>
  </div>
</template>

<script>
import banner from '@/common/components/Banner'
import topbar from '@/components/layout/topbar'
import footTips from '@/components/layout/footTips'
import mobileFootTips from '@/components/layout/mobileFootTips'
export default {
  name: 'layout',
  components: {
    topbar,
    footTips,
    mobileFootTips,
    banner
  }
}
</script>

<style lang='less'>
  @media screen and (max-width: 768px) {
    #container {
      .halfScreen {
        // margin-top: 75px;
      }
    }
  }
  @media screen and (min-width: 768px) {
    #container {
      .marginTop {
        margin-top: 75px;
      }
    }
  }
  #container {
    position: relative;
    @media screen and (max-width: 767px) {
      .header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: #fff;
        // box-shadow: 0 15px 10px -12px #e1e0e0;
        // box-shadow:0px 15px 10px -15px #e1e0e0;
      }
      .main {
        margin-top: 75px;
      }
    }
    @media screen and (min-width: 768px) {
      .header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
      }
    }
    .header {
      padding: 0px;
      overflow: hidden;
      height: 75px !important;
    }
    .main {
      overflow: hidden;
      padding: 0 0 100px 0;
      width: 100%;
    }
    .footer {
      padding: 0;
    }

    .poster {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
  // @media screen and (max-width: 768px) {
  //   #container {
  //     .halfScreen {
  //       // margin-top: 75px;
  //     }
  //   }
  // }
  // @media screen and (min-width: 768px) {
  //   #container {
  //     .halfScreen {
  //       margin-top: 75px;
  //     }
  //   }
  // }
  // #container {
  //   position: relative;
  //   @media screen and (max-width: 767px) {
  //     .el-header {
  //       position: fixed;
  //       top: 0;
  //       left: 0;
  //       right: 0;
  //       z-index: 1000;
  //       background-color: orange;
  //     }
  //     .el-main {
  //       margin-top: 75px;
  //     }
  //   }
  //   @media screen and (min-width: 768px) {
  //     .el-header {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       right: 0;
  //       z-index: 1000;
  //     }
  //   }
  //   .el-header {
  //     padding: 0px;
  //     overflow: hidden;
  //     height: 75px !important;
  //   }
  //   .el-main {
  //     overflow: hidden;
  //     padding: 0;
  //     width: 100%;
  //   }
  //   .el-footer {
  //     padding: 0;
  //   }
  // }
</style>

import request from '@/utils/request'

// 查询订单详情列表
export function listDetail (query) {
  return request({
    url: '/back/orderdetail/list',
    method: 'get',
    params: query
  })
}

// 查询订单详情详细
export function getDetail (id) {
  return request({
    url: '/back/orderdetail/' + id,
    method: 'get'
  })
}

// 新增订单详情
export function addDetail (data) {
  return request({
    url: '/back/orderdetail',
    method: 'post',
    data: data
  })
}

// 修改订单详情
export function updateDetail (data) {
  return request({
    url: '/back/orderdetail',
    method: 'put',
    data: data
  })
}

// 删除订单详情
export function delDetail (id) {
  return request({
    url: '/back/orderdetail/' + id,
    method: 'delete'
  })
}

// 导出订单详情
export function exportDetail (query) {
  return request({
    url: '/back/orderdetail/export',
    method: 'get',
    params: query
  })
}

<template>
  <div class="product-content">
    <div class="display-list">
      <div class="display-item" v-for="item in dataList">
        <div>
          <img :src="item.img" alt="">
        </div>
        <p>{{ item.name }}</p>
      </div>
    </div>

    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="queryParams.pageNum"
      :page-size="10"
      layout="prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "kzqCmp",

  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      dataList: [
        {
          name: '补发伤风败俗肯定比粉丝看的方便开始的吧发烧发',
          img: require('../../assets/images/oneImg.png')
        },
        {
          name: '啥发啥发',
          img: require('../../assets/images/oneImg.png'),
        },
        {
          name: '老妈你看来仍请尽快',
          img: require('../../assets/images/oneImg.png'),
        }
      ],
      total: 0
    }
  },

  methods: {
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  }
}
</script>

<style scoped lang="less">
@media screen and (min-width: 768px) {
  .display-list {
    max-width: 1170px;
  }
  .display-item {
    div {
      width: 300px;
      height: 242px;
      overflow: hidden;
    }

    p {
      width: 300px;
    }
  }
}
@media screen and (max-width: 768px) {
  .display-list {
    width: 100%;
  }
  .display-item {
    div {
      width: 170px;
      height: 140px;
      overflow: hidden;
    }
    p {
      width: 170px;
    }
  }
}

.product-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .display-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 5px;
    box-sizing: border-box;

    .display-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 6px 12px 6px;

      img {
        transition: all .5s;
        width: 100%;
        height: 100%;

        &:hover {
          transform: scale(1.2);
        }
      }

      p {
        margin: 0;
        padding: 5px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;
        font-weight: bold;
        background-color: #fff;
      }

      &:hover {
        cursor: pointer;

        p {
          background: #3a8ee6;
          color: #fff;
        }
      }
    }
  }
}
</style>

<template>
  <div id="companyIntroduct">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>公司简介</span>
      </div>
      <div class="content">
        <div>
<!--          <img :src="img" :alt="tableData.name"
          v-handle-img="$store.state.api + tableData.introduceimg">-->

          <video class="video" id="bgvid" playsinline="" autoplay muted loop="">
            <source src="../../assets/video/company.mp4" type="video/mp4">
          </video>
        </div>
        <div>
          <p>
            徐州普飞特电子科技有限公司，坐落在江苏省徐州市中国矿业大学科技园。中国矿业大学多学科的综合优势使得我们有能力为用户提供全方位综合解决方案，公司从中国矿业大学引进了一批技术骨干，其中多名教授、博士在自控行业具有多年研发经验，同时也引进了一些企业精英，他们在自控行业兢兢业业的工作了若干年，为客户提供了多年的优质服务得到大家的好评。
          </p>
          <p>
            公司在“以质量求生存，以诚信求发展”的前题下，急用户所急，为用户解决难题，同时本着互惠互利的原则，谒诚为用户提供一流的产品、一流的服务。过硬的产品质量是我们实现目标的重要一环，本公司将国际先进水平的自动化、通信、计算机技术用于楼宇控制行业，让产品走在楼控行业的最前沿。
          </p>
          <p>
            公司秉承“敬业、合作、创新”的企业精神，以技术创新为原动力，创立自主知识产权的产品体系，结合系统集成技术，为社会提供一流的行业解决方案和服务。严格履行合同，为工程提供优质的设备和服务。
          </p>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { listInfo } from '@/api/company'
import imgUrl from '@/assets/images/loading.gif'
export default {
  name: 'companyIntroduct',
  data () {
    return {
      tableData: [],
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      img: imgUrl
    }
  },
  methods: {
    async loadList () {
      try {
        const res = await listInfo({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          name: this.search
        })
        if (res.code === 200) {
          this.total = res.total
          this.tableData = res.rows[0]
        }
      } catch (error) {
        this.$message.error('未知错误，请重新尝试')
      }
    },
    onSearch () {
      this.pageNum = 10
      this.pageNum = 1
      this.loadList()
    }
  },
  created () {
    // this.loadList()
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 767px) {
    #companyIntroduct {
      .content {
        > div:first-child {
          width: 100%;
        }
        > div:last-child {
          width: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 767px) {
    #companyIntroduct {
      .content {
        > div:first-child {
          width: 40%;
        }
        > div:last-child {
          width: 56%;
        }
      }
    }
  }
  #companyIntroduct {
    .box-card {
      margin: 0 auto;
      margin-top: 20px;
      width: 90%;
      text-align: left;
    }
    .clearfix {
      span {
        font-size: 20px;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      > div:first-child {
        flex: 1;
        img {
          width: 100%;
          height: 100%;
        }

        .video {
          width: 95%;
        }
      }
      > div:last-child {
        background-color: rgb(247,247,247);
        padding: 15px;
      }
      p {
        margin: 10px;
        padding: 0;
        text-indent: 24px;
        // color: #919191;
        // font-size: 14px;
        // line-height: 25px;
        // text-align: left;
        // text-indent: 28px;
      }
    }
  }
</style>

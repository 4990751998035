<template>
  <div id="vip">
    <div class="container">
      <div class="searchContainer">
      <!-- 筛选 -->
        <div>
          <el-select v-model="type" placeholder="课程分类" clearable
          @change="onSearch">
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <!-- 搜索 -->
        <div>
          <el-input placeholder="课程名称" v-model="search" class="input-with-select" clearable @keydown.enter.native="onSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearch"></el-button>
          </el-input>
        </div>
      </div>
      <div v-if="!$store.state.vip.courseList" style="text-align: center;">正在加载中...</div>
      <video-list v-else :courseList="$store.state.vip.courseList" />
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="$store.state.vip.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import { listCurriculum } from '@/api/course'
import { mapActions } from 'vuex'
import videoList from '@/common/components/VideoList'
export default {
  name: 'vip',
  components: {
    videoList
  },
  data () {
    return {
      activeNameOuter: 'first',
      activeNameInner1: 'first',
      activeNameInner2: 'first',
      options: ['煤炭', '金属非金属', '露天开采', '机电和自动化', '专业软件', '企业运营与管理', '石油开采与冶炼', '矿业勘探&投资', '公共课程'],
      searchKind: '',
      // tableData: [],
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      type: null, // 分类
      isLoading: true
    }
  },
  methods: {
    ...mapActions(['getCourseList']),
    loadList () {
      this.getCourseList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        name: this.search,
        type: this.type
      })
    },
    // async loadList () {
    // try {
    //   const res = await listCurriculum({
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum,
    //     name: this.search,
    //     type: this.type
    //   })
    //   if (res.code === 200) {
    //     this.total = res.total
    //     this.tableData = res.rows
    //     this.isLoading = false
    //   }
    // } catch (error) {
    //   this.$message.error('未知错误，请重新尝试')
    // }
    // },
    onSearch () {
      this.pageNum = 10
      this.pageNum = 1
      this.loadList()
    },
    changeTabs () {
      this.isVip = !this.isVip
    },
    // 分页每页数量发生改变
    handleSizeChange (val) {
      this.pageSize = val
      this.loadList()
    },
    // 分页当前页码发生改变
    handleCurrentChange (val) {
      this.pageNum = val
      this.loadList()
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    this.loadList()
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #vip .container {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #vip .container {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 768px) {
    #vip {

    }
  }
  .container {
    .searchContainer {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      > div {
        margin: 0 3px;
      }
    }
  }
</style>

<template>
  <div class="bottomSynthesize">
    <!-- 标签页功能区 -->
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item" :name="index + ''"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <div v-show="activeName === '0'">
        <slot name="first"></slot>
      </div>
      <!-- <div v-show="activeName === '1'">
        <slot name="second"></slot>
      </div>
      <div v-show="activeName === '2'">
        <slot name="three"></slot>
      </div>
      <div v-show="activeName === '3'">
        <slot name="four"></slot>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottomSynthesize', // 底部综合功能区
  props: {
    tabs: {
      type: Array, // 头像图片路径
      default: function () {
        return {
          tabs: []
        }
      }
    }
  },
  data () {
    return {
      activeName: 0,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      score: null
    }
  },
  methods: {
    submitProblem () {
      this.$message({
        type: 'error',
        message: '提交/评价功能暂时已关闭'
      })
    }
  }
}
</script>

<style lang='less' scoped>
  .bottomSynthesize {
    .el-tabs {
      /* 使el-tabs在手机端可以滑动 */
      display: flex;
      overflow-x: auto;
    }
    .content {
      width: 98%;
      margin: 0 auto;
    }
  }
</style>

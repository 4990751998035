<template>
  <div id="meetDetails">
    <!-- 顶部面包屑菜单 -->
    <div class="fullScreen">
      <top-shop
      :src="course.img"
      :isCourse="false"
      :title="course.title"
      :onSubmit="onSubmit"
      :onCollect="onCollect"
      :onShare="onShare"
      :isAllowd="isApply" />
    </div>
    <!-- 底部综合功能区 -->
    <div class="center">
      <bottom-synthesize
      :tabs="tabs">
      <div slot="first">
        <div class="first" v-html="course.summary">
        </div>
      </div>
      <!-- <div slot="second">
        <div class="second">
          <p>课程安排</p>
        </div>
      </div>
      <div slot="three">
        <div class="three">
          <lecturer />
        </div>
      </div>
      <div slot="four">
        <div class="four">
          <p>培训时间：2021 年 04 月 20 日 - 2021 年 04 月 29 日</p>
          <p>培训地点：徐州市泉山区解放南路中国矿业大学国家大学科技园A座（如有地点变更会提前通知学员）</p>
        </div>
      </div> -->
      </bottom-synthesize>
    </div>
    <!-- 报名信息填写 -->
    <el-dialog
      title="用户报名"
      destroy-on-close
      :visible.sync="dialogVisible"
      :width="$store.state.isXs ? '95%' : '500px'"
      :show-close="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form ref="formData" label-width="85px" :model="formData" :rules="formRule">
        <el-form-item label="姓名" prop="applicant">
          <el-input v-model="formData.applicant"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phonenumber">
          <el-input v-model="formData.phonenumber"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="company">
          <el-input v-model="formData.company"></el-input>
        </el-form-item>
        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model="formData.email"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import topShop from '@/common/components/TopShop'
import bottomSynthesize from '@/common/components/BottomSynthesize'
import { getIntroduction } from '@/api/college'
import { listRegistration, addRegistration } from '@/api/offlineRegistration'
export default {
  name: 'meetDetails',
  components: { topShop, bottomSynthesize },
  data () {
    return {
      // 是否已经报名
      isApply: false,
      // tabs: ['课程概述', '课程安排', '主讲导师', '课程时间地点'],
      tabs: ['课程概述'],
      dialogVisible: false,
      formData: {},
      course: {},
      formRule: {
        applicant: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 100, min: 2, message: '请输入2-100有效长度', trigger: 'blur' }
        ],
        phonenumber: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^1[123456789]\d{9}$/, message: '请输入正确的联系方式', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          { max: 100, min: 2, message: '请输入2-100有效长度', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          // eslint-disable-next-line
          { pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: '请输入正确的邮箱', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 获取线下课程数据详情
    async loadCourseData (id) {
      const res = await getIntroduction(id)
      if (res.code === 200) {
        if (res.data) {
          this.course = res.data
        } else {
          const loading = this.$loading({
            lock: true,
            text: '此培训会不存在，即将返回会员专区',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          setTimeout(() => {
            this.$router.push({
              name: 'college'
            })
            loading.close()
          }, 2000)
        }
      }
    },
    // 获取是否已经报名详情
    async loadRegistration (id) {
      const res = await listRegistration({
        pageSize: 10,
        pageNum: 1,
        trianmeetid: id,
        userid: this.$store.state.user.userInfo.userName || this.$store.state.user.wxUserInfo.openid
      })
      if (res.code === 200) {
        if (res.total === 0) {
          this.isApply = false
        } else {
          this.isApply = true
        }
      }
    },
    // 上传用户数据
    uploadData () {
      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          try {
            const loading = this.$loading({
              lock: true,
              text: 'loading',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.9)'
            })
            if (this.$store.state.isLoginType === 1) {
              this.formData.userid = this.$store.state.user.userInfo.userName
            } else if (this.$store.state.isLoginType === 2) {
              this.formData.userid = this.$store.state.user.wxUserInfo.openid
            } else {
              this.formData.userid = '匿名用户'
            }
            this.formData.id = Math.floor(Math.random() * 100000000000) + ''
            this.formData.trianmeetid = this.$route.query.id
            this.formData.ordertime = new Date()
            this.formData.trianmeetname = this.course.title
            const res = await addRegistration(this.formData)
            if (res.code === 200) {
              this.$message.success('报名成功')
              this.isApply = true
              this.dialogVisible = false
            } else {
              this.$message.error('报名失败，请重新尝试')
            }
            loading.close()
          } catch (err) {
            console.log(err)
            this.$message.error('报名失败，请刷新网页重新再试')
          }
        } else {
          return false
        }
      })
    },
    onSubmit () { // 打开dialog对话框
      this.dialogVisible = true
    },
    onShare () { // 分享
      this.$message.warning('分享功能暂未实现')
    },
    onCollect () { // 收藏
      this.$message.warning('收藏功能暂未实现')
    },
    handleClose () {}, // 关闭前的钩子函数
    handleCancel () { // 取消时触发的函数
      this.dialogVisible = false
      this.formData = {
        name: '',
        age: ''
      }
    },
    handleSubmit () { // 提交时触发的函数
      this.uploadData()
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    // this.loadRegistration()
    const id = this.$route.query.id
    this.loadCourseData(id)
    if (this.$store.state.isLoginType > 0) {
      this.loadRegistration(id)
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #meetDetails .center {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #meetDetails .center {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 768px) {
    #meetDetails {

    }
  }
  #meetDetails {
    .fullScreen{
      background-color: rgb(243,243,243);
      padding-bottom: 10px;
    }
    .three {
      width: 300px;
      margin: 0 auto;
    }
    /deep/ .el-dialog {
      overflow: hidden;
    }
  }
</style>

<template>
  <div id="projectScore">
    <div class="poster">
      <img src="../../assets/images/poster/gongcyj.png" alt="">
    </div>

    <div class="container">
      <div class="btnContent">
        <div class="btnBox1" v-for="(item, index) in menuBtnList">
          <el-button
            type="text"
            :class="{ isShow: selectedBtn === index }"
            @click="queryProList(item, index)"
          >
            {{ item.name }}
          </el-button>
          <div v-if="index + 1 !== menuBtnList.length" style="width: 1px;margin: 0 10px;height: 15px; background: #000;"></div>
        </div>
      </div>

      <div class="btnContent">
        <div class="btnBox2" v-for="(item, index) in menuBtnChildrenList">
          <el-button
            type="text"
            :class="{ isShow: selectedChildrenBtn === index }"
            @click="queryChildrenProList(item, index)"
          >
            {{ item.name }}
          </el-button>
          <div v-if="index + 1 !== menuBtnChildrenList.length" style="width: 1px;margin: 0 10px;height: 15px; background: #000;"></div>
        </div>
      </div>

      <!--      <div class="btnContent">
              <el-button :class="{ isShow: $route.path.split('/')[2] === '' }" @click="$router.replace({name: 'kzqCmp'})">控制器&触摸屏</el-button>
              <el-button :class="{ isShow: $route.path.split('/')[2] === 'bsqCgq' }" @click="$router.replace({name: 'bsqCgq'})">变送器&传感器</el-button>
              <el-button :class="{ isShow: $route.path.split('/')[2] === 'kg' }" @click="$router.replace({name: 'kg'})">开关类</el-button>
              <el-button :class="{ isShow: $route.path.split('/')[2] === 'fmQdq' }" @click="$router.replace({name: 'fmQdq'})">阀门与驱动器</el-button>
              <el-button :class="{ isShow: $route.path.split('/')[2] === 'ztwg' }" @click="$router.replace({name: 'ztwg'})">组态网关类</el-button>
            </div>
            <keep-alive :max="10">  &lt;!&ndash; 最大缓存数10，条件判断要落实到router-view上，在keep-alive上会失效 &ndash;&gt;
              <router-view v-if="$route.meta.isKeepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.isKeepAlive"></router-view>-->
    </div>

    <div class="classTitle">
      <p class="title">{{ (menuBtnChildrenList.length > 0 && menuBtnChildrenList[selectedChildrenBtn].name) || (menuBtnList.length > 0 && menuBtnList[selectedBtn].name) || ' ' }}</p>
    </div>

    <div class="display-list">
      <div class="display-item" v-for="item in dataList" @click="goDetail(item)">
        <div class="imgBox">
          <img :src="baseUrl + item.img" alt="">
        </div>
        <p>{{ item.name }}</p>
      </div>
    </div>

    <el-pagination
      v-show="total > 0"
      @current-change="handleCurrentChange"
      :current-page.sync="queryParams.pageNum"
      :page-size="9"
      layout="prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <p class="empty-class" v-if="dataList && dataList.length <= 0" :image-size="200">暂无数据 ～～～</p>
  </div>
</template>

<script>
import { menuList, proList } from "@/api/project";

export default {
  name: 'index',
  components: {
  },
  data () {
    const baseUrl = process.env.VUE_APP_BASE_API

    return {
      baseUrl: baseUrl,
      menuList: [],
      menuBtnList: [],
      menuBtnChildrenList: [],
      selectedBtn: 0,
      selectedChildrenBtn: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 9,
        type: ''
      },
      dataList: [],
      total: 0
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created() {
    this.getMenuList()
  },
  methods: {
    queryProList(item, index) {
      this.selectedChildrenBtn = 0
      this.selectedBtn = index || index === 0 ? index : this.selectedBtn
      this.queryParams.pageNum = 1
      this.menuBtnChildrenList = this.menuList.filter(val => {
        return val.pid === item.id
      })

      if (this.menuBtnChildrenList.length > 0) {
        this.queryChildrenProList(this.menuBtnChildrenList[0])
      } else {
        this.queryParams.type = item.name || this.queryParams.type
        proList(this.queryParams).then(res => {
          console.log('产品列表', res)
          this.dataList = res.rows
          this.total = res.total
        })
      }

      // this.queryParams.type = item.name || this.queryParams.type
      // console.log('产品列表参数', this.queryParams)
      // proList(this.queryParams).then(res => {
      //   console.log('产品列表', res)
      //   this.dataList = res.rows
      //   this.total = res.total
      // })
    },

    queryChildrenProList(item, index) {
      this.selectedChildrenBtn = index || index === 0 ? index : this.selectedChildrenBtn
      this.queryParams.type = item.name || this.queryParams.type
      console.log('产品列表参数', this.queryParams)
      proList(this.queryParams).then(res => {
        console.log('产品列表', res)
        this.dataList = res.rows
        this.total = res.total
      })
    },

    getMenuList() {
      menuList().then(res => {
        console.log('menuList', res)
        // this.menuList = res.rows
        this.menuList = [
          {
            name: '楼宇',
            pid: '0'
          },
          {
            name: '工厂',
            pid: '0'
          },
          {
            name: '酒店',
            pid: '0'
          },
          {
            name: '枢纽',
            pid: '0'
          }
        ],
        this.menuBtnList = this.menuList.filter(val => {
          return val.pid === '0'
        })
        this.menuBtnChildrenList = this.menuList.filter(val => {
          return val.pid === this.menuBtnList[0].id
        })
        if (this.menuBtnChildrenList.length > 0) {
          this.queryChildrenProList(this.menuBtnChildrenList[0])
        } else {
          this.queryChildrenProList(this.menuBtnList[0])
        }
      })
    },

    goDetail(item) {
      this.$router.push({
        path: '/projectDetail',
        query: {
          obj: JSON.stringify(item)
        }
      })
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.pageNum = val
      this.queryChildrenProList(this.queryParams)
    }
  }
}
</script>

<style lang='less' scoped>
@media screen and (max-width: 768px) {
  #projectScore{
  }
}

@media screen and (min-width: 768px) {
  #projectScore {
  }
}

#projectScore {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .poster {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .container {
    width: 100%;

    .btnContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 5px;
      box-sizing: border-box;

      .btnBox1 {
        display: flex;
        align-items: center;

        .el-button {
          font-size: 18px;
        }
      }

      .btnBox2 {
        display: flex;
        align-items: center;

        .el-button {
          font-size: 15px;
        }
      }

      .el-button {
        padding: 10px 0;
        color: black;

        &:hover {
          color: red;
        }
      }
    }
  }
  ul {
    padding: 0;
  }
  .isShow {
    // background-color: rgb(10, 132, 202);
    color: red !important;
  }

  .empty-class {
    color: #e4e4e4;
  }
}

@media screen and (min-width: 768px) {
  .display-list {
    max-width: 1170px;
  }

  .classTitle {
    width: 1170px;
  }

  .display-item {
    div {
      width: 300px;
      height: 242px;
      overflow: hidden;
    }

    p {
      width: 300px;
    }
  }
}
@media screen and (max-width: 768px) {
  .display-list {
    width: 100%;
  }

  .classTitle {
    width: 100%;

    .title {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }
    
  .display-item {
    div {
      width: 170px;
      height: 140px;
      overflow: hidden;
    }
    p {
      width: 170px;
    }
  }
}

.classTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #ececec;

  .title {
    font-size: 30px;
    font-weight: bold;
    line-height: 56px;
    color: #3b3b3b;
    letter-spacing: 2px;
    margin: 6px 0;
  }

  .content {
    font-size: 12px;
    color: #a0a0a0;
  }
}

.display-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 36px 5px;
  box-sizing: border-box;

  .display-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6px 12px 6px;

    .imgBox {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #e4e7ed;
      box-sizing: border-box;

      img {
        transition: all .5s;
        width: 85%;
        // height: 100%;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    p {
      margin: 0;
      padding: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      font-weight: bold;
      background-color: #fff;
      text-align: center;
    }

    &:hover {
      cursor: pointer;

      .imgBox {
        border-bottom: none;
        border-color: #3a8ee6;
      }

      p {
        background: #3a8ee6;
        color: #fff;
      }
    }
  }
}
</style>

<template>
  <div class="downloadDetail">
    <div class="poster">
      <img src="../../assets/images/poster/gongcyj.png" alt="">
    </div>

    <div class="title" @click="$router.back(-1)">
      返回
      <mis-icon icon="back" font-size="18px"></mis-icon>
    </div>

    <div class="proInfo">
      <div class="imgContent">
        <img :src="baseUrl + detailInfo.img" alt="">
      </div>

      <div class="classInfo">
        <p>
          <span>方案名称：</span>
          <span>{{ detailInfo.name }}</span>
        </p>

        <p>
          <span>方案类别：</span>
          <span>{{ detailInfo.type }}</span>
        </p>

        <el-button
          size="mini" @click="download"
          v-if="detailInfo.pdf"
        >
          下载
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { proDetail } from "@/api/product";

export default {
  name: "downloadDetail",

  data() {
    const baseUrl = process.env.VUE_APP_BASE_API

    return {
      baseUrl: baseUrl,
      itemInfo: {},
      detailInfo: {}
    }
  },

  created() {
    this.itemInfo = JSON.parse(this.$route.query.obj)

    this.getDetailInfo()
  },

  methods: {
    getDetailInfo() {
      proDetail(this.itemInfo.id).then(res => {
        console.log('详情', res)
        this.detailInfo = res.data
      })
    },

    download() {
      window.open('http://106.14.183.48' + this.baseUrl + this.detailInfo.pdf)
    }
  }
}
</script>

<style scoped lang="less">
.downloadDetail {
  .poster {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .title {
    border-bottom: 1px solid #dcdfe6;
    text-align: right;
    padding: 10px 10px 10px 0;
    color: #0d6fb8;

    &:hover {
      cursor: pointer;
      color: #409eff;
    }
  }

  .proInfo {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .imgContent {
      border: 1px solid grey;
      padding: 3px;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .imgContent {
      max-width: 500px;
      max-height: 500px;
      overflow: hidden;
    }

    .classInfo {
      margin-left: 20px;
      width: 60%;
    }
  }
  @media screen and (max-width: 768px) {
    .proInfo {
      flex-direction: column;
      align-items: center;
    }

    .imgContent {
      width: 80%;
      height: auto;
      overflow: hidden;
    }
  }
}
</style>

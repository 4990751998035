import request from '@/utils/request'

// 获取微信支付二维码
export function getWxCode (data) {
  return request({
    url: '/pay/common/createCode',
    method: 'post',
    data: data
  })
}

// 获取微信支付二维码
// export function getResult (query) {
//   return request({
//     url: '/pay/common/notify',
//     method: 'get',
//     params: query
//   })
// }

// 获取支付宝支付二维码
export function getZfbCode (data) {
  return request({
    url: '/alipay/pay',
    method: 'post',
    data
  })
}

import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/user'
import vip from './module/vip'
import industry from './module/industry'
import news from './module/news'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isXs: false,
    device: 'PC',
    el_: null,
    api: process.env.VUE_APP_BASE_API,
    isLoginType: 0, // 0 未登录 1 手机号登录 2 wx登录
    recordFullPath: '',
    IEversion: false
  },
  mutations: {
    changeIsXs (state, payload) {
      state.isXs = payload
    },
    getDevice (state, payload) {
      state.device = payload
    },
    setAutoHeight (state, payload) {
      state.autoHeight = payload
    },
    changeLoginStatus (state, payload) {
      state.isLoginType = payload
    },
    setRecordFullPath (state, payload) {
      state.recordFullPath = payload
    },
    setIEversion (state, payload) {
      state.IEversion = payload
    }
  },
  actions: {
  },
  modules: {
    user,
    vip,
    industry,
    news
  }
})

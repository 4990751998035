<template>
  <div id="newsDetails">
    <breadcrumb :title="news.title" />
    <el-card class="box-card">
      <div slot="header" class="clearfix top">
        <h4>{{ news.title }}</h4>
        <p>
          <span>时间：{{ news.uptime }}</span>
          <span>浏览：{{ news.visit }}</span>
        </p>
      </div>
      <div class="maincontent" v-html="news.content"></div>
    </el-card>
  </div>
</template>

<script>
import breadcrumb from '@/common/components/Breadcrumb'
import { getConsulting, updateConsultingVisit } from '@/api/industryInfo'
import { getNews, updateNewsVisit } from '@/api/companyNews'
export default {
  name: 'newsDetails',
  components: {
    breadcrumb
  },
  data () {
    return {
      news: {
        title: '此新闻不存在'
      }
    }
  },
  methods: {
    async loadIndustryNews (id) {
      const res = await getConsulting(id)
      if (res.data) {
        this.news = res.data
        this.updateData(true)
      } else {
        const loading = this.$loading({
          lock: true,
          text: '此新闻不存在，即将返回行业资讯页面',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'industryInfo'
          })
          loading.close()
        }, 2000)
      }
    },
    async loadCompanyNews (id) {
      const res = await getNews(id)
      if (res.data) {
        this.news = res.data
        this.updateData(false)
      } else {
        const loading = this.$loading({
          lock: true,
          text: '此新闻不存在，即将返回新闻动态页面',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'newsList'
          })
          loading.close()
        }, 2000)
      }
    },
    // 修改浏览次数
    updateData (val) {
      val ? updateConsultingVisit(this.$route.query.id) : updateNewsVisit(this.$route.query.id)
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    const id = this.$route.query.id
    const module = this.$route.query.module
    if (module === 'industryInfo') this.loadIndustryNews(id)
    else if (module === 'companyNews') this.loadCompanyNews(id)
    else {
      const loading = this.$loading({
        lock: true,
        text: '此新闻不存在，即将返回首页',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        this.$router.push({
          name: 'newsList'
        })
        loading.close()
      }, 2000)
    }
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #newsDetails {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #newsDetails {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 807px) {
    .foot {
      img {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 807px) {
    #newsDetails {
      .foot {
        img {
          width: 807px;
          height: 494px;
        }
      }
    }
  }
  #newsDetails {
    .top {
      span {
        font-size: 12px;
        color: #888;
        margin-right: 20px;
        display: inline-block;
      }
    }
    // .main {
    //   p {
    //     margin: 20px 0;
    //     text-indent: 0pt;
    //     font-family: 微软雅黑;
    //     color: rgb(34, 34, 34);
    //     letter-spacing: 0.35pt;
    //     font-size: 8pt;
    //     background-image: initial;
    //     background-position: initial;
    //     background-size: initial;
    //     background-repeat: initial;
    //     background-attachment: initial;
    //     background-origin: initial;
    //     background-clip: initial;
    //   }
    // }
    .foot {
      width: 100%;
    }
  }
</style>

<template>
  <div class="download">
    <info
      title="下载中心"
      refAnimal="download"
      content1="您需要的资料都在这里～～～"
    />

    <div class="container">
      <div class="btnContent">
        <div class="btnBox1" v-for="(item, index) in menuBtnList">
          <el-button
            type="text"
            :class="{ isShow: selectedBtn === index }"
            @click="queryProList(item, index)"
          >
            {{ item.name }}
          </el-button>
          <div v-if="index + 1 !== menuBtnList.length" style="width: 1px;margin: 0 10px;height: 15px; background: #000;"></div>
        </div>
      </div>
    </div>

    <div class="display-list">
      <div class="display-item" v-for="item in dataList" @click="goDetail(item)">
        <!-- <div>
          <img :src="item.img" alt="">
        </div> -->
        <p class="fileItem">
          <mis-icon icon="Pdf" font-size="28px"></mis-icon>
          <span>{{ item.name }}</span>
        </p>
      </div>
    </div>

    <p class="empty-class" v-if="dataList && dataList.length <= 0" :image-size="200">暂无数据 ～～～</p>

    <el-button type="primary" round @click="$router.push({path: '/downloadCenter'})">更多<i class="el-icon-right el-icon--right"></i></el-button>
  </div>
</template>

<script>
import info from '@/components/index/Info'
import { proList } from "@/api/download";

export default {
  name: "download",

  components: { info },

  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      dataList: [],
      menuBtnList: [
        {
          name: '控制器与触摸屏类'
        },
        {
          name: '变送器与传感器类'
        },
        {
          name: '开关类'
        },
        {
          name: '阀门与驱动器类'
        },
        {
          name: '系统软件'
        }
      ],
      selectedBtn: 0,
    }
  },

  mounted() {

  },

  created() {
    this.queryProList(this.menuBtnList[0])
  },

  methods: {
    queryProList(item, index) {
      this.queryParams.type = item.name
      this.selectedBtn = index || 0
      proList(this.queryParams).then(res => {
        console.log('产品列表', res)
        this.dataList = res.rows
        this.total = res.total
      })
    },

    goDetail(item) {
      this.$router.push({
        path: '/downloadDetail',
        query: {
          obj: JSON.stringify(item)
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
@media screen and (min-width: 768px) {
  .display-list {
    width: 900px;
  }
  .display-item {
    div {
      width: 300px;
      height: 242px;
      overflow: hidden;
    }

    p {
      width: 300px;
    }
  }
}
@media screen and (max-width: 768px) {
  .display-list {
    width: 100%;
  }
  .display-item {
    div {
      width: 170px;
      height: 140px;
      overflow: hidden;
    }
    p {
      width: 170px;
    }
  }
}

.download {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-class {
    color: #e4e4e4;
  }
}

.container {
      width: 100%;

      .btnContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        box-sizing: border-box;

        .btnBox1 {
          display: flex;
          align-items: center;

          .el-button {
            font-size: 18px;
          }
        }

        .btnBox2 {
          display: flex;
          align-items: center;

          .el-button {
            font-size: 15px;
          }
        }

        .el-button {
          padding: 10px 0;
          color: black;

          &:hover {
            color: red;
          }
        }

        .isShow {
          // background-color: red;
          color: red;
        }
      }
    }

.display-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 5px;
  box-sizing: border-box;

  .display-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6px 12px 6px;

    img {
      transition: all .5s;
      width: 100%;
      height: 100%;

      &:hover {
        transform: scale(1.2);
      }
    }

    p {
      margin: 0;
      padding: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      font-weight: bold;
      background-color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &:hover {
      cursor: pointer;

      p {
        background: #3a8ee6;
        color: #fff;
      }
    }
  }
}
</style>

import request from '@/utils/request'

// 查询公司新闻动态列表
export function listNews (query) {
  return request({
    url: '/back/comnews/list',
    method: 'get',
    params: query
  })
}

// 查询公司新闻动态详细
export function getNews (id) {
  return request({
    url: '/back/comnews/' + id,
    method: 'get'
  })
}

// 新增公司新闻动态
export function addNews (data) {
  return request({
    url: '/back/comnews',
    method: 'post',
    data: data
  })
}

// 修改公司新闻动态
export function updateNews (data) {
  return request({
    url: '/back/comnews',
    method: 'put',
    data: data
  })
}

// 修改公司新闻动态浏览次数
export function updateNewsVisit (id) {
  return request({
    url: '/back/comnews/visitUp/' + id,
    method: 'get'
  })
}

// 删除公司新闻动态
export function delNews (id) {
  return request({
    url: '/back/comnews/' + id,
    method: 'delete'
  })
}

// 导出公司新闻动态
export function exportNews (query) {
  return request({
    url: '/back/comnews/export',
    method: 'get',
    params: query
  })
}

<template>
  <div class="course">
    <div>
      <img :src="img" alt="" v-handle-img="$store.state.api + src">
    </div>
    <h4>{{ title }}</h4>
    <ul>
      <li>
        <p>学习人数：{{ studyPeople }}</p>
        <p>类型：{{ lecturerName }}</p>
      </li>
      <li>
        <button class="animated" v-add-class="'swing'" @click="goto">参与试看</button>
      </li>
    </ul>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
export default {
  name: 'course',
  props: {
    src: { // 图片地址
      type: String
    },
    title: { // 课程标题
      type: String,
      default: '安评应急培训'
    },
    studyPeople: { // 学习人数
      type: Number,
      default: 4564
    },
    lecturerName: { // 类型
      type: String,
      default: '煤炭'
    },
    id: { // id
      type: String
    },
    cost: { // 价格
      type: Number
    }
  },
  data () {
    return {
      img: imgUrl
    }
  },
  methods: {
    goto () {
      if (this.cost > 0) {
        this.$router.push({
          name: 'courseDeals',
          query: {
            id: this.id
          }
        })
      } else {
        this.$router.push({
          name: 'videoShow',
          query: {
            id: this.id
          }
        })
      }
    }
  }
}
</script>

<style lang='less' scoped>
  .course {
    width: 266px;
    height: 270px;
    overflow: hidden;
    border: 1px solid #e6e6e6;
    margin: 0;
    button {
      padding: 7px;
      border-radius: 3px;
      background-color: rgb(21, 180, 74);
      border: 0;
      color: #fff;
      cursor: pointer;
      outline: none;
    }
    div {
      width: 264px;
      height: 169px;
      img {
        width: 100%;
        height: 100%;
        transition: transform 0.2s linear;
      }
      &:hover img{
        transform: scale(1.05)
      }
    }
    h4 {
      color: #1c1c1c;
      font-size: 16px;
      padding: 10px 10px 5px;
      margin: 0;
      font-weight: 400;
    }
    ul {
      display: flex;
      margin: 0;
      padding: 0 10px;
      li:first-child {
        width: 60%;
        margin: 0;
      }
      li:last-child {
        width: 40%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        font-size: 16px;
        color: #a0a0a0;
        line-height: 30px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>

<template>
  <div id="forgetPassword">
    <el-card>
      <div slot="header" class="clearfix">
        <span>重置密码</span>
      </div>
      <el-form ref="ruleForm" class="demo-ruleForm" :rules="rules" :model="userForm">
        <el-form-item prop="phonenumber">
          <el-input prefix-icon="el-icon-user" v-model="userForm.phonenumber" placeholder="手机号码">
            <template slot="append">
              <el-button class="btn"
              v-if="!isLoading"
              @click="handleSendCode"
              :disabled="!isAbleClick"
              :style="{ backgroundColor: !isAbleClick ? 'rgb(144,147,153)' :'#409EFF', color: '#fff' }">
                发送验证码
              </el-button>
              <el-button class="btn" v-else disabled>{{ count }}S</el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input prefix-icon="el-icon-c-scale-to-original" v-model="userForm.code" placeholder="手机验证码" :suffix-icon="flag ? 'el-icon-check' : 'el-icon-close'">
          </el-input>
        </el-form-item>
        <el-form-item prop="password1">
          <el-input prefix-icon="el-icon-lock" type="password" v-model="userForm.password1" placeholder="设置密码（6-18位字符）"></el-input>
        </el-form-item>
        <el-form-item prop="password2">
          <el-input prefix-icon="el-icon-lock" type="password" v-model="userForm.password2" placeholder="确认密码（6-18位字符）"></el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-checkbox v-model="checked">
            我已接收并同意《<a href="">用户服务条款</a>》
        </el-checkbox>
        <div class="footer-control">
          <el-button type="primary" @click="onResetPassword" :disabled="!checked">{{ isLoadingResiter ? '重置中...' : '重 置' }}</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { loadSms, checkCode } from '@/api/sms'
import { forgetPwd } from '@/api/user'
export default {
  name: 'forgetPassword',
  data () {
    const checkPassword = (rule, value, callback) => {
      if (this.userForm.password1 === value) {
        callback()
      } else {
        callback(new Error('两次输入密码不一致'))
      }
    }
    const checkCode = (rule, value, callback) => {
      if (!this.flag) {
        if (value.length === 4) {
          if (this.isSendSms) {
            this.checkMobileCode()
          }
          callback()
        } else {
          callback(new Error('请输入4位有效验证码'))
        }
      } else callback()
    }
    return {
      userForm: {
        phonenumber: '', // 手机号
        code: '', // 验证码
        password1: '', // 密码
        password2: '' // 确定密码
      },
      rules: {
        phonenumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[123456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' },
          { validator: checkCode, trigger: 'blur' }
        ],
        password1: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { max: 18, min: 6, message: '请输入6-18位有效密码', trigger: 'blur' }
        ],
        password2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      },
      checked: false,
      isLoading: false, // 验证码等待时间
      count: 60, // 等待倒计时
      isAbleClick: false, // 是否可以点击
      uuid: '',
      isLoadingResiter: false, // 注册等待时间
      isSendSms: false, // 是否已经发送验证码
      flag: false
    }
  },
  methods: {
    async checkMobileCode () {
      const res = await checkCode({
        uuid: this.uuid,
        code: parseInt(this.userForm.code)
      })
      if (res.code === 200) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    async resetPassword () {
      try {
        this.isLoadingResiter = true
        const res = await forgetPwd({
          phonenumber: this.userForm.phonenumber,
          code: this.userForm.code,
          password: this.userForm.password2
        })
        console.log(res)
        if (res.code === 200) {
          this.$message.success('重置成功，请登录')
          this.$router.push({
            name: 'login'
          })
          this.$destroy()
        } else {
          this.$message.error('重置失败')
        }
        this.isLoadingResiter = false
      } catch (err) {
        this.$message.error('未知错误，请联系管理员')
        this.isLoadingResiter = false
      }
    },
    onResetPassword () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.flag) {
            this.resetPassword()
          } else {
            this.$message.error('验证码错误，请输入正确的验证码')
          }
        } else {
          return false
        }
      })
    },
    async handleSendCode () {
      this.isSendSms = true
      this.isLoading = true
      this.count--
      const timer = setInterval(() => {
        this.count--
        if (this.count <= 0) {
          this.isLoading = false
          this.count = 60
          clearInterval(timer)
        }
      }, 1000)
      try {
        const res = await loadSms({
          phoneNumbers: this.userForm.phonenumber,
          templateCode: 'SMS_214620011'
        })
        if (res.code === 200) {
          if (res.res === 'OK') {
            this.$message.success('发送成功')
            this.uuid = res.uuid
          } else {
            this.$message.error('验证码发送频繁，请等待半小时')
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  watch: {
    userForm: {
      handler (newVal) {
        // console.log(newVal.phonenumber.trim().match(/^1[123456789]\d{9}$/))
        if (!this.isLoading) {
          if (newVal.phonenumber.trim().match(/^1[123456789]\d{9}$/)) {
            this.isAbleClick = true
          } else {
            this.isAbleClick = false
          }
        }
      },
      deep: true
    }
  },
  created () {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('wxUserInfo')
  }
}
</script>

<style lang='less' scoped>
  @media screen and (min-width: 768px) {
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-card {
      width: 500px;
    }
  }
  @media screen and (max-width: 767px) {
    .footer-control {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
    }
    .el-card {
      width: 90%;
    }
  }
  #forgetPassword {
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/ .el-input-group__append {
      height: 100%;
      padding: 0;
      margin: 0;
      border: none;
    }
    .btn {
      width: 80px;
      height: 40px;
      line-height: 40px;
      outline: none;
      margin: 0;
      border: 0;
      padding: 0;
      border-radius: 1px;
      text-align: center;
    }
    /deep/ .el-icon-close {
      font-size: 20px;
    }
    /deep/ .el-icon-check {
      color: green;
      font-size: 20px;
    }
  }
  .el-card {
    margin: 50px auto 100px;
  }
  .logo {
    background-color: orange;
    text-align: center;
    margin-bottom: 15px;

    img {
      vertical-align: top;
    }
  }
</style>

<template>
  <div class="infoShow">
    <div v-if="unfold" class="unfold">
      <span>
        <i></i>
      </span>
      <router-link :to="{ name: 'newsDetails', query: {
          id: id,
          module: 'industryInfo'
        }}">
        <img :src="img" alt="安评应急培训" v-handle-img="$store.state.api + src">
      </router-link>
      <div>
        <h5>{{ title }}</h5>
        <p>{{ summary }}</p>
      </div>
    </div>
    <div v-else class="shrink">
      <span>
        <i></i>
      </span>
      <router-link :to="{ name: 'newsDetails', query: {
          id: id,
          module: 'industryInfo'
        }}">
        {{ summary }}
      </router-link>
      <span>{{ date }}</span>
    </div>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
export default {
  name: 'infoShow',
  props: {
    title: { // 标题
      type: String,
      default: '安评应急培训安评应急培训安评应急培训'
    },
    summary: { // 内容
      type: String,
      default: '安评应急培训安评应急培训安评应急培训安评应急培训安评应急培训'
    },
    src: { // 图片连接
      type: String
    },
    date: { // 日期
      type: String,
      default: '03-26'
    },
    unfold: { // 展开
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'asd'
    }
  },
  data () {
    return {
      img: imgUrl
    }
  }
}
</script>

<style lang='less' scoped>
  .infoShow {
    .shrink {
      display: flex;
      justify-content: space-between;
      line-height: 37px;
      height: 37px;
      > span:nth-of-type(1) {
        display: inline-block;
        width: 20px;
        line-height: 37px;
        i {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 1px solid red;
        }
      }
      > a {
        display: inline-block;
        width: calc(100% - 70px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > span:nth-of-type(2) {
        display: inline-block;
        width: 45px;
        color: #999;
      }
    }
    .unfold {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span {
        display: inline-block;
        width: 20px;
        line-height: 75px;
        i {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 1px solid red;
        }
      }
      a {
        display: inline-block;
        width: 40%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      div {
        width: 50%;
        display: inline-block;
        h5 {
          font-size: 16px;
          margin: 10px 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
        }
        p {
          line-height: 18px;
          height: 36px;
          color: #666;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
  }
  .infoShow:hover a, .infoShow:hover h5 {
    color: red;
  }
  .infoShow:hover i {
    background-color: red;
  }
</style>

import request from '@/utils/request'

// 查询课程信息列表
export function listCurriculum (query) {
  return request({
    url: '/back/curriculum/list',
    method: 'get',
    params: query
  })
}

// 查询课程信息详细
export function getCurriculum (id) {
  return request({
    url: '/back/curriculum/' + id,
    method: 'get'
  })
}

// 查询课程完整URL
export function getVideoUrl (id) {
  return request({
    url: '/back/curriculum/getVideoUrl/' + id,
    method: 'get'
  })
}

// 查询课程试看URL
export function getShortVideoUrl (id) {
  return request({
    url: '/back/curriculum/getShortVideoUrl/' + id,
    method: 'get'
  })
}

// 新增课程信息
export function addCurriculum (data) {
  return request({
    url: '/back/curriculum',
    method: 'post',
    data: data
  })
}

// 修改课程信息
export function updateCurriculum (data) {
  return request({
    url: '/back/curriculum',
    method: 'put',
    data: data
  })
}

// 新增访问次数+1信息
export function updateVisit (id) {
  return request({
    url: '/back/curriculum/visitUp/' + id,
    method: 'get'
  })
}

// 新增订购次数+1信息
export function updateOrder (id) {
  return request({
    url: '/back/curriculum/orderUp/' + id,
    method: 'get'
  })
}

// 删除课程信息
export function delCurriculum (id) {
  return request({
    url: '/back/curriculum/' + id,
    method: 'delete'
  })
}

// 导出课程信息
export function exportCurriculum (query) {
  return request({
    url: '/back/curriculum/export',
    method: 'get',
    params: query
  })
}

<template>
  <div id="app" class="wraper" ref="box">
    <keep-alive :max="10">  <!-- 最大缓存数10，条件判断要落实到router-view上，在keep-alive上会失效 -->
      <router-view v-if="$route.meta.isKeepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.isKeepAlive"></router-view>
    <el-backtop
      target=".wraper"
      :bottom="20"
      :right="20"
      :visibility-height="100"
      style="z-index: 10000;"
    >
      <!-- 返回顶部 -->
      <div
        style="{
          height: 100%;
          width: 100%;
          background-color: #f2f5f6;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          border-radius: 50%;
          line-height: 40px;
          color: #1989fa;
        }"
      >
        UP
      </div>
    </el-backtop>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'app',
  methods: {
    ...mapActions(['getCourseList', 'getIndustryList', 'getNewsList']),
    getClientWidth () {
      // 判断屏幕是否是最小屏xs
      if (window.innerWidth < 768) {
        this.$store.commit('changeIsXs', true)
      } else {
        this.$store.commit('changeIsXs', false)
      }
    },
    loadPublicData () {
      if (this.$route.name === 'index') {
        // 请求公共课程数据
        this.getCourseList({
          pageSize: 4,
          pageNum: 1,
          name: '',
          type: ''
        })
        // 请求行业资讯数据
        this.getIndustryList({
          pageSize: 20,
          pageNum: 1,
          title: ''
        })
        // 请求公共新闻数据
        this.getNewsList({
          pageSize: 3,
          pageNum: 1,
          title: ''
        })
      } else if (this.$route.name === 'vip') {
        this.getCourseList({
          pageSize: 10,
          pageNum: 1,
          name: '',
          type: ''
        })
      } else if (this.$route.name === 'newsList') {
        // 请求公共新闻数据
        this.getNewsList({
          pageSize: 10,
          pageNum: 1,
          title: ''
        })
      } else if (this.$route.name === 'industryInfo') {
        // 请求行业资讯数据
        this.getIndustryList({
          pageSize: 10,
          pageNum: 1,
          title: ''
        })
      }
    },
    IEVERSION () {
      const userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
      const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 // 判断是否IE<11浏览器
      const isEdge = userAgent.indexOf('Edge') > -1 && !isIE // 判断是否IE的Edge浏览器
      const isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1
      if (isIE) {
        const reIE = new RegExp('MSIE (\\d+\\.\\d+)')
        reIE.test(userAgent)
        // eslint-disable-next-line
        const fIEVersion = parseFloat(RegExp['$1'])
        console.log(fIEVersion)
        if (fIEVersion === 7) {
          return 7
        } else if (fIEVersion === 8) {
          return 8
        } else if (fIEVersion === 9) {
          return 9
        } else if (fIEVersion === 10) {
          return 10
        } else {
          return 6 // IE版本<=7
        }
      } else if (isEdge) {
        return 'edge' // edge
      } else if (isIE11) {
        return 11 // IE11
      } else {
        return -1 // 不是ie浏览器
      }
    }
  },
  created () {
    if (this.IEVERSION() === 11 || this.IEVERSION() === 10) {
      alert('浏览器版本低！为了良好的体验感，推荐使用谷歌浏览器（chrome）或者360安全浏览器极速模式。')
      this.$store.commit('setIEversion', this.IEVERSION())
    }
    // this.loadPublicData()

    // if (this.$route.name !== 'login' && this.$route.name !== 'register') {
    //   // 获取登录状态
    //   const token = localStorage.getItem('token')
    //   const user = localStorage.getItem('user')
    //   const wxUserInfo = localStorage.getItem('wxUserInfo')
    //   if (token && user) { // 手机登录
    //     this.$store.commit('changeLoginStatus', 1)
    //   } else if (wxUserInfo) { // 微信登录
    //     this.$store.commit('changeLoginStatus', 2)
    //   } else {
    //     this.$store.commit('changeLoginStatus', 0)
    //   }
    // }

    // 检测使用设备
    const device = /Android|webOS|iPhone|iPod|BlackBerry|iPad|SymbianOS|Windows Phone/i.test(
      window.navigator.userAgent
    )
      ? 'MOBILE'
      : 'PC'
    this.$store.commit('getDevice', device)
    this.getClientWidth()
    window.addEventListener('resize', () => {
      // 当文档宽度发生变化时，window.innerWidth获取浏览器窗口有效宽度
      this.getClientWidth()
    })
  },
  mounted () {
    const that = this
    that.$store.state.el_ = this.$refs.box
    // 滚动添加动画
    // if (this.$route.name === 'index') {
    //   this.$refs.box.addEventListener('scroll', () => {
    //     const scrollTop = this.$refs.box.scrollTop
    //     const documentHeight = document.body.clientHeight
    //     const animateBanner = 0
    //     const animateCompany = this.$refs.box.querySelector('.animateBanner').clientHeight + animateBanner
    //     const animateAdvantage = this.$refs.box.querySelector('.animateCompany').clientHeight + animateCompany
    //     const animatePartnerAcademy = this.$refs.box.querySelector('.animateAdvantage').clientHeight + animateAdvantage
    //     const animateLearnPlaform = this.$refs.box.querySelector('.animatePartnerAcademy').clientHeight + animatePartnerAcademy
    //     const animateTrainShow = this.$refs.box.querySelector('.animateLearnPlaform').clientHeight + animateLearnPlaform
    //     const animateNewList = this.$refs.box.querySelector('.animateTrainShow').clientHeight + animateTrainShow
    //     if (animateBanner >= scrollTop && animateBanner <= scrollTop + documentHeight) {
    //       this.$refs.box.querySelector('.animateBanner').classList.add('fadeInUpBig')
    //     } else if (animateCompany >= scrollTop && animateCompany <= scrollTop + documentHeight) {
    //       this.$refs.box.querySelector('.animateCompany').classList.add('fadeInUpBig')
    //     } else if (animateAdvantage >= scrollTop && animateAdvantage <= scrollTop + documentHeight) {
    //       this.$refs.box.querySelector('.animateAdvantage').classList.add('fadeInUpBig')
    //     } else if (animatePartnerAcademy >= scrollTop && animatePartnerAcademy <= scrollTop + documentHeight) {
    //       this.$refs.box.querySelector('.animatePartnerAcademy').classList.add('fadeInUpBig')
    //     } else if (animateLearnPlaform >= scrollTop && animateLearnPlaform <= scrollTop + documentHeight) {
    //       this.$refs.box.querySelector('.animateLearnPlaform').classList.add('fadeInUpBig')
    //     } else if (animateTrainShow >= scrollTop && animateTrainShow <= scrollTop + documentHeight) {
    //       this.$refs.box.querySelector('.animateTrainShow').classList.add('fadeInUpBig')
    //     } else if (animateNewList >= scrollTop && animateNewList <= scrollTop + documentHeight) {
    //       this.$refs.box.querySelector('.animateNewList').classList.add('fadeInUpBig')
    //     }
    //   })
    // }
  },
  watch: {
    $route: {
      handler () {
        // this.loadPublicData()
        // // 获取登录状态
        // const token = localStorage.getItem('token')
        // const user = localStorage.getItem('user')
        // const wxUserInfo = localStorage.getItem('wxUserInfo')
        // if (token && user) { // 手机登录
        //   this.$store.commit('changeLoginStatus', 1)
        //   localStorage.removeItem('wxUserInfo')
        // } else if (wxUserInfo) { // 微信登录
        //   this.$store.commit('changeLoginStatus', 2)
        // } else {
        //   this.$store.commit('changeLoginStatus', 0)
        // }
      },
      deep: true
    }
  }
}
</script>

<style lang="less">
#app {
  height: 100%;
  overflow-y: scroll;
}
.wraper {
  /* 返回顶部 */
  /* 溢出高度自动显示滚动条 */
  overflow-x: hidden;
}
</style>

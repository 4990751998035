<template>
  <div id="more">
    等待更新
  </div>
</template>

<script>
export default {
  name: 'more',
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  }
}
</script>

<style lang='less' scoped>
  #more {

  }
</style>

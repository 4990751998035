<template>
    <div id="loginContainer">
      <el-card>
      <div slot="header" class="clearfix cardTop">
        <div class="t1">用户登录</div>
        <div class="t2">
          <div class="wx">
            <img src="@/assets/images/wx.png" alt="">
          </div>
          <div>
            <button @click="handleWxLogin">微信登录</button>
          </div>
        </div>
      </div>
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="demo-loginForm">
        <el-form-item prop="username">
          <el-input prefix-icon="el-icon-user" v-model="loginForm.username" auto-complete="off" placeholder="手机号码">
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input prefix-icon="el-icon-lock" @keydown.enter.native="handleLogin" auto-complete="off" type="password" v-model="loginForm.password" placeholder="输入密码（6-16位字符）">
            <template slot="append"><a href="#" @click="handleForget">忘记密码</a></template>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-checkbox v-model="loginForm.agreeServiceClause">
            我已接收并同意《<a href="/rest/help?id=serviceClause">用户服务条款</a>》
        </el-checkbox>
        <div class="footer-control">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="handleLogin" :disabled="!loginForm.agreeServiceClause">
            <span v-if="!loading">登 录</span>
            <span v-else>登 录 中...</span>
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
// import axios from 'axios'
import { login, getInfo, getCodeImg } from '@/api/user'
export default {
  name: 'register',
  data () {
    return {
      codeUrl: '',
      cookiePassword: '',
      loading: false,
      loginForm: {
        username: '',
        password: '',
        agreeServiceClause: false,
        code: '',
        uuid: ''
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '用户名不能为空' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '密码不能为空' }
        ]
      },
      checked: false, // 判断是否同意协议
      fullPath: '',
      isWxLogin: false
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog () { // 关闭dialog
      this.$emit('closeDialog', false)
    },
    async handleForget () { // 处理忘记密码的点击
      this.$router.push({
        name: 'forgetPassword'
      })
    },
    handleSendCode () { // 处理发送验证码的点击
      this.$message.error('忘记密码，暂未实现，请等待部署')
    },
    handleLogin () { // 登录
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          if (this.loginForm.agreeServiceClause) {
            this.loading = true
            try {
              const { username, password, code, uuid } = this.loginForm
              const res = await login({
                username: username.trim(),
                password,
                code,
                uuid
              })
              localStorage.setItem('token', res.token)
              this.loadInfo()
            } catch (err) {
              this.loading = false
            }
          } else {
            this.$message.warning('请同意用户服务条款')
          }
        }
      })
    },
    async loadInfo () { // 获取用户信息
      const res = await getInfo()
      localStorage.setItem('user', JSON.stringify(res.data))
      this.loading = false
      if (this.fullPath === '/register' || this.fullPath === '/forgetPassword') {
        location.href = '/'
      } else {
        location.href = this.fullPath
      }
    },
    getCode () { // 获取验证码
      getCodeImg().then(res => {
        this.codeUrl = 'data:image/gif;base64,' + res.img
        this.loginForm.uuid = res.uuid
      })
    },
    // 处理微信登录
    handleWxLogin () {
      // this.isWxLogin = true
      window.open('http://www.apyjpx.com:89/wx/login', '_blank')
      // location.href = 'http://www.apyjpx.com:89/wx/login'
    }
  },
  // 组件内钩子
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('setRecordFullPath', from.fullPath)
      vm.fullPath = from.fullPath
    })
  },
  created () {
    this.getCode()
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('wxUserInfo')
  }
}
</script>

<style lang='less' scoped>
  @media screen and (min-width: 768px) {
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-card {
      width: 500px;
    }
  }
  @media screen and (max-width: 767px) {
    .footer-control {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
    }
    .el-card {
      width: 90%;
    }
  }
  #loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .iframe {
      width: 100%;
      height: 400px;
    }
  }
  .el-card {
    margin: 50px auto 100px;
    .cardTop {
      line-height: 36px;
      .t1 {
        float: left;
      }
      .t2 {
        float: right;
        display: flex;
        justify-content: flex-start;
        .wx {
          width: 36px;
          height: 36px;
          img {
            vertical-align: middle;
          }
        }
        button {
          cursor: pointer;
          outline: none;
          border: none;
          background-color: #fff;
        }
      }
    }
  }
  .logo {
    background-color: orange;
    text-align: center;
    margin-bottom: 15px;

    img {
      vertical-align: top;
    }
  }
  .demo-loginForm /deep/ .el-input-group__append {
    padding: 5px;
    cursor: pointer;
  }
</style>

import { listNews } from '@/api/companyNews'
import { message } from 'element-ui'
export default {
  state: {
    newsList: null,
    total: 0
  },
  mutations: {
    SET_NEWSLIST (state, payload) {
      state.newsList = payload
    },
    SET_TOTAL (state, payload) {
      state.total = payload
    }
  },
  actions: {
    // 等待获取新闻列表
    // async getNewsList (context, payload) {
    //   try {
    //     const res = await listNews({
    //       pageSize: payload.pageSize,
    //       pageNum: payload.pageNum,
    //       name: payload.search
    //     })
    //     if (res.code === 200) {
    //       context.commit('SET_NEWSLIST', res.rows)
    //       context.commit('SET_TOTAL', res.total)
    //     }
    //   } catch (error) {
    //     message.error('未知错误，请重新尝试')
    //   }
    // }
  },
  getters: {}
}

<template>
  <div id="dataView">
    <breadcrumb />
    <!-- <embed :src="$store.state.api + src" :height="autoHeight" type=""> -->
    <!-- <iframe :src="$store.state.api + src" :height="autoHeight" frameborder="0"></iframe> -->
    <!-- <embed :src="$store.state.api + src" :height="autoHeight" type=""> -->
    <div class="center">
      <div class="contor">
        <el-button type="primary" size="mini" style="cursor:pointer" @click="prev">上一页
        </el-button>
        <span>
          <span v-text="page_num" /> /
          <span v-text="page_count" />
        </span>
        <el-button type="primary" size="mini" style="cursor:pointer" @click="next">下一页
        </el-button>
        <el-button v-if="$store.state.isLoginType > 0" type="primary" size="mini" style="cursor:pointer" @click="download">下载
        </el-button>
        <!-- <el-button color="#c9431f" type="primary" size="mini" style="cursor:pointer" @click="addscale">
          放大</el-button>
        <el-button color="#c9431f" type="primary" size="mini" style="cursor:pointer" @click="minus">缩小
        </el-button> -->
      </div>
      <canvas id="the-canvas" class="canvasstyle" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import PDFJS from 'pdfjs-dist'
  import breadcrumb from '@/common/components/Breadcrumb'
  export default {
    name: 'dataView',
    components: {
      breadcrumb
    },
    data() {
      return {
        autoHeight: 500,
        src: '',
        pdfUrl: "",
        pdfDoc: null, // pdfjs 生成的对象
        pageNum: 1, //
        pageRendering: false,
        pageNumPending: null,
        scale: 1.7, // 放大倍数
        page_num: 0, // 当前页数
        page_count: 0, // 总页数
        maxscale: 2.2, // 最大放大倍数
        minscale: 1.2 // 最小放大倍数
      }
    },
    computed: {
      ctx() {
        const id = document.getElementById("the-canvas");
        return id.getContext("2d");
      }
    },
    methods: {
      onClickLeft() {
        window.history.go(-1);
      },
      init(pdfUrl) {
        let _this = this;
        // 初始化pdf
        PDFJS.getDocument(pdfUrl).then(function (pdfDoc_) {
          _this.pdfDoc = pdfDoc_;
          _this.page_count = _this.pdfDoc.numPages;
          _this.renderPage(_this.pageNum);
        });
      },
      renderPage(num) {
        // 渲染pdf
        const vm = this;
        this.pageRendering = true;
        const canvas = document.getElementById("the-canvas");
        // Using promise to fetch the page
        this.pdfDoc.getPage(num).then(function (page) {
          var viewport = page.getViewport(vm.scale);
          // alert(vm.canvas.height)
          canvas.height = viewport.height;
          vm.pdfWidth = canvas.width = viewport.width;
          // Render PDF page into canvas context
          var renderContext = {
            canvasContext: vm.ctx,
            viewport: viewport
          };
          var renderTask = page.render(renderContext);
          // Wait for rendering to finish
          renderTask.promise.then(function () {
            vm.pageRendering = false;
            if (vm.pageNumPending !== null) {
              // New page rendering is pending
              vm.renderPage(vm.pageNumPending);
              vm.pageNumPending = null;
            }
          });
        });
        vm.page_num = vm.pageNum;
      },
      // addscale() {
      //   // 放大
      //   if (this.scale >= this.maxscale) {
      //     return;
      //   }
      //   this.scale += 0.5;
      //   this.queueRenderPage(this.pageNum);
      // },
      // minus() {
      //   // 缩小
      //   if (this.scale <= this.minscale) {
      //     return;
      //   }
      //   this.scale -= 0.5;
      //   this.queueRenderPage(this.pageNum);
      // },
      prev() {
        // 上一页
        const vm = this;
        if (vm.pageNum <= 1) {
          return;
        }
        vm.pageNum--;
        vm.queueRenderPage(vm.pageNum);
      },
      next() {
        // 下一页
        const vm = this;
        if (vm.pageNum >= vm.page_count) {
          return;
        }
        vm.pageNum++;
        vm.queueRenderPage(vm.pageNum);
      },
      queueRenderPage(num) {
        if (this.pageRendering) {
          this.pageNumPending = num;
        } else {
          this.renderPage(num);
        }
      },
      // 下载
      download () {
        const a = document.createElement('a')
        a.download = true
        a.href = this.$store.state.api + this.src
        a.click()
      }
    },
    created() {
      this.autoHeight = window.innerHeight * 0.9
      this.src = this.$route.query.id
    },
    mounted() {
      this.$nextTick(function () {
        this.$store.state.el_.scrollTop = 0
      })
      //解决字体问题
      const CMAP_URL = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.943/cmaps/";
      let obj = {};
      obj.url = this.$store.state.api + this.src; //pdf文件地址，根据情景自行修改
      obj.cMapUrl = CMAP_URL;
      obj.cMapPacked = true;
      this.init(obj);
    }
  }

</script>

<style lang="less" scoped>
  @media screen and (max-width: 1200px) {
    #dataView {
      width: 100%;
    }
  }

  @media screen and (min-width: 1200px) {
    #dataView {
      width: 1200px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 768px) {
    #dataView {}
  }

  #dataView {
    iframe {
      width: 100%;
    }

    embed {
      width: 100%;
      height: 600px;
    }
  }

  #the-canvas {
    width: 100%;
  }

</style>

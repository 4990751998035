<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <vue-cropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType"
          :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixed="option.fixed"
          :fixedNumber="option.fixedNumber" :full="option.full" :fixedBox="option.fixedBox" :canMove="option.canMove"
          :canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox"
          :height="option.height" :infoTrue="option.infoTrue" :maxImgSize="option.maxImgSize" :enlarge="option.enlarge"
          :mode="option.mode" @realTime="realTime" @imgLoad="imgLoad">
        </vue-cropper>
      </div>
      <!--底部操作工具按钮-->
      <div class="footer-btn">
        <div class="scope-btn">
          <label class="btn" for="uploads">选择头像</label>
          <input type="file" id="uploads" style="position:absolute; clip:rect(0 0 0 0);"
            accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)">
          <el-button size="mini" type="danger" plain @click="rotateLeft">↺ 左旋转</el-button>
          <el-button size="mini" type="danger" plain @click="rotateRight">↻ 右旋转</el-button>
        </div>
      </div>
    </div>
    <div class="right">
      <!--预览效果图-->
      <div class="show-preview">
        <div :style="previews.div" class="preview">
          <img :src="previews.url" :style="previews.img">
        </div>
      </div>
      <div>
        <el-button type="success" @click="uploadImg">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VueCropper
} from 'vue-cropper'
import {
  uploadAvatar,
  updateInfo
} from '@/api/user'
import bus from '@/utils/eventBus'
export default {
  name: 'CropperImage',
  components: {
    VueCropper
  },
  data () {
    return {
      name: this.Name,
      previews: {},
      option: {
        img: '', // 裁剪图片的地址
        // img: this.$store.state.api + this.$store.state.user.userInfo.avatar, // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
        outputType: 'jpeg', // 裁剪生成图片的格式（jpeg || png || webp）
        info: true, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: false, // false按原比例裁切图片，不失真
        fixedBox: true, // 固定截图框大小，不允许改变
        canMove: false, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        height: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, // 限制图片最大宽度和高度
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: '' // 图片默认渲染方式
      },
      userInfo: {}
    }
  },
  methods: {
    // 初始化函数
    imgLoad (msg) {
      console.log('工具初始化函数=====' + msg)
    },
    // 向左旋转
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    // 向右旋转
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    // 实时预览函数
    realTime (data) {
      this.previews = data
    },
    // 选择图片
    selectImg (e) {
      const file = e.target.files[0]
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: '图片类型要求：jpeg、jpg、png',
          type: 'error'
        })
        return false
      }
      // 转化为blob
      const reader = new FileReader()
      reader.onload = (e) => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
      }
      // 转化为base64
      reader.readAsDataURL(file)
    },
    // 上传图片
    uploadImg (type) {
      this.$refs.cropper.getCropBlob(async data => {
        const formData = new FormData()
        formData.append('avatarfile', data)
        const res = await uploadAvatar(formData)
        if (res.code === 200) {
          this.loadUpdateInfo(res.imgUrl)
        }
      })
    },
    async loadUpdateInfo (imgUrl) {
      try {
        const user = JSON.parse(localStorage.getItem('user'))
        user.avatar = imgUrl
        const res = await updateInfo(user)
        if (res.code === 200) {
          bus.$emit('avatar', imgUrl)
          localStorage.setItem('user', JSON.stringify(user))
          this.$message.success('头像修改成功')
        }
      } catch (err) {}
    }
  },
  created () {
    if (this.$store.state.isLoginType === 1) {
      this.userInfo = this.$store.state.user.userInfo
      console.log(this.userInfo)
    } else if (this.$store.state.isLoginType === 2) {
      this.userInfo = this.$store.state.user.wxUserInfo
      console.log(this.userInfo)
    } else {
      this.userInfo = null
      console.log('本地用户信息获取错误，请重新登录')
    }
  }
}

</script>

<style scoped lang="less">
  .cropper-content {
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    display: -webkit-flex;
    justify-content: flex-end;

    .cropper-box {
      flex: 1;
      width: 100%;

      .cropper {
        width: auto;
        height: 300px;
      }
    }

    .right {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
    }

    .show-preview {
      width: 200px;
      height: 200px;
      overflow: hidden;
      border-radius: 50%;

      .preview {
        width: 100%;
        height: 100%;
        border: 1px solid #67c23a;
        background: #cccccc;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .footer-btn {
    margin-top: 30px;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;

    .scope-btn {
      margin: 0 auto;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      padding-right: 10px;
    }

    .upload-btn {
      flex: 1;
      -webkit-flex: 1;
      display: flex;
      display: -webkit-flex;
      justify-content: center;
    }

    .btn {
      outline: none;
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      -webkit-appearance: none;
      text-align: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: 0;
      -webkit-transition: .1s;
      transition: .1s;
      font-weight: 500;
      padding: 8px 15px;
      font-size: 12px;
      border-radius: 3px;
      color: #fff;
      background-color: #409EFF;
      border-color: #409EFF;
      margin-right: 10px;
    }
  }

</style>

<template>
  <div>
    <keep-alive :max="10">  <!-- 最大缓存数10，条件判断要落实到router-view上，在keep-alive上会失效 -->
      <router-view v-if="$route.meta.isKeepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.isKeepAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'index',
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  }
}
</script>

<style lang='less' scoped>

</style>

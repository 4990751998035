<template>
  <div id="about">
    <div>
      <div v-if="!$route.meta.isBanner" class="poster">
        <img src="../../assets/images/poster/aboutUs.png" alt="">
      </div>

      <div class="container">
        <div class="btnContent">
          <el-button :class="{ isShow: $route.path.split('/')[2] === '' }" @click="$router.push({name: 'companyIntroduct'})">企业介绍</el-button>
          <el-button :class="{ isShow: $route.path.split('/')[2] === 'contact' }" @click="$router.push({name: 'contact'})">联系我们</el-button>
        </div>
        <keep-alive :max="10">  <!-- 最大缓存数10，条件判断要落实到router-view上，在keep-alive上会失效 -->
          <router-view v-if="$route.meta.isKeepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.isKeepAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    #about .container {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    #about .container {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 768px) {
    #about {

    }
  }
  #about {
    text-align: center;

    .poster {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .container {
      // margin-top: 20px;

      .btnContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        box-sizing: border-box;

        .el-button {
          margin: 5px;
        }
      }
    }
    ul {
      padding: 0;
    }
    .isShow {
      background-color: rgb(10, 132, 202);
      color: #fff;
    }
  }
</style>

<template>
  <div>
    <div class="Dplayer">
      <!-- 顶部面包屑导航 -->
    <breadcrumb :title="title" />
    <div class="container">
      <!-- dplayer视频播放器 -->
      <div class="dplayer" ref="dplayeraaa">
        <div class="dplayerContainer">
          <!-- dplayer挂载元素 -->
          <div id="dplayer" ref="dplayer" :style="{ height: autoHeight }"></div>
          <!-- 当PC端时，显示播放按钮 -->
          <div v-if="$store.state.device === 'PC'">
            <div class="start-btn" v-show="isShowBtn" @click="onStart">
              <i class="el-icon-caret-right"></i>
            </div>
          </div>
        </div>
      </div>
      <el-menu
      default-active="1-1"
      class="el-menu-vertical-demo"
      background-color="#323B40"
      text-color="#fff"
      active-text-color="#f0f">
        <el-menu-item index="1-1">{{ title }}</el-menu-item>
      </el-menu>
    </div>
    </div>
    <div class="video-info">
      <div class="left">
        <h3>{{ title }}</h3>
        <ul>
          <li>已学习{{ studyPeople }}人</li>
          <li>共播放{{ playNumber }}次</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DPlayer from 'dplayer'
import breadcrumb from '@/common/components/Breadcrumb'
import { getVideoUrl, getShortVideoUrl, updateVisit } from '@/api/course'
export default {
  name: 'Dplayer',
  components: {
    breadcrumb
  },
  props: {
    id: { // video id
      type: String
    },
    pic: { // dplayer组件中的图片路径
      type: String
    },
    title: { // dplayer组件中的图片路径
      type: String
    },
    studyPeople: { // dplayer组件中的图片路径
      type: Number
    },
    playNumber: { // dplayer组件中的图片路径
      type: String
    }
  },
  data () {
    return {
      dp: null,
      isShowBtn: true,
      videoId: null,
      url: '',
      autoHeight: '450px',
      onReady: false
    }
  },
  methods: {
    async onStart () {
      this.dp.play()
      this.isShowBtn = this.dp.video.paused
    },
    async loadVideoUrl (videoId, isFull) {
      if (isFull) {
        const res = await getVideoUrl(videoId)
        if (res.code === 200) {
          this.url = res.msg
        }
      } else {
        const res = await getShortVideoUrl(videoId)
        if (res.code === 200) {
          this.url = res.msg
          this.$message.warning('您当前正在观看试看版本')
        }
      }
      await updateVisit(videoId)
      this.onReady = true
      const that = this // 将this赋值给that，否则监听dp on会报错
      that.dp = new DPlayer({
        container: document.getElementById('dplayer'), // 挂载播放器容器
        autoplay: false, // 是否自动播放
        hotkey: true, // 开启热键
        lang: 'zh-cn', // 语言
        preload: 'metadata', // 视频预加载
        volume: 0.5, // 初始声音大小
        playbackSpeed: [0.25, 0.5, 0.75, 1, 1.5, 2, 3],
        logo: require('@/' + 'assets/images/logo/底部logo.png'),
        video: {
          url: this.$store.state.api + this.url,
          pic: this.$store.state.api + this.pic
        },
        contextmenu: [
          {
            text: '安评应急培训',
            link: '/'
          }
        ]
      })
      // 监听播放事件
      that.dp.on('play', function () {
        that.isShowBtn = that.dp.video.paused
      })
      // 监听暂停事件
      that.dp.on('pause', function () {
        that.isShowBtn = that.dp.video.paused
      })
      // 监听正在播放事件
      that.dp.on('playing', function () {
        if (!isFull) {
          that.dp.notice('试看版，请登录观看完整版', that.dp.video.duration * 1000)
        }
      })
    }
  },
  watch: {
    id (newVal, oldVal) {
      if (this.$store.state.isLoginType === 1 || this.$store.state.isLoginType === 2) this.loadVideoUrl(newVal, true)
      else this.loadVideoUrl(newVal, false)
    }
  },
  created () {
    this.autoHeight = window.innerHeight * 0.6 + 'px'
  }
}
</script>

<style lang='less' scoped>
  @media screen and (max-width: 1200px) {
    .Dplayer, .video-info {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    .Dplayer, .video-info {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 767px) {
    .el-menu {
      display: none;
    }
    .dplayer {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    .el-menu {
      display: block;
      width: 18%;
    }
    .dplayer {
      width: 80%;
    }
  }
  .Dplayer {
    /deep/ .is-link {
      color: #fff;
    }
    .container {
      display: flex;
      justify-content: space-between;
      .el-menu {
        border-right: none;
      }
    }
  }
  #dplayer {
    width: 100%;
    height: 100%;

    /deep/ .dplayer-video {
      height: auto;
    }
  }
  .dplayerContainer {
    position: relative;
    .start-btn {
      display: inline-block;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 67px;
      color: #fff;
      border: 10px solid #fff;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
  .video-info {
    color: #fff;
    h3 {
      font-size: 25px;
    }
    ul {
      display: flex;
      justify-content: flex-start;
      li {
        margin-right: 10px;
      }
    }
  }
</style>

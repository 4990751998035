<template>
  <div id="AdvantageImg" @click="handleClick">
    <img :src="require('@/' + src)" alt="安评">
    <div class="article">
      <div>
        <i :class="icon"></i>
        <p class="title">{{ title }}</p>
        <p class="content">{{ content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'advantageImg',
  props: { // 我们的优势
    src: { // 图片路径
      type: String,
      default: 'assets/images/我们的优势/丰富行业经验.jpg'
    },
    icon: { // icon clas类名
      type: String,
      default: 'el-icon-picture-outline-round'
    },
    title: { // 标题
      type: String,
      default: '安评'
    },
    content: { // 内容
      type: String,
      default: '1335家直营分部和学习中心覆盖全国319个地级市'
    }
  },
  data () {
    return {
      opacity: false
    }
  },
  methods: {
    handleClick () {
      this.opacity = !this.opacity
    }
  }
}
</script>

<style lang='less' scoped>
  #AdvantageImg {
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;vertical-align: top;
    }
    &:hover {
      background-color: rgb(13,111,184);
    }
    &:hover img{
      opacity: 0;
    }
    &:hover .article {
      background-color: rgba(0, 0, 0, 0);
    }
  }
  .article {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    text-align: center;
    padding: 0 10px;
    background-color: rgba(0, 0, 0, .6);
    i {
      font-size: 40px;
    }
    .content {
      font-size: 13px;
      line-height: 18px;
    }
  }
</style>

<template>
  <div class="newsListContainer">
    <info
    title="服务流程"
    refAnimal="newsListContainer"
    content1="全程1V1服务，实时追踪反馈，确保项目准时保质保量交付"
    />
    <div class="newsContainer">
      <div class="img animated" v-scroll-class="'fadeInUpBig'">
        <img src="../../assets/images/fuwulc.png" alt="">
        <div>
          <p>我们用心</p>
          <p>让您放心</p>
        </div>
      </div>
      <div class="newsList">
        <div class="animated" v-scroll-class="'fadeInUpBig'">
          <news
            :sort="1"
            title="提交您的需求"
            summary="" />
        </div>

        <div class="animated" v-scroll-class="'fadeInUpBig'">
          <news
            :sort="2"
            title="专人接洽"
            summary="" />
        </div>

        <div class="animated" v-scroll-class="'fadeInUpBig'">
          <news
            :sort="3"
            title="订单处理与企业的生产"
            summary="" />
        </div>

        <div class="animated" v-scroll-class="'fadeInUpBig'">
          <news
            :sort="4"
            title="物流运输"
            summary="" />
        </div>

        <div class="animated" v-scroll-class="'fadeInUpBig'">
          <news
            :sort="5"
            title="技术支持和服务"
            summary="" />
        </div>

        <div class="animated" v-scroll-class="'fadeInUpBig'">
          <news
            :sort="6"
            title="客户验收"
            summary="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgUrl from '@/assets/images/loading.gif'
import info from '@/components/index/Info'
import news from '@/components/index/News'
export default {
  name: 'newsList',
  components: { info, news },
  data () {
    return {
      img: imgUrl
    }
  }
}
</script>

<style lang='less' scoped>
/* 媒体查询，当文档宽度大于1200px时 */
  @media screen and (min-width: 1200px) {
    .newsContainer {
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 1200px) {
    .newsContainer {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .newsListContainer {
      .newsContainer {
        flex-wrap: wrap;
      }
      .img {
        width: 100%;
        height: 376px;
      }
      .newsList {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .newsListContainer {
      .newsContainer {
        flex-wrap: nowrap;
      }
      .img {
        width: 40%;
      }
      .newsList {
        width: 55%;
      }
    }
  }
  .newsContainer {
    display: flex;
    justify-content: space-between;
    .img {
      overflow: hidden;
      position: relative;
      background-size: 100% 100%;
      &:hover img {
        transform: scale(1.05);
      }
      img {
        width: 100%;
        height: 100%;
        transition: transform 0.2s linear;
      }
      div {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 70px;
        padding: 0 20px;
        color: #fff;
        background-color: rgba(0, 0, 0, .4);
      }
      p {
        margin: 0;
        padding: 0;
      }
      p:first-child {
        font-size: 25px;
        line-height: 35px;
        height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
        word-wrap: break-word;
      }
      p:last-child {
        font-size: 16px;
        line-height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
    .newsList {
      height: 402px;
      overflow: hidden;
      margin-left: 20px;
    }
  }
  .news {
    padding: 15px 0;
    border-top: 2px solid rgb(209,209,209);
  }
  .news:first-child {
    border: none;
    margin-top: 0;
  }
  // .news:nth-child(2) {
  //   padding-top: 30px;
  // }
  // .news:last-child {
  //   margin-bottom: 0;
  //   padding-top: 30px;
  // }
</style>

<template>
  <div class="skill">
    <img :src="require('@/' + src)" alt="安评">
    <div class="container">
      <div v-add-class="'shake'" class="animated">
        <i :class="icon"></i>
        <p class="title">{{ title }}</p>
        <!-- <p class="content" v-for="(item, index) in content" :key="index">
          <i class="el-icon-right"></i>
          {{ item }}
        </p> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'skill',
  props: {
    src: { // 图片路径
      type: String,
      default: ''
    },
    icon: { // icon 类名
      type: String,
      default: 'el-icon-s-marketing'
    },
    title: { // skill标题
      type: String,
      default: '安全生产'
    },
    content: { // 内容
      type: Array,
      default: function () {
        return []
      }
    },
    display: { //
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang='less' scoped>
  @keyframes round {
    0% {
      left: -20px;
    }
    50% {
      left: -15px;
    }
    100% {
      left: -20px;
    }
  }
  .skill {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex; /* 图片居中显示 */
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      transition: transform 0.2s linear;
    }
    .container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      color: #fff;
      background-color: rgba(0, 0, 0, .6);
      div {
        // margin-top: 50px;
      }
    }
    i {
      font-size: 40px;
    }
    .title {
      font-size: 16px;
    }
    .content {
      font-size: 14px;
      margin: 5px 0;
      position: relative;
      text-align: left;
      display: flex;
      align-items: center;
      margin-left: 20px;
      i {
        position: absolute;
        left: -20px;
        font-size: 12px;
        animation: round 1s infinite;
      }
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
</style>

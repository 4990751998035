import request from '@/utils/request'

// 查询线下报名人信息列表
export function listRegistration (query) {
  return request({
    url: '/back/registration/list',
    method: 'get',
    params: query
  })
}

// 查询线下报名人信息详细
export function getRegistration (id) {
  return request({
    url: '/back/registration/' + id,
    method: 'get'
  })
}

// 新增线下报名人信息
export function addRegistration (data) {
  return request({
    url: '/back/registration',
    method: 'post',
    data: data
  })
}

// 修改线下报名人信息
export function updateRegistration (data) {
  return request({
    url: '/back/registration',
    method: 'put',
    data: data
  })
}

// 删除线下报名人信息
export function delRegistration (id) {
  return request({
    url: '/back/registration/' + id,
    method: 'delete'
  })
}

// 导出线下报名人信息
export function exportRegistration (query) {
  return request({
    url: '/back/registration/export',
    method: 'get',
    params: query
  })
}

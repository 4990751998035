<template>
  <div class="productList">
    <info
      title="工程业绩"
      refAnimal="productList"
      content1="丰富行业实战经验"
      content2="始终践行创新精神、破解行业难题"
    />

    <div class="container animated" v-scroll-class="'fadeInUpBig'">
      <div class="btnContent">
        <div class="btnBox1" v-for="(item, index) in menuBtnList">
          <el-button
            type="text"
            :class="{ isShow: selectedBtn === index }"
            @click="queryProList(item, index)"
          >
            {{ item.name }}
          </el-button>
          <div v-if="index + 1 !== menuBtnList.length" style="width: 1px;margin: 0 10px;height: 15px; background: #000;"></div>
        </div>
      </div>

      <div class="btnContent">
        <div class="btnBox2" v-for="(item, index) in menuBtnChildrenList">
          <el-button
            type="text"
            :class="{ isShow: selectedChildrenBtn === index }"
            @click="queryChildrenProList(item, index)"
          >
            {{ item.name }}
          </el-button>
          <div v-if="index + 1 !== menuBtnChildrenList.length" style="width: 1px;margin: 0 10px;height: 15px; background: #000;"></div>
        </div>
      </div>
    </div>

    <div class="display-list">
      <div class="display-item animated" v-scroll-class="'fadeInUpBig'" v-for="item in dataList" @click="goDetail(item)">
        <div class="imgBox">
          <img :src="baseUrl + item.img" alt="">
        </div>
        <p>{{ item.name }}</p>
      </div>
    </div>

    <p class="empty-class" v-if="dataList && dataList.length <= 0" :image-size="200">暂无数据 ～～～</p>

    <el-button type="primary" round @click="$router.push({path: '/projectScore'})">更多<i class="el-icon-right el-icon--right"></i></el-button>
  </div>
</template>

<script>
import info from '@/components/index/Info'
import { menuList, proList } from "@/api/project";

export default {
  name: "productList",

  components: { info },

  data() {
    const baseUrl = process.env.VUE_APP_BASE_API

    return {
      baseUrl: baseUrl,
      menuList: [],
      menuBtnList: [],
      menuBtnChildrenList: [],
      selectedBtn: 0,
      selectedChildrenBtn: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 9,
        ishome: '1',
        type: ''
      },
      dataList: [],
    }
  },

  mounted() {

  },

  created() {
    this.getMenuList()
  },

  methods: {
    queryProList(item, index) {
      this.selectedChildrenBtn = 0
      this.selectedBtn = index || index === 0 ? index : this.selectedBtn
      this.menuBtnChildrenList = this.menuList.filter(val => {
        return val.pid === item.id
      })

      if (this.menuBtnChildrenList.length > 0) {
        this.queryChildrenProList(this.menuBtnChildrenList[0])
      } else {
        this.queryParams.type = item.name || this.queryParams.type
        proList(this.queryParams).then(res => {
          console.log('产品列表', res)
          this.dataList = res.rows
          this.total = res.total
        })
      }

      // this.queryParams.type = item.name || this.queryParams.type
      // console.log('产品列表参数', this.queryParams)
      // proList(this.queryParams).then(res => {
      //   console.log('产品列表', res)
      //   this.dataList = res.rows
      //   this.total = res.total
      // })
    },

    queryChildrenProList(item, index) {
      this.selectedChildrenBtn = index || index === 0 ? index : this.selectedChildrenBtn
      this.queryParams.type = item.name || this.queryParams.type
      console.log('产品列表参数', this.queryParams)
      proList(this.queryParams).then(res => {
        console.log('产品列表', res)
        this.dataList = res.rows
        this.total = res.total
      })
    },

    getMenuList() {
      menuList().then(res => {
        console.log('menuList', res)
        // this.menuList = res.rows
        this.menuList = [
          {
            name: '楼宇',
            pid: '0'
          },
          {
            name: '工厂',
            pid: '0'
          },
          {
            name: '酒店',
            pid: '0'
          },
          {
            name: '枢纽',
            pid: '0'
          }
        ],
        
        this.menuBtnList = this.menuList.filter(val => {
          return val.pid === '0'
        })
        this.menuBtnChildrenList = this.menuList.filter(val => {
          return val.pid === this.menuBtnList[0].id
        })
        if (this.menuBtnChildrenList.length > 0) {
          this.queryChildrenProList(this.menuBtnChildrenList[0])
        } else {
          this.queryChildrenProList(this.menuBtnList[0])
        }
      })
    },

    goDetail(item) {
      this.$router.push({
        path: '/projectDetail',
        query: {
          obj: JSON.stringify(item)
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
@media screen and (min-width: 768px) {
  .display-list {
    max-width: 1170px;
  }
  .display-item {
    div {
      width: 300px;
      height: 242px;
      overflow: hidden;
    }

    p {
      width: 300px;
    }
  }
}
@media screen and (max-width: 768px) {
  .display-list {
    width: 100%;
  }
  .display-item {
    div {
      width: 170px;
      height: 140px;
      overflow: hidden;
    }
    p {
      width: 170px;
    }
  }
}

.productList {
  display: flex;
  flex-direction: column;
  align-items: center;

  .empty-class {
    color: #e4e4e4;
  }
}

.container {
  width: 100%;

  .btnContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5px;
    box-sizing: border-box;

    .isShow {
      // background-color: rgb(10, 132, 202);
      color: red !important;
    }

    .btnBox1 {
      display: flex;
      align-items: center;

      .el-button {
        font-size: 18px;
      }
    }

    .btnBox2 {
      display: flex;
      align-items: center;

      .el-button {
        font-size: 15px;
      }
    }

    .el-button {
      padding: 10px 0;
      color: black;

      &:hover {
        color: red;
      }
    }
  }
}

.display-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 5px;
  box-sizing: border-box;

  .display-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 6px 12px 6px;

    .imgBox {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #e4e7ed;
      box-sizing: border-box;

      img {
        transition: all .5s;
        width: 85%;
        // height: 100%;

        &:hover {
          transform: scale(1.2);
        }
      }
    }

    p {
      margin: 0;
      padding: 5px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      font-weight: bold;
      background-color: #fff;
      text-align: center;
    }

    &:hover {
      cursor: pointer;

      .imgBox {
        border-bottom: none;
        border-color: #3a8ee6;
      }

      p {
        background: #3a8ee6;
        color: #fff;
      }
    }
  }
}
</style>

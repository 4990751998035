<template>
  <div id="indexContainer" ref="scrollBox">
    <!-- <div class="banner animated animateBanner fadeInUpBig">
    </div> -->

    <div>
      <productList />
    </div>

    <div>
      <solutionList />
    </div>

    <div class="animated animateAdvantage">
      <advantage />
    </div>

    <div class="animated animateAdvantage">
      <projectList />
    </div>

    <div class="animated animateNewList">
      <!--      <news-list />-->
      <service-process></service-process>
    </div>

    <div class="animated animateAdvantage">
      <download />
    </div>

<!--    <div class="animated animatePartnerAcademy">
      <partner-academy />
    </div>
    <div class="animated animateLearnPlaform">
      <learnPlatform />
    </div>
    <div class="animated animateTrainShow">
      <trainShow />
    </div>-->
  </div>
</template>

<script>
import productList from '@/components/index/productList'
import solutionList from '@/components/index/solutionList'
import projectList from '@/components/index/projectList'
import download from '@/components/index/downloadCenter'
import company from '@/components/index/Company'
import advantage from '@/components/index/Advantage'
import partnerAcademy from '@/components/index/PartnerAcademy'
import learnPlatform from '@/components/index/LearnPlatform'
import trainShow from '@/components/index/TrainShow'
import newsList from '@/components/index/NewsList'
import serviceProcess from '@/components/index/serviceProcess'
export default {
  name: 'indexContainer',
  components: { serviceProcess, download, productList, solutionList, projectList, company, advantage, trainShow, newsList, partnerAcademy, learnPlatform },
  data () {
    return {
    }
  },
  methods: {
    // 滚动函数
    // onScroll () {
    // app的卷去高度
    // const scrollTop = this.$root.$el.scrollTop
    // // 网页可见区域高度
    // const documentHeight = document.body.clientHeight
    // // banner到顶部距离
    // const animateBanner = 0
    // // company到顶部距离
    // const animateCompany = this.$refs.scrollBox.querySelector('.animateBanner').clientHeight + animateBanner
    // // advantage到顶部距离
    // const animateAdvantage = this.$refs.scrollBox.querySelector('.animateCompany').clientHeight + animateCompany
    // // 合作院校到顶部距离
    // const animatePartnerAcademy = this.$refs.scrollBox.querySelector('.animateAdvantage').clientHeight + animateAdvantage
    // // 学习平台到顶部距离
    // const animateLearnPlaform = this.$refs.scrollBox.querySelector('.animatePartnerAcademy').clientHeight + animatePartnerAcademy
    // // 培训相关到顶部距离
    // const animateTrainShow = this.$refs.scrollBox.querySelector('.animateLearnPlaform').clientHeight + animateLearnPlaform
    // // 底部新闻列表到顶部距离
    // const animateNewList = this.$refs.scrollBox.querySelector('.animateTrainShow').clientHeight + animateTrainShow
    // // 以下皆是添加animated 类名 添加动画效果
    // if (animateBanner >= scrollTop && animateBanner <= scrollTop + documentHeight) {
    //   this.$refs.scrollBox.querySelector('.animateBanner').classList.add('fadeInUpBig')
    // } else if (animateCompany >= scrollTop && animateCompany <= scrollTop + documentHeight) {
    //   // this.$refs.scrollBox.querySelector('.animateCompany').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.company1').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.company2').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.company3').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.company4').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.animate1').classList.add('fadeInRightBig')
    // } else if (animateAdvantage >= scrollTop && animateAdvantage <= scrollTop + documentHeight) {
    //   // this.$refs.scrollBox.querySelector('.animateAdvantage').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.advantage1').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.advantage2').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.advantage3').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.advantage4').classList.add('fadeInUpBig')
    // } else if (animatePartnerAcademy >= scrollTop && animatePartnerAcademy <= scrollTop + documentHeight) {
    //   // this.$refs.scrollBox.querySelector('.animatePartnerAcademy').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.academy1').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.academy2').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.academy3').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.academy4').classList.add('fadeInUpBig')
    // } else if (animateLearnPlaform >= scrollTop && animateLearnPlaform <= scrollTop + documentHeight) {
    //   // this.$refs.scrollBox.querySelector('.animateLearnPlaform').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.learnPlaform1').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.learnPlaform2').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.learnPlaform3').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.learnPlaform4').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.learnPlaform5').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.learnPlaform6').classList.add('fadeInUpBig')
    // } else if (animateTrainShow >= scrollTop && animateTrainShow <= scrollTop + documentHeight) {
    //   // this.$refs.scrollBox.querySelector('.animateTrainShow').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow1').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow2').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow3').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow4').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow5').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow6').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow7').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow8').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.trainShow9').classList.add('fadeInUpBig')
    // } else if (animateNewList >= scrollTop && animateNewList <= scrollTop + documentHeight) {
    //   // this.$refs.scrollBox.querySelector('.animateNewList').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.newsList1').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.newsList2').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.newsList3').classList.add('fadeInUpBig')
    //   this.$refs.scrollBox.querySelector('.newsList4').classList.add('fadeInUpBig')
    // }
    // }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
    // if (this.$route.name === 'index') {
    //   // 在首页添加滚动事件
    //   // addEventListener 第三个参数：冒泡/捕获 = false/true
    //   window.addEventListener('scroll', this.onScroll, true)
    // }
  }
  // destroyed () {
  //   // 移除滚动事件
  //   window.removeEventListener('scroll', this.onScroll, true)
  // }
}
</script>

<style lang='less' scoped>
  #indexContainer {
    position: relative;
    height: 100%;
  }
  // .animateCompany {
  //   height: 100%;
  //   overflow-y: auto;
  // }
  .animateHide {
    opacity: 0;
  }
</style>

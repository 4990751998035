import request from '@/utils/request'

// 查询资料信息列表
export function listInformation (query) {
  return request({
    url: '/back/information/list',
    method: 'get',
    params: query
  })
}

// 查询资料信息详细
export function getInformation (id) {
  return request({
    url: '/back/information/' + id,
    method: 'get'
  })
}

// 新增资料信息
export function addInformation (data) {
  return request({
    url: '/back/information',
    method: 'post',
    data: data
  })
}

// 修改资料信息
export function updateInformation (data) {
  return request({
    url: '/back/information',
    method: 'put',
    data: data
  })
}

// 删除资料信息
export function delInformation (id) {
  return request({
    url: '/back/information/' + id,
    method: 'delete'
  })
}

// 导出资料信息
export function exportInformation (query) {
  return request({
    url: '/back/information/export',
    method: 'get',
    params: query
  })
}

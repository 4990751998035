import request from '@/utils/request'

// 查询案例展示菜单类列表
export function menuList () {
  return request({
    url: '/web/caseMenu/list',
    method: 'get'
  })
}

// 查询产品中心内容信息列表
export function proList (query) {
  return request({
    url: '/web/caseInfor/list',
    method: 'get',
    params: query
  })
}

// 获取产品中心内容信息详细信息
export function proDetail (id) {
  return request({
    url: '/web/caseInfor/' + id,
    method: 'get'
  })
}

<template>
  <ul class="academy">
    <li>
      <img :src="this.$store.state.api + src" alt="">
    </li>
    <li>
      <h4>{{ academyName }}</h4>
    </li>
    <!-- <li>
      <p>{{ academyIntro }}</p>
    </li> -->
    <div></div>
  </ul>
</template>

<script>
export default {
  name: 'academy',
  props: {
    src: {
      type: String,
      default: ''
    },
    academyName: {
      type: String,
      default: '中国矿业大学'
    },
    academyIntro: {
      type: String,
      default: '中国矿业大学是教育部直属的全国重点高校、国家“211工程”“985优势学科创新平台项目”和国家“双一流”建设高校，同时也是教育部与江苏省人民政府、国家安全生产监督管理总局共建高校。学校为全国首批具有博士和硕士授予权的高校之一，学校设有研究生院。学校坐落于素有“五省通衢”之称的国家历史文化名城——江苏省徐州市，有文昌和南湖两个校区，占地4000余亩。'
    }
  }
}
</script>

<style lang='less' scoped>
  .academy {
    width: 100%;
    height: 308px;
    background-color: rgba(0,0,0,0.5);
    overflow: hidden;
    text-align: center;
    transition: background-color 0.2s linear;
    li {
      transition: color 0.2s linear;
    }
    > li:nth-child(1) {
      width: 100px;
      height: 100px;
      margin: 0 auto;
      margin-top: 60px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    > li:nth-child(2) {
      // color: #898989;
      color: #fff;
      font-size: 18px;
    }
    > li:nth-child(3) {
      font-size: 14px;
      color: #a0a0a0;
      line-height: 21px;
      text-align: left;
      height: 147px;
      overflow: hidden ;
      display: -webkit-box ;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical ;
      word-break: break-all ;
      p {
        margin: 0;
      }
    }
    &:hover, &:focus {
      background-color: #c54a54;
      li {
        color: #fff !important;
      }
    }
  }
</style>

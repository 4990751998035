<template>
  <div id="partnerAcademy">
    <academy v-for="(item, index) in tableData" :key="index"
    :bgSrc="item.background"
    :academyName="item.name"
    :academyContent="item.content"
    :color="item.type"
    :flexDirection="item.position" />
  </div>
</template>

<script>
import { listMechanism } from '@/api/partnerAcademy'
import academy from '@/components/partnerAcademy/Academy'
export default {
  name: 'index',
  components: {
    academy
  },
  data () {
    return {
      total: 0, // 分页总数
      pageNum: 1, // 分页当前页码数
      pageSize: 10, // 分页数量
      search: undefined, // 搜索内容
      tableData: [] // 显示内容列表
      // academys: [
      //   {
      //     bgSrc: 'assets/images/academyImages/academyBg4.jpg',
      //     academyName: '中国矿业大学',
      //     academyContent: '中国矿业大学作为当今全国唯一以矿业命名的特色鲜明高水平大学，在煤炭能源的勘探、开发、利用，资源、环境和生产相关的矿建、安全、测绘、机械、信息技术、生态恢复、管理工程等领域形成了优势品牌和鲜明特色。',
      //     color: '#fff',
      //     flexDirection: true,
      //     imgSrc: 'assets/images/academyImages/academySmall1.png'
      //   },
      //   {
      //     bgSrc: 'assets/images/academyImages/academyBg3.jpg',
      //     academyName: '中煤科工集团重庆研究院有限公司',
      //     academyContent: '在瓦斯防治、粉尘防治、火灾爆炸防治、应急救援和水害防治等主导专业取得重大突破，开发出了数百种适应煤矿安全生产需求的仪器、仪表和装备,为我国煤矿安全形势的根本好转和煤炭工业安全事业的长足发展做出了积极贡献。',
      //     color: '#000',
      //     flexDirection: false,
      //     imgSrc: 'assets/images/academyImages/academySmall2.png'
      //   },
      //   {
      //     bgSrc: 'assets/images/academyImages/academyBg2.jpg',
      //     academyName: '中煤科工集团上海研究院有限公司',
      //     academyContent: '上海院设有采掘机械、运输机械技术、电气自动化技术、液压技术等专业研究部门，拥有长期从事采、掘、运、电、液等方面的技术优势，是我国煤炭行业采煤机、运输机、电气技术 和液压技术的归口单位，是煤炭行业煤矿专用设备标准化技术委员会的单位。',
      //     color: '#fff',
      //     flexDirection: true,
      //     imgSrc: 'assets/images/academyImages/academySmall3.png'
      //   },
      //   {
      //     bgSrc: 'assets/images/academyImages/academyBg1.jpg',
      //     academyName: '辽宁工程技术大学',
      //     academyContent: '在矿山灾害治理、安全技术与工程、测绘科学与技术、机械制造等研究领域有明显优势，在冲击地压预测与防治、煤炭自燃火灾防治、煤矿瓦斯防治理论与技术、安全结构分析与预测、遥感卫星对地观测系统图像处理理论与方法等研究领域已达到国内国际先进水平。',
      //     color: '#000',
      //     flexDirection: false,
      //     imgSrc: 'assets/images/academyImages/academySmall2.png'
      //   }
      // ]
    }
  },
  methods: {
    async loadList () {
      try {
        const res = await listMechanism({
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          name: this.search
        })
        if (res.code === 200) {
          this.total = res.total
          this.tableData = res.rows
        }
      } catch (error) {
        this.$message.error('未知错误，请重新尝试')
      }
    },
    onSearch () {
      this.pageNum = 10
      this.pageNum = 1
      this.loadList()
    },
    // 分页每页数量发生改变
    handleSizeChange (val) {
      this.pageSize = val
      this.loadList()
    },
    // 分页当前页码发生改变
    handleCurrentChange (val) {
      this.pageNum = val
      this.loadList()
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$store.state.el_.scrollTop = 0
    })
  },
  created () {
    this.loadList()
  }
}
</script>

<style lang='less' scoped>
  #partnerAcademy {}
</style>

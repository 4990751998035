import request from '@/utils/request'

// 查询线下课程列表
export function listIntroduction (query) {
  return request({
    url: '/back/introduction/list',
    method: 'get',
    params: query
  })
}

// 查询线下课程详细
export function getIntroduction (id) {
  return request({
    url: '/back/introduction/' + id,
    method: 'get'
  })
}

// 新增线下课程
export function addIntroduction (data) {
  return request({
    url: '/back/introduction',
    method: 'post',
    data: data
  })
}

// 修改线下课程
export function updateIntroduction (data) {
  return request({
    url: '/back/introduction',
    method: 'put',
    data: data
  })
}

// 删除线下课程
export function delIntroduction (id) {
  return request({
    url: '/back/introduction/' + id,
    method: 'delete'
  })
}

// 导出线下课程
export function exportIntroduction (query) {
  return request({
    url: '/back/introduction/export',
    method: 'get',
    params: query
  })
}

import { listCurriculum } from '@/api/course'
import { message } from 'element-ui'
export default {
  state: {
    courseList: null,
    total: 0
  },
  mutations: {
    SET_COURSELIST (state, payload) {
      state.courseList = payload
    },
    SET_TOTAL (state, payload) {
      state.total = payload
    }
  },
  actions: {
    async getCourseList (context, payload) {
      try {
        const res = await listCurriculum({
          pageSize: payload.pageSize,
          pageNum: payload.pageNum,
          name: payload.search,
          type: payload.type
        })
        if (res.code === 200) {
          context.commit('SET_COURSELIST', res.rows)
          context.commit('SET_TOTAL', res.total)
        }
      } catch (error) {
        console.log(message)
        // message.error('未知错误，请重新尝试')
      }
    }
  },
  getters: {}
}

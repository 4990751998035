import request from '@/utils/request'

// 查询下载中心列表
export function proList (query) {
  return request({
    url: '/web/downInfor/list',
    method: 'get',
    params: query
  })
}

// 获取下载中心详细信息
export function proDetail (id) {
  return request({
    url: '/web/downInfor/' + id,
    method: 'get'
  })
}
